import { getThemeOptions } from "@helpers/schemas"
import { HeroSidePanel, HeroSidePanel2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Side Panel",
  component: "HeroSidePanel",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Slider Elements",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          whiteList: ["TextualPanel"],
          key: "sliderElements",
        },
        {
          title: "Side Elements",
          type: "ComponentArray",
          mandatory: true,
          maxItems: 3,
          whiteList: ["HeroSideCard"],
          key: "sideElements",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          type: "UniqueCheck",
          key: "anchor",
          options: [
            {
              title: "Show anchor arrow",
            },
          ],
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "HeroSidePanel",
    anchorID: null,
    theme: "default",
    anchor: true,
    sliderElements: [
      {
        component: "TextualPanel",
        title: { content: "Title", tag: "h4" },
        content: null,
        link: {
          component: "Link",
          text: "Link",
          style: "secondary",
          url: {
            href: null,
            linkToURL: null,
            linkTo: null,
            newTab: false,
            noFollow: false,
          },
        },
        backgroundImage: { component: "Image", veil: 50 },
        theme: "accent",
      },
    ],
    sideElements: [
      {
        component: "HeroSideCard",
      },
      {
        component: "HeroSideCard",
      },
      {
        component: "HeroSideCard",
      },
    ],
  },
  thumbnails: {
    "1x": HeroSidePanel,
    "2x": HeroSidePanel2x,
  },
}
