import { FloatingCTA, FloatingCTA2x } from "@thumbnails/Modules"
const defCustomLink = (title, icon, mod) => ({
  component: "FloatingCTAElement",
  icon: false,
  iconSelection: icon,
  title: title,
  theme: "primary",
  componentModules: mod || {
    floatingCTALightbox: { component: "FloatingCTALightbox" },
    floatingCTAMail: { component: "FloatingCTAMail" },
    floatingCTAVideo: { component: "FloatingCTAVideo" },
    RequestInfo: { component: "RequestInfo" },
  },
})
export default {
  schemaType: "module",
  displayName: "Simple Floating CTA",
  component: "SimpleFloatingCTA",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "CTA elements",
          type: "ComponentArray",
          maxItems: 1,
          whiteList: ["FloatingCTAElement"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    anchorID: null,
    component: "SimpleFloatingCTA",
    theme: "defaultAlt",
    elements: [defCustomLink("CTA Component", "email")],
  },
  thumbnails: {
    "1x": FloatingCTA,
    "2x": FloatingCTA2x,
  },
}
