export default {
  schemaType: "component",
  displayName: "Table Row",
  component: "TableRow",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Cell type",
          type: "ConditionalField",
          key: "cellType",
          mandatory: true,
          required: true,
          options: [
            {
              value: "text",
              title: "Text",
              name: "text",
            },
            {
              value: "link",
              title: "Link",
              name: "link",
            },
          ],
          fields: [
            {
              title: "Row Label",
              type: "TextField",
              key: "title",
              condition: "text",
              humanReadable: true,
            },
            {
              title: "Text",
              type: "TextField",
              key: "textLink",
              condition: "link",
              humanReadable: true,
            },
            {
              title: "URL",
              type: "UrlField",
              key: "textURL",
              advanced: true,
              condition: "link",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "TableRow",
    title: "-",
    cellType: "text",
    link: {
      component: "Link",
      text: "Link",
      style: "link",
      url: {
        newTab: false,
        noFollow: true,
        internal: true,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    textLink: "text",
    textURL: "https://www.secuoyas.com",
  },
}
