import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { Profile, Profile2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Profile",
  component: "Profile",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          mandatory: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
    { title: "config", fields: [] },
  ],
  default: {
    component: "Profile",
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
    title: { content: "Título", tag: "h4" },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in fermentum purus.",
    link: {
      component: "Link",
    },
  },
  thumbnails: {
    "1x": Profile,
    "2x": Profile2x,
  },
}
