import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { PersonCard, PersonCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Person Card",
  component: "PersonCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          hideable: true,
        },
        {
          title: "Name",
          type: "HeadingField",
          key: "name",
          mandatory: true,
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Position",
          type: "RichText",
          html: true,
          key: "position",
          mandatory: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: "LinkedIn",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "linkedin",
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "imageBackground",
          helptext: "Recommended minimum image size: 120x120",
        },
        {
          title: "Modal Detail",
          type: "RichText",
          html: true,
          key: "modalDetail",
          helptext: "The text will be displayed in a modal",
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "PersonCard",
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
    link: {
      external: true,
      href: "https://www.secuoyas.com",
      icon: "",
      label: "go to secuoyas",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    name: { content: "Title", tag: "h4" },
    position:
      "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie.",
    linkedin: {
      component: "Link",
    },
    link: {
      label: "Learn more about Antonios’ teaching methodology",
      href: "//ie.edu",
    },
    modalDetail:
      "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor ut, iaculis quis, sem. Phasellus rhoncus. Aenean id metus id velit ullamcorper pulvinar. Vestibulum fermentum tortor id mi. Pellentesque ipsum. Nulla non arcu lacinia neque faucibus fringilla. Nulla non lectus sed ni",
  },
  thumbnails: {
    "1x": PersonCard,
    "2x": PersonCard2x,
  },
}
