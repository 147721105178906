import { Video, Video2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Video",
  component: "Video",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "URL Video",
          type: "TextField",
          key: "url",
          mandatory: true,
        },
        {
          title: "Thumbnail",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "thumbnail",
          hideable: true,
        },
        {
          type: "UniqueCheck",
          key: "automaticSubtitles",
          options: [
            {
              title: "Add automatic subtitles from Youtube",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "Video",
    thumbnail: {
      component: "Image",
    },
    url: "",
    automaticSubtitles: false,
  },
  thumbnails: {
    "1x": Video,
    "2x": Video2x,
  },
}
