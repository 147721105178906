import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { FeedbackForm, FeedbackForm2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Feedback Form",
  component: "FeedbackForm",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "TextField",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Person contact",
          type: "TextField",
          key: "personContact",
        },
        {
          title: "Phone contact",
          type: "TextField",
          key: "phoneContact",
          hideable: true,
        },
        {
          title: "Email contact",
          type: "TextField",
          key: "emailContact",
          hideable: true,
        },
        {
          title: "Image contact",
          type: "ImageField",
          key: "imageContact",
          hideable: true,
        },

        {
          title: "Include form",
          type: "ConditionalField",
          key: "includeForm",
          mandatory: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Email to",
              type: "TextField",
              key: "to",
              mandatory: true,
              condition: true,
            },
            {
              title: "Subject",
              type: "TextField",
              key: "subject",
              mandatory: true,
              condition: true,
              humanReadable: true,
            },
            {
              title: "Form Button",
              type: "ComponentContainer",
              whiteList: ["FormButton"],
              key: "formButton",
              mandatory: true,
              condition: true,
            },
            {
              title: "Link",
              type: "ComponentContainer",
              whiteList: ["Link"],
              key: "link",
              condition: false,
            },
          ],
        },
        {
          title: "Legal condition page",
          type: "UrlField",
          advanced: true,
          key: "legalConditionLink",
          mandatory: true,
          advanced: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "FeedbackForm",
    title: "We want to know your opinion",
    subtitle: "Lorem ipsum",
    personContact: "Name contact",
    emailContact: "example@email.com",
    phoneContact: "000 000 000",
    imageContact: { ...DEFAULT_IMAGE_FIELD },
    includeForm: true,
    to: "example@email.com",
    subject: "Subject",
    formButton: {
      component: "FormButton",
      url: {
        href: "//ie.edu",
        newTab: false,
      },
      text: "Send",
      style: "primary",
    },
    legalConditionLink: {
      external: true,
      href: "",
      icon: "",
      label: "",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    link: {
      component: "Link",
      url: {
        href: "//ie.edu",
        newTab: false,
      },
      text: "Contact us",
      style: "secondary",
    },
  },
  thumbnails: {
    "1x": FeedbackForm,
    "2x": FeedbackForm2x,
  },
}
