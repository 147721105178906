import * as React from "react"
import { useInView } from "react-intersection-observer"

const style = {
  width: "100%",
  height: 1,
  marginBottom: -1,
  visibility: "hidden",
}

function useSticky(threshold = 0, rootMargin = "0px") {
  const [ref, inView, entry] = useInView({
    threshold,
    rootMargin,
  })

  const sentinel = <div ref={ref} style={style} />
  let topIntersection = false

  if (entry) {
    topIntersection = entry.boundingClientRect.top - entry.rootBounds.top < 0
  }

  return {
    sentinel,
    isSticky: !!entry && !inView && topIntersection,
  }
}

export default useSticky
