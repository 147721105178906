import { getThemeOptions } from "@helpers/schemas"
import { SocialShare, SocialShare2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Social Share",
  component: "SocialShare",
  category: "social",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "SocialShare",
    title: "Share",
    theme: "inverse",
  },
  thumbnails: {
    "1x": SocialShare,
    "2x": SocialShare2x,
  },
}
