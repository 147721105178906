import { getThemeOptions } from "@helpers/schemas"
import { Timeline, Timeline2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Timeline",
  component: "Timeline",
  dataPacks: null,
  category: "content",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "HeadingField",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          key: "subtitle",
          type: "RichText",
          html: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          key: "detail",
          type: "RichText",
          html: true,
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Timeline elements",
          key: "elements",
          type: "ComponentArray",
          whiteList: ["Milestone", "BlockTitle"],
          mandatory: true,
          maxItems: null,
        },
        {
          title: "Grouping link",
          type: "ComponentContainer",
          key: "groupingLink",
          whiteList: ["Link"],
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
        {
          title: "Featured content",
          key: "featured",
          type: "ConditionalField",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "Timeline",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    featured: false,

    title: { content: "Lorem ipsum", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    detail: null,
    elements: [],
    groupingLink: {
      component: "Link",
      text: "Discover more",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": Timeline,
    "2x": Timeline2x,
  },
}
