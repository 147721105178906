import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { Principles, Principles2x } from "@thumbnails/Modules"
import { getThemeOptions } from "@helpers/schemas"
import {
  PrinciplesLayout1,
  PrinciplesLayout2,
  PrinciplesLayout3,
  PrinciplesLayout4,
  PrinciplesLayout5,
} from "@layouts"

const defaultLink = {
  component: "Link",
}

const PrinciplesCardDefault = title => ({
  component: "PrinciplesCard",
  iconDefault: {
    ...DEFAULT_IMAGE_FIELD,
  },
  iconInverse: {
    ...DEFAULT_IMAGE_FIELD,
  },
  backgroundMode: "color",
  cardBackground: {
    component: "Image",
    veil: 50,
  },
  cardSelectBackground: {
    component: "Image",
    veil: 50,
  },
  title: { content: title, tag: "h2" },
  showExtract: true,
  description:
    "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor ut, iaculis quis, sem. Phasellus rhoncus. Aenean id metus id velit ullamcorper pulvinar. Vestibulum fermentum tortor id mi. Pellentesque ipsum. Nulla non arcu lacinia neque faucibus fringilla. Nulla non lectus sed ni",
  extractDescription: true,
  anchor: "Read more",
  link: defaultLink,
})

const PrinciplesConstructor = [
  PrinciplesCardDefault("Title"),
  PrinciplesCardDefault("Title"),
  PrinciplesCardDefault("Title"),
]

export default {
  schemaType: "module",
  displayName: "Principles",
  component: "Principles",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Auxiliar Title",
          type: "HeadingField",
          key: "auxTitle",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Auxiliar Subtitle",
          type: "RichText",
          html: true,
          key: "auxSubtitle",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Section Title",
          type: "HeadingField",
          key: "sectionTitle",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Cards",
          type: "ComponentArray",
          maxItems: null,
          key: "cards",
          whiteList: ["PrinciplesCard"],
        },
        {
          title: "Show Card Numbers",
          type: "RadioGroup",
          mandatory: true,
          key: "showNumbers",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Collapsed by default",
          type: "RadioGroup",
          mandatory: true,
          key: "collapsedByDefault",
          helptext:
            "If you select YES the user will only see the cards. If you select NO, the information of the first card will be displayed.",
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: PrinciplesLayout1,
            },
            {
              value: "L002",
              img: PrinciplesLayout2,
            },
            {
              value: "L003",
              img: PrinciplesLayout3,
            },
            {
              value: "L004",
              img: PrinciplesLayout4,
            },
            {
              value: "L005",
              img: PrinciplesLayout5,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          type: "UniqueCheck",
          key: "imageVeilColor",
          options: [
            {
              title: "Veil color",
            },
          ],
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "Principles",
    anchorID: null,
    layout: "L001",
    theme: "default",
    imageVeilColor: false,
    verticalSpacing: "medium",
    note01: {
      text: "By customizing the card you will see how the associated content is looking. This content will be seen only when the user selects that card.",
    },
    title: { content: "Lorem Ipsum", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    detail: null,
    auxTitle: null,
    collapsedByDefault: true,
    auxSubtitle: null,
    sectionTitle: null,
    cards: PrinciplesConstructor,
    showNumbers: true,
  },
  thumbnails: {
    "1x": Principles,
    "2x": Principles2x,
  },
}
