export default {
  ARTICLES_EVENTS: { title: "Articles & Events" },
  FINANTIAL: { title: "Finantial" },
  GENERALINFO: { title: "General Info" },
  INTERACTIVE: { title: "Interactive" },
  INSIGHTS: { title: "Insights" },
  OFFICES: { title: "Offices" },
  PEOPLE: { title: "People" },
  PROJECTS: { title: "Projects" },
  STUDIES: { title: "Studies" },
}
