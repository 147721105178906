const STORIES = {
  title: "Stories",
  dataPacks: ["STORIES"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["StoryDetail"],
    fields: [
      {
        key: "units",
        title: "Unit",
        from: "categories.units",
        type: "AsyncCheckGroup",
        source: "UNIT",
      },
      {
        key: "schools",
        title: "Schools",
        from: "categories.schools",
        type: "AsyncCheckGroup",
        source: "SCHOOLS",
        showList: true,
        indexable: true,
      },
      {
        key: "pathways",
        title: "Pathways",
        from: "categories.pathways",
        type: "AsyncCheckGroup",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        from: "categories.programs",
        type: "AsyncCheckGroup",
        source: "PROGRAMS",
      },
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        from: "categories.subjectAreas",
        type: "AsyncCheckGroup",
        source: "SUBJECT_AREA",
      },
      {
        key: "genders",
        title: "Gender",
        from: "categories.genders",
        type: "AsyncCheckGroup",
        source: "GENDER",
      },
      {
        key: "stageTargets",
        title: "Stage Target",
        from: "categories.stageTargets",
        type: "AsyncCheckGroup",
        source: "STAGE_TARGET",
      },
      {
        key: "regions",
        title: "Region",
        from: "categories.regions",
        type: "AsyncCheckGroup",
        source: "REGION",
      },
      {
        key: "formats",
        title: "Program Format",
        from: "categories.formats",
        type: "AsyncCheckGroup",
        source: "FORMAT",
      },
      {
        key: "centers",
        title: "Centers & Departments",
        from: "categories.centers",
        type: "AsyncCheckGroup",
        source: "CENTERS",
      },
      {
        key: "topics",
        title: "Topic",
        from: "categories.topics",
        type: "AsyncCheckGroup",
        source: "TOPIC",
      },
      {
        key: "studyType",
        title: "Study type",
        from: "categories.studyType",
        type: "AsyncCheckGroup",
        source: "STUDY_TYPE",
      },
      {
        key: "profiles",
        title: "Profiles",
        from: "categories.profiles",
        type: "AsyncCheckGroup",
        source: "PROFILES",
      },
      {
        key: "title",
        title: "Title",
        type: "TextField",
        from: "title",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "quote",
        title: "Quote",
        type: "RichText",
        html: true,
        from: "quote",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "dateTime",
        title: "Date",
        type: "DateField",
        from: "dateTime",
        indexable: true,
      },
      {
        key: "image",
        title: "Image",
        type: "ImageField",
        from: "image",
      },
      {
        key: "moreQuickFacts",
        title: "Quick facts",
        type: "ComponentArray",
        from: "moreQuickFacts",
      },
      {
        key: "cardBackground",
        title: "Image",
        type: "ImageField",
        from: "cardBackground",
        helptext: "Shown on pagination",
      },
      {
        key: "program",
        title: "Program",
        type: "AsyncSelect",
        source: "PROGRAMS",
        from: "program",
      },
    ],
    searchFrom: ["quote"],
  },
  clone: null,
  defaultValues: null,
}

export { STORIES }
