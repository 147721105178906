const NEWS = {
  title: "News",
  dataPacks: ["NEWS"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["NewsDetail"],
    fields: [
      {
        key: "units",
        title: "Unit",
        from: "categories.units",
        type: "AsyncCheckGroup",
        source: "UNIT",
      },
      {
        key: "schools",
        title: "Schools",
        from: "categories.schools",
        type: "AsyncCheckGroup",
        source: "SCHOOLS",
      },
      {
        key: "pathways",
        title: "Pathways",
        from: "categories.pathways",
        type: "AsyncCheckGroup",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        from: "categories.programs",
        type: "AsyncCheckGroup",
        source: "PROGRAMS",
      },
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        from: "categories.subjectAreas",
        type: "AsyncCheckGroup",
        source: "SUBJECT_AREA",
        showList: true,
        indexable: true,
      },
      {
        key: "stageTargets",
        title: "Stage Target",
        from: "categories.stageTargets",
        type: "AsyncCheckGroup",
        source: "STAGE_TARGET",
      },
      {
        key: "centers",
        title: "Centers & Departments",
        from: "categories.centers",
        type: "AsyncCheckGroup",
        source: "CENTERS",
      },
      {
        key: "topics",
        title: "Topic",
        from: "categories.topics",
        type: "AsyncCheckGroup",
        source: "TOPIC",
      },
      {
        key: "title",
        title: "Title",
        from: "title",
        type: "TextField",
      },
      {
        key: "abstract",
        title: "Abstract",
        from: "abstract",
        type: "TextField",
      },
      {
        title: "Image or video?",
        type: "ConditionalField",
        key: "imageOrVideo",
        from: "imageOrVideo",
      },
      {
        key: "image",
        title: "Image",
        from: "image",
        type: "ImageField",
      },
      {
        title: "URL Video",
        type: "TextField",
        key: "video",
        from: "video",
      },
      {
        title: "Thumbnail",
        type: "ComponentContainer",
        whiteList: ["Image"],
        key: "thumbnail",
        from: "thumbnail",
      },
      {
        key: "lead",
        title: "Lead",
        from: "lead",
        type: "TextField",
      },
      {
        key: "longAbstract",
        title: "longAbstract",
        from: "longAbstract",
        type: "RichText",
        html: true,
        humanReadable: true,
      },
      {
        key: "content",
        title: "Content",
        from: "content",
        type: "TextField",
      },
      {
        key: "newsDate",
        title: "Date",
        from: "newsDate",
        type: "TextField",
        indexable: true,
      },
    ],
    searchFrom: ["lead", "longAbstract", "content"],
  },
  clone: null,
  defaultValues: null,
}

export { NEWS }
