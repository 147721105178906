import { allModulesWhitelist } from "@helpers/schemas"
import { StoryDetail, StoryDetail2x } from "@thumbnails/Templates"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

// Defaults from modules
import CardCollection from "@schemas/modules/CardCollection"
import Wysiwyg from "@schemas/modules/Wysiwyg"

const twoDigits = num => {
  return `${num < 10 ? "0" : ""}${num}`
}

const today = () => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}/${twoDigits(month)}/${twoDigits(day)}`
}

export default {
  schemaType: "template",
  displayName: "Story Detail",
  component: "StoryDetail",
  dataPacks: ["STORIES"],
  type: { label: "Stories", value: "stories", mode: "detail" },
  content: [
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "genders",
          placeholder: "Gender",
          source: "GENDER",
        },
        {
          key: "stageTargets",
          placeholder: "Stage Target",
          source: "STAGE_TARGET",
        },
        {
          key: "regions",
          placeholder: "Region",
          source: "REGION",
        },
        {
          key: "formats",
          placeholder: "Format",
          source: "FORMAT",
        },
        {
          key: "centers",
          placeholder: "Centers & Departments",
          source: "CENTERS",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
        {
          key: "studyType",
          placeholder: "Study type",
          source: "STUDY_TYPE",
        },
        {
          key: "profiles",
          placeholder: "Profiles",
          source: "PROFILES",
        },
      ],
    },
    {
      title: "Story Data",
      type: "FieldGroup",
      key: "storyData",
      fields: [
        {
          key: "quote",
          title: "Quote",
          type: "RichText",
          html: true,
          mandatory: true,
          humanReadable: true,
          helptext: "Shown on the distributor card",
        },
        {
          key: "imageQuote",
          title: "Quote image",
          type: "ImageField",
        },
        {
          title: "Date",
          type: "DateField",
          mandatory: true,
          key: "dateTime",
          helptext: "This date will be used to sort in distributors and lists.",
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
        },
        {
          title: "About me",
          type: "RichText",
          html: true,
          key: "aboutMe",
          humanReadable: true,
        },
      ],
    },
    {
      title: "Quick Facts",
      type: "ComponentArray",
      maxItems: null,
      key: "moreQuickFacts",
      whiteList: ["QuickFactStories"],
      helptext:
        "The first quick fact marked to be shown on the card will appear as featured text",
    },
    {
      key: "program",
      title: "Program",
      type: "AsyncSelect",
      source: "PROGRAMS",
      hideable: true,
    },
    {
      title: "Social Share",
      type: "ComponentArray",
      maxItems: 1,
      key: "socialShare",
      whiteList: ["SocialShare"],
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      key: "mainContent",
      whiteList: allModulesWhitelist,
    },
    {
      title: "Show pagination",
      type: "RadioGroup",
      key: "showPagination",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "Yes",
        },
        {
          value: false,
          title: "No",
          name: "No",
        },
      ],
    },
    {
      title: "Image",
      type: "ImageField",
      key: "cardBackground",
      helptext: "Shown on pagination",
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    data: {
      quantity: 1,
      mode: "navigation",
      order: "recent-asc",
    },
    templateType: "StoryDetail",
    quote: "",
    imageQuote: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    date: today(),
    image: "",
    aboutMe:
      "Velit vitae id torquent placerat semper per sollicitudin ac, facilisi potenti curabitur eget lorem nascetur adipiscing.",
    moreQuickFacts: {
      component: "Section",
      name: "More Quick Facts",
      modules: [],
      sectionPosition: 2,
    },
    socialShare: {
      component: "Section",
      name: "Show Social Share",
      modules: [],
      sectionPosition: 3,
    },
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [{ ...Wysiwyg.default }, { ...CardCollection.default }],
      sectionPosition: 4,
    },
    showPagination: true,
    cardBackground: false,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 4,
    },
  },
  thumbnails: {
    "1x": StoryDetail,
    "2x": StoryDetail2x,
  },
}
