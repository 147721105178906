// * Lista de idiomas que estarán disponibles para el cliente
// * El orden en el que se presentan es el orden de prioridad
//      a la hora de seleccionar las traducciones.
// * El primer idioma indicado será el que se use como default
// * La key de cada idioma es SIEMPRE el locale, en formato xx_XX.
// * Cambiar el locale de un idioma o eliminar un idioma de la lista
//      afecta a los listados que luego se ven en AX, por lo que
//      son operaciones que hay que hacer con cuidado (hay que prepararlas
//      antes desde API, para que API cambie a mano en la BBDD el locale de
//      ese idioma antes de cambiarlo en DX).

export default {
  en: {
    name: "English",
    label: "EN",
    alias: ["en_GB"],
  },
  es: {
    name: "Spanish",
    label: "ES",
    alias: ["es_ES"],
  },
}

const translations = {
  en: {
    ok: "Ok",
    cancel: "Cancel",
    send: "Send",
  },
  es: {
    ok: "Aceptar",
    cancel: "Cancelar",
    send: "Enviar",
    hola: "hola",
  },
}

const getText = (language, text) => {
  const translateOrder = Object.keys(languages).filter(
    item => item !== language
  )
  translateOrder.unshift(language)
  for (const lang of translateOrder) {
    const response = translations[lang] && translations[lang][text]
    if (response) return response
  }
  console.error(
    `ERROR: Text "${text}" not defined. Please, review translations.`
  )
  return "Text undefined"
}
