export const overlapMenuHeros = [
  "HeroCarousel",
  "HeroClaim",
  "HeroImage",
  "HeroLanding",
  "HeroQuote",
  "HeroMosaic",
  "HeroVideo",
  "HeroProgram",
  "HeroProgramVideo",
]
