import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { HeroVideo, HeroVideo2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Video",
  component: "HeroVideo",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          type: "UniqueCheck",
          key: "noShowTitle",
          options: [
            {
              title: "Don't show title",
            },
          ],
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Video URL",
          type: "TextField",
          key: "video",
          hideable: true,
        },
        {
          type: "UniqueCheck",
          key: "automaticSubtitles",
          options: [
            {
              title: "Add automatic subtitles from Youtube",
            },
          ],
        },
        {
          title: "Video veil percentage",
          type: "SliderField",
          key: "veil",
          defaultValue: 50,
          hideable: true,
        },
        {
          title: "Button",
          type: "TextField",
          key: "button",
          hideable: true,
          helptext: "This button will only be visible on tablet and mobile",
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          mandatory: true,
          helptext:
            "Choose a representative image of the video because it will be the cover seen in the tablet and mobile versions. Fallback background image. Recommended minimum image size: 2508x1030",
        },
        {
          title: "Featured",
          type: "ComponentContainer",
          maxItems: 1,
          whiteList: ["HeroCard"],
          key: "heroCard",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Shadow",
          type: "UniqueCheck",
          label: "Add title shadow",
          key: "titleShadow",
          helptext: "Add text shadow",
          options: [
            {
              title: "Add title shadow",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "HeroVideo",
    anchorID: null,
    titleShadow: false,
    noShowTitle: false,
    subtitle: "Lorem Ipsum",
    button: "Watch the video",
    video: "",
    automaticSubtitles: false,
    veil: 50,
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    heroCard: {
      component: "HeroCard",
    },
    veilColor: "veil01",
    veilPercentage: 50,
  },
  thumbnails: {
    "1x": HeroVideo,
    "2x": HeroVideo2x,
  },
}
