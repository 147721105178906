import { PageContext, SiteContext } from "@contexts"
import { createSiteTheme, createSubTheme } from "@themes/utils"
import { useContext } from "react"
import { ThemeContext } from "styled-components"

// Breakpoint Alias
// Permite acceder a las propiedades rensponsive mediante objetos
// <Box width={{ s:"100px", xl:"300px"}} />
function addBreakpointAlias(theme) {
  theme.mediaquery?.mediaqueries.forEach(mq => {
    theme.breakpoints[mq.label] = mq.minWidth
  })
  return { ...theme }
}

function useTheme() {
  const value = useContext(SiteContext)
  const pageValue = useContext(PageContext)
  if (!value) {
    console.warn("Griddo: You forgot to put <PageProvider>.")
    return {}
  } else {
    const { theme: siteTheme } = value
    const { theme: pageTheme } = pageValue

    // Aquí hacemos override del theme de la siguiente manera:
    // Si hay un theme en la página, este será tomado en cuenta...
    // ...si no, prevalecerá el de site.
    const theme = pageTheme || siteTheme

    return createSiteTheme({ theme })
  }
}

function useSubTheme(name) {
  const { theme } = useContext(SiteContext)
  return createSubTheme({ theme, name }) || {}
}

function useStyledTheme() {
  const theme = useContext(ThemeContext)
  return theme || {}
}

function useStyledSubTheme({ name }) {
  try {
    const subtheme = useContext(ThemeContext)
    return addBreakpointAlias(subtheme.subthemes[name]) || {}
  } catch (e) {
    return {}
  }
}

export { useTheme, useSubTheme, useStyledTheme, useStyledSubTheme }
