export default {
  schemaType: "component",
  displayName: "Insights Card",
  component: "InsightsCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Type",
          type: "RadioGroup",
          key: "type",
          options: [
            {
              value: "articles",
              title: "Article",
              name: "articles",
            },
            {
              value: "videos",
              title: "Video",
              name: "videos",
            },
            {
              value: "podcasts",
              title: "Podcast",
              name: "podcasts",
            },
            {
              value: "infographics",
              title: "Infographic",
              name: "infographics",
            },
          ],
          mandatory: true,
        },
        {
          title: "Date",
          type: "DateField",
          key: "insightDate",
          mandatory: true,
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Extract",
          type: "RichText",
          html: true,
          key: "extract",
          humanReadable: true,
        },
        {
          title: "Image",
          type: "TextField",
          key: "image",
          mandatory: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
          mandatory: true,
        },
        {
          key: "topics",
          title: "Topic",
          type: "MultiCheckSelect",
          source: "TOPIC",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Size",
          type: "RadioGroup",
          key: "size",
          options: [
            {
              value: "big",
              title: "Big",
              name: "big",
            },
            {
              value: "small",
              title: "Small",
              name: "small",
            },
          ],
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: "InsightsCard",
    size: "small",
    type: "articles",
  },
}
