export const SOCIAL_SHARE_LINKS = {
  facebook: "https://www.facebook.com/sharer/sharer.php?u=",
  twitter: "https://twitter.com/intent/tweet?text=",
  linkedin: "https://www.linkedin.com/sharing/share-offsite/?url=",
  whatsapp: "https://api.whatsapp.com/send?text=",
}

export const SOCIAL_ICONS_MAP = {
  flicker: "flickr",
  instagram: "instagram",
  linkedIn: "linkedin",
  facebook: "facebook",
  twitter: "twitter",
  youTube: "youtube",
  youtube: "youtube",
  newsletter: "email",
  tiktok: "tiktok",
  snapchat: "snapchat",
  podcast: "podcast",
}

export const SOCIAL_IDS = {
  facebook: "social-button-facebook",
  instagram: "social-button-instagram",
  twitter: "social-button-twitter",
  linkedin: "social-button-linkedin",
  youtube: "social-button-youtube",
  podcast: "social-button-podcast",
}
