import { CircleChart, CircleChart2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Chart",
  component: "Chart",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Chart Name",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          humanReadable: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
        },
        {
          title: "Chart Style",
          key: "kind",
          type: "ConditionalField",
          options: [
            {
              title: "Circle chart",
              name: "CircleChart",
              value: "CircleChart",
            },
            {
              value: "SemiCircleChart",
              title: "Semi circle chart",
              name: "SemiCircleChart",
            },
            {
              value: "RadialChart",
              title: "Radial bars chart",
              name: "RadialChart",
            },
            {
              value: "BarChart",
              title: "Bar chart",
              name: "BarChart",
            },
            {
              value: "PolarAreaChart",
              title: "Polar area chart",
              name: "PolarAreaChart",
            },
          ],
          fields: [
            {
              condition: "BarChart",
              title: "Unit",
              type: "TextField",
              key: "unit",
              mandatory: true,
            },
          ],
        },
        {
          type: "ArrayFieldGroup",
          title: "",
          key: "chartData",
          name: "Data",
          fields: [
            {
              title: "Label",
              type: "TextField",
              key: "label",
              mandatory: true,
              isTitle: true,
              humanReadable: true,
            },
            {
              title: "Value",
              type: "TextField",
              key: "value",
              mandatory: true,
              humanReadable: true,
            },
            {
              title: "Color",
              type: "ColorPicker",
              key: "color",
              colors: [
                {
                  theme: "default-theme",
                  options: [
                    { name: "Color 1", hex: "#000066" },
                    { name: "Color 2", hex: "#46BEFF" },
                    { name: "Color 3", hex: "#0032A0" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                    { name: "Color 7", hex: "#0048E1" },
                  ],
                },
                {
                  theme: "sgpa-theme",
                  options: [
                    { name: "Color 1", hex: "#00808F" },
                    { name: "Color 2", hex: "#0097A9" },
                    { name: "Color 3", hex: "#016E7B" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                    { name: "Color 7", hex: "#00B8CD" },
                  ],
                },
                {
                  theme: "business-theme",
                  options: [
                    { name: "Color 1", hex: "#000066" },
                    { name: "Color 2", hex: "#0097DC" },
                    { name: "Color 3", hex: "#0032A0" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                    { name: "Color 7", hex: "#0048E1" },
                  ],
                },
                {
                  theme: "law-theme",
                  options: [
                    { name: "Color 1", hex: "#D9A2A7" },
                    { name: "Color 2", hex: "#D90011" },
                    { name: "Color 3", hex: "#B2000F" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                  ],
                },
                {
                  theme: "architecture-theme",
                  options: [
                    { name: "Color 1", hex: "#CCABCA" },
                    { name: "Color 2", hex: "#814F9C" },
                    { name: "Color 3", hex: "#572672" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                  ],
                },
                {
                  theme: "science-theme",
                  options: [
                    { name: "Color 1", hex: "#6DC201" },
                    { name: "Color 2", hex: "#549601" },
                    { name: "Color 3", hex: "#3F7200" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                  ],
                },
                {
                  theme: "emba-theme",
                  options: [
                    { name: "Color 1", hex: "#0F0F0F" },
                    { name: "Color 2", hex: "#4C4949" },
                    { name: "Color 3", hex: "#313940" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                  ],
                },
                {
                  theme: "humanities-theme",
                  options: [
                    { name: "Color 1", hex: "#FF630F" },
                    { name: "Color 2", hex: "#CE4800" },
                    { name: "Color 3", hex: "#A03901" },
                    { name: "Color 4", hex: "#646566" },
                    { name: "Color 5", hex: "#888A8C" },
                    { name: "Color 6", hex: "#D6D6D6" },
                  ],
                },
              ],
              mandatory: true,
              isThemePalette: true,
            },
          ],
        },
        {
          title: "Alternative text",
          type: "TextField",
          key: "alt",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "Chart",
    kind: "CircleChart",
    title: { content: "Chart Title", tag: "h2" },
    unit: "%",
    chartData: [
      {
        label: "Item 1",
        value: 40,
        color: "Color 5",
      },
      {
        label: "Item 2",
        value: 40,
        color: "Color 4",
      },
      {
        label: "Item 3",
        value: 20,
        color: "Color 6",
      },
    ],
    alt: "Chart description",
  },
  thumbnails: {
    "1x": CircleChart,
    "2x": CircleChart2x,
  },
}
