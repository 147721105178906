import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Add to calendar",
  component: "AddToCalendar",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Location",
          type: "TextField",
          key: "location",
          humanReadable: true,
        },
        {
          title: "Start time",
          placeholder: "hh:mm",
          helptext: "hh:mm",
          type: "TextField",
          key: "startsDateTime",
          mandatory: true,
        },
        {
          title: "End time",
          placeholder: "hh:mm",
          helptext: "hh:mm",
          type: "TextField",
          key: "endsDateTime",
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: "AddToCalendar",
    location: "",
    startsDateTime: "",
    endsDateTime: "",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
