export default {
  link: {
    fields: [
      {
        type: "TextField",
        title: "Auxiliar text",
        key: "auxText",
        humanReadable: true,
      },
      {
        type: "ImageField",
        title: "Image",
        key: "image",
        fullWidth: true,
      },
      {
        type: "ToggleField",
        title: "",
        key: "special",
        helptext: "Check it if the link has a special behavior.",
        background: true,
      },
    ],
  },

  group: {
    fields: [
      {
        type: "ImageField",
        title: "Image",
        key: "image",
        fullWidth: true,
      },
    ],
  },
}
