import { allModulesWhitelist } from "@helpers/schemas"
import { EventList, EventList2x } from "@thumbnails/Templates"

// Defaults from modules
import SocialMedia from "@schemas/modules/SocialMedia"

export default {
  schemaType: "template",
  displayName: "Event List",
  component: "EventList",
  dataPacks: ["EVENTS"],
  type: { label: "Events", value: "events" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
      ],
      key: "heroSection",
      mandatory: true,
    },
    {
      title: "Lead text",
      type: "TextField",
      key: "leadText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showStageTargetFilter",
              title: "Show Stage Target filter",
              name: "stageTargetFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showRegionFilter",
              title: "Show Region filter",
              name: "regionFilter",
            },
            {
              key: "ShowFormatFilter",
              title: "Show Fotmat filter",
              name: "formatFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showEventTypeFilter",
              title: "Show Event type filter",
              name: "eventTypeFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "stageTargetLabel",
          title: "Stage target label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "regionLabel",
          title: "Region label",
          type: "TextField",
        },
        {
          key: "formatLabel",
          title: "Format label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
        {
          key: "eventTypeLabel",
          title: "Event type label",
          type: "TextField",
        },
      ],
    },
    {
      title: "",
      type: "ReferenceField",
      sources: [
        { structuredData: "EVENTS" },
        { structuredData: "EVENTS_AUTO" },
      ],
      key: "data",
    },
    {
      title: "No results text",
      type: "TextField",
      key: "noResultsText",
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 10,
      mandatory: true,
    },
    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "EventList",
    layout: "L001",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    leadText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "EVENTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "EVENTS_AUTO",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      quantity: 15,
      preferenceLanguage: false,
    },
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 10,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related content",
      modules: [{ ...SocialMedia.default }],
      sectionPosition: 2,
    },
    activePage: 1,
    slug: "events",
  },
  thumbnails: {
    "1x": EventList,
    "2x": EventList2x,
  },
}
