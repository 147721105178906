// ----------------------------------------------------------
// Estos métodos accedel al archivo de themes: allThemes.json
// ----------------------------------------------------------

import { createGlobalStyle } from "styled-components"
import themes from "./.allThemes"

function createGlobalTheme() {
  return themes.globalTheme
}

function createSiteTheme({ theme }) {
  const userTheme = themes[theme]
  if (userTheme) {
    return userTheme.siteTheme
  } else {
    console.warn(
      `Griddo: Theme ${theme} doesn't exist. Using a fallback theme.`
    )
    return themes["default-theme"].siteTheme
  }
}

function createSubThemes({ theme }) {
  const userSubTheme = themes[theme]
  if (userSubTheme) {
    return userSubTheme.subThemes
  } else {
    console.warn(
      `Griddo: Theme ${theme} doesn't exist. Can't create subthemes. Using a fallback theme.`
    )
    return themes["default-theme"].subThemes
  }
}

function createSubTheme({ theme, name }) {
  return createSubThemes({ theme })[name]
}

const GlobalCSS = createGlobalStyle`
  html, body {
    line-height: ${p => p.theme.lineHeightBase};
    font-family: ${p => p.theme.fontFamily.primary};
    color: ${p => p.theme.colors.text01};
    background-color: ${p => p.theme.colors.primaryBackground};
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
  }

  * { box-sizing: border-box; }
  a { color: inherit; text-decoration: none; }
  ${"" /* // Las clases thesa- son de thesaurus wysiwyg */}
  ${"" /* // Las clases fr- son de froala */}
  ${"" /* // La clase modal-image es para las imágenes de los modales */}
  #iesite img:not([class*="thesa-"]):not([class*="fr-"]):not([class*="modal-image"]) {
     display: block; max-width: 100%;
  }

  em { font-style: italic; }

  /* Collapse */
  /* :root { scroll-behavior: smooth; } */
  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  /* Reset H1 from normalize */
  h1 { margin:0; font-size: unset; }
`

function createPageTheme(theme) {
  const globalTheme = createGlobalTheme()
  // Breakpoints Alias.
  // Ahora es posible también acceder a los breakpoints de las props mediante un
  // objeto:
  // <Box width={{ s: "100px", xl: "300px" }}
  globalTheme.mediaquery.mediaqueries.forEach(mq => {
    globalTheme.breakpoints[mq.label] = mq.minWidth
  })

  const siteTheme = {
    ...createSiteTheme({ theme: theme }),
    subthemes: {
      ...createSubThemes({ theme: theme }),
    },
  }

  // If siteTheme has mediaqueries...
  if (siteTheme.mediaquery) {
    siteTheme.mediaquery.mediaqueries.forEach(mq => {
      siteTheme.breakpoints[mq.label] = mq.minWidth
    })
  }

  return { ...globalTheme, ...siteTheme }
}

export {
  createGlobalTheme,
  createSiteTheme,
  createSubThemes,
  createSubTheme,
  GlobalCSS,
  createPageTheme,
}
