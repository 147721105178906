import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { ImageGallery, ImageGallery2x } from "@thumbnails/Modules"
import { ImageGalleryLayout1, ImageGalleryLayout2 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Image Gallery",
  component: "ImageGallery",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,

          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          key: "elements",
          mandatory: true,
          maxItems: null,
          whiteList: ["Image"],
          helptext: "Recommended minimum image size: 1920x1080",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: ImageGalleryLayout1,
            },
            {
              value: "L002",
              img: ImageGalleryLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "ImageGallery",
    verticalSpacing: "medium",
    theme: "default",
    layout: "L001",
    title: { content: "Title", tag: "h2" },
    subtitle:
      "Nostrud aliquip occaecat do nisi id proident cupidatat eu cillum. Labore eiusmod cupidatat consectetur reprehenderit non esse labore occaecat sits aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    detail: null,
    elements: [
      {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
      {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
      {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
      {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
    ],
  },
  thumbnails: {
    "1x": ImageGallery,
    "2x": ImageGallery2x,
  },
}
