import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import { mapPoint, shape, case2, student, info } from "@theme-img"

export default {
  schemaType: "component",
  displayName: "Quick Fact",
  component: "QuickFactStories",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Detail",
          key: "detail",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Icon",
          key: "icon",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "shape",
              img: shape,
            },
            {
              value: "mapPoint",
              img: mapPoint,
            },
            {
              value: "case2",
              img: case2,
            },
            {
              value: "student",
              img: student,
            },
            {
              value: "info",
              img: info,
            },
          ],
          columns: 5,
        },
        {
          title: "Show in card",
          type: "ConditionalField",
          key: "showInCard",
          mandatory: true,
          options: [
            {
              name: "yes",
              value: "yes",
              title: "Yes",
            },
            {
              name: "no",
              value: "no",
              title: "No",
            },
          ],
          fields: [
            {
              condition: "no",
              title: "Show in hover",
              type: "RadioGroup",
              key: "showInHover",
              mandatory: true,
              options: [
                {
                  name: "yes",
                  value: "yes",
                  title: "Yes",
                },
                {
                  name: "no",
                  value: "no",
                  title: "No",
                },
              ],
            },
          ],
        },
      ],
    },
    { title: "config", fields: [] },
  ],
  default: {
    component: "QuickFactStories",
    title: "Title",
    detail: "Lorem Ipsum",
    showInCard: "no",
    showInHover: "no",
    icon: "shape",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
