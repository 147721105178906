import calendar2CTA from "./CTAIcons/calendar2.png"
import cloudDownload from "./CTAIcons/cloudDownload.png"
import comments from "./CTAIcons/comments.png"
import download from "./CTAIcons/download.png"
import downloadBox from "./CTAIcons/downloadBox.png"
import edit from "./CTAIcons/edit.png"
import envelope from "./CTAIcons/envelope.png"
import externalLink from "./CTAIcons/externalLink.png"
import lock from "./CTAIcons/lock.png"
import share from "./CTAIcons/share.png"

import themeIconEmail from "./CTAIcons/email.png"
import themeIconChat from "./CTAIcons/chat.png"
import themeIconWhatsapp from "./CTAIcons/whatsapp.png"
import themeIconPhone from "./CTAIcons/phone.png"
import themeIconVideo from "./CTAIcons/video.png"
import themeHeaderStyle1 from "./HeaderStyle01.png"
import themeHeaderStyle2 from "./HeaderStyle02.png"
import spacerDecorationNone from "./spacerDecorationNone@2x.png"
import spacerDecorationLine from "./spacerDecorationLine@2x.png"
import calendar from "./quickFactsIcons/calendar.png"
import calendar2 from "./quickFactsIcons/calendar2.png"
import comment from "./quickFactsIcons/comment.png"
import device from "./quickFactsIcons/device.png"
import mapPoint from "./quickFactsIcons/mapPoint.png"
import time from "./quickFactsIcons/time.png"
import case2 from "./quickFactsIcons/case2.png"
import shape from "./quickFactsIcons/shape.png"
import student from "./quickFactsIcons/student.png"
import info from "./quickFactsIcons/info.png"

// Themes
// Architecture-theme
import imageAccentArchitecture from "./architecture-theme/accent.png"
import imageAccentLightArchitecture from "./architecture-theme/accentLight.png"
import imageAccentMidArchitecture from "./architecture-theme/accentMid.png"
import imageAccentAltArchitecture from "./architecture-theme/accentAlt.png"
import imageDefaultArchitecture from "./architecture-theme/default.png"
import imageDefaultAltArchitecture from "./architecture-theme/defaultAlt.png"
import imageInverseArchitecture from "./architecture-theme/inverse.png"
import backgroundImageAccentArchitecture from "./architecture-theme/background/accent.png"
import backgroundImageAccentLightArchitecture from "./architecture-theme/background/accentLight.png"
import backgroundImageAccentMidArchitecture from "./architecture-theme/background/accentMid.png"
import backgroundImageAccentAltArchitecture from "./architecture-theme/background/accentAlt.png"
import backgroundImageDefaultArchitecture from "./architecture-theme/background/default.png"
import backgroundImageDefaultAltArchitecture from "./architecture-theme/background/defaultAlt.png"
import backgroundImageInverseArchitecture from "./architecture-theme/background/inverse.png"

// Business-theme
import imageAccentBusiness from "./business-theme/accent.png"
import imageAccentLightBusiness from "./business-theme/accentLight.png"
import imageAccentMidBusiness from "./business-theme/accentMid.png"
import imageAccentAltBusiness from "./business-theme/accentAlt.png"
import imageDefaultBusiness from "./business-theme/default.png"
import imageDefaultAltBusiness from "./business-theme/defaultAlt.png"
import imageInverseBusiness from "./business-theme/inverse.png"
import backgroundImageAccentBusiness from "./business-theme/background/accent.png"
import backgroundImageAccentLightBusiness from "./business-theme/background/accentLight.png"
import backgroundImageAccentMidBusiness from "./business-theme/background/accentMid.png"
import backgroundImageAccentAltBusiness from "./business-theme/background/accentAlt.png"
import backgroundImageDefaultBusiness from "./business-theme/background/default.png"
import backgroundImageDefaultAltBusiness from "./business-theme/background/defaultAlt.png"
import backgroundImageInverseBusiness from "./business-theme/background/inverse.png"

// Default-theme
import imageAccentDefault from "./default-theme/accent.png"
import imageAccentLightDefault from "./default-theme/accentLight.png"
import imageAccentMidDefault from "./default-theme/accentMid.png"
import imageAccentAltDefault from "./default-theme/accentAlt.png"
import imageDefaultDefault from "./default-theme/default.png"
import imageDefaultAltDefault from "./default-theme/defaultAlt.png"
import imageInverseDefault from "./default-theme/inverse.png"
import backgroundImageAccentDefault from "./default-theme/background/accent.png"
import backgroundImageAccentLightDefault from "./default-theme/background/accentLight.png"
import backgroundImageAccentMidDefault from "./default-theme/background/accentMid.png"
import backgroundImageAccentAltDefault from "./default-theme/background/accentAlt.png"
import backgroundImageDefaultDefault from "./default-theme/background/default.png"
import backgroundImageDefaultAltDefault from "./default-theme/background/defaultAlt.png"
import backgroundImageInverseDefault from "./default-theme/background/inverse.png"

// Emba-theme
import imageAccentEmba from "./emba-theme/accent.png"
import imageAccentLightEmba from "./emba-theme/accentLight.png"
import imageAccentMidEmba from "./emba-theme/accentMid.png"
import imageAccentAltEmba from "./emba-theme/accentAlt.png"
import imageDefaultEmba from "./emba-theme/default.png"
import imageDefaultAltEmba from "./emba-theme/defaultAlt.png"
import backgroundImageAccentEmba from "./emba-theme/background/accent.png"
import backgroundImageAccentLightEmba from "./emba-theme/background/accentLight.png"
import backgroundImageAccentMidEmba from "./emba-theme/background/accentMid.png"
import backgroundImageAccentAltEmba from "./emba-theme/background/accentAlt.png"
import backgroundImageDefaultEmba from "./emba-theme/background/default.png"
import backgroundImageDefaultAltEmba from "./emba-theme/background/defaultAlt.png"
import backgroundImageInverseEmba from "./emba-theme/background/inverse.png"

// Humanities-theme
import imageAccentHumanities from "./humanities-theme/accent.png"
import imageAccentLightHumanities from "./humanities-theme/accentLight.png"
import imageAccentMidHumanities from "./humanities-theme/accentMid.png"
import imageAccentAltHumanities from "./humanities-theme/accentAlt.png"
import imageDefaultHumanities from "./humanities-theme/default.png"
import imageDefaultAltHumanities from "./humanities-theme/defaultAlt.png"
import imageInverseHumanities from "./humanities-theme/inverse.png"
import backgroundImageAccentHumanities from "./humanities-theme/background/accent.png"
import backgroundImageAccentLightHumanities from "./humanities-theme/background/accentLight.png"
import backgroundImageAccentMidHumanities from "./humanities-theme/background/accentMid.png"
import backgroundImageAccentAltHumanities from "./humanities-theme/background/accentAlt.png"
import backgroundImageDefaultHumanities from "./humanities-theme/background/default.png"
import backgroundImageDefaultAltHumanities from "./humanities-theme/background/defaultAlt.png"
import backgroundImageInverseHumanities from "./humanities-theme/background/inverse.png"

// Law-theme
import imageAccentLaw from "./law-theme/accent.png"
import imageAccentLightLaw from "./law-theme/accentLight.png"
import imageAccentMidLaw from "./law-theme/accentMid.png"
import imageAccentAltLaw from "./law-theme/accentAlt.png"
import imageDefaultLaw from "./law-theme/default.png"
import imageDefaultAltLaw from "./law-theme/defaultAlt.png"
import imageInverseLaw from "./law-theme/inverse.png"
import backgroundImageAccentLaw from "./law-theme/background/accent.png"
import backgroundImageAccentLightLaw from "./law-theme/background/accentLight.png"
import backgroundImageAccentMidLaw from "./law-theme/background/accentMid.png"
import backgroundImageAccentAltLaw from "./law-theme/background/accentAlt.png"
import backgroundImageDefaultLaw from "./law-theme/background/default.png"
import backgroundImageDefaultAltLaw from "./law-theme/background/defaultAlt.png"
import backgroundImageInverseLaw from "./law-theme/background/inverse.png"

// Science-theme
import imageAccentScience from "./science-theme/accent.png"
import imageAccentLightScience from "./science-theme/accentLight.png"
import imageAccentMidScience from "./science-theme/accentMid.png"
import imageAccentAltScience from "./science-theme/accentAlt.png"
import imageDefaultScience from "./science-theme/default.png"
import imageDefaultAltScience from "./science-theme/defaultAlt.png"
import imageInverseScience from "./science-theme/inverse.png"
import backgroundImageAccentScience from "./science-theme/background/accent.png"
import backgroundImageAccentLightScience from "./science-theme/background/accentLight.png"
import backgroundImageAccentMidScience from "./science-theme/background/accentMid.png"
import backgroundImageAccentAltScience from "./science-theme/background/accentAlt.png"
import backgroundImageDefaultScience from "./science-theme/background/default.png"
import backgroundImageDefaultAltScience from "./science-theme/background/defaultAlt.png"
import backgroundImageInverseScience from "./science-theme/background/inverse.png"

// Sgpa-theme
import imageAccentSgpa from "./sgpa-theme/accent.png"
import imageAccentLightSgpa from "./sgpa-theme/accentLight.png"
import imageAccentMidSgpa from "./sgpa-theme/accentMid.png"
import imageAccentAltSgpa from "./sgpa-theme/accentAlt.png"
import imageDefaultSgpa from "./sgpa-theme/default.png"
import imageDefaultAltSgpa from "./sgpa-theme/defaultAlt.png"
import imageInverseSgpa from "./sgpa-theme/inverse.png"
import backgroundImageAccentSgpa from "./sgpa-theme/background/accent.png"
import backgroundImageAccentLightSgpa from "./sgpa-theme/background/accentLight.png"
import backgroundImageAccentMidSgpa from "./sgpa-theme/background/accentMid.png"
import backgroundImageAccentAltSgpa from "./sgpa-theme/background/accentAlt.png"
import backgroundImageDefaultSgpa from "./sgpa-theme/background/default.png"
import backgroundImageDefaultAltSgpa from "./sgpa-theme/background/defaultAlt.png"
import backgroundImageInverseSgpa from "./sgpa-theme/background/inverse.png"

const themes = {
  "architecture-theme": {
    accent: imageAccentArchitecture,
    accentLight: imageAccentLightArchitecture,
    accentMid: imageAccentMidArchitecture,
    accentAlt: imageAccentAltArchitecture,
    default: imageDefaultArchitecture,
    defaultAlt: imageDefaultAltArchitecture,
    inverse: imageInverseArchitecture,
  },
  "business-theme": {
    accent: imageAccentBusiness,
    accentLight: imageAccentLightBusiness,
    accentMid: imageAccentMidBusiness,
    accentAlt: imageAccentAltBusiness,
    default: imageDefaultBusiness,
    defaultAlt: imageDefaultAltBusiness,
    inverse: imageInverseBusiness,
  },
  "default-theme": {
    accent: imageAccentDefault,
    accentLight: imageAccentLightDefault,
    accentMid: imageAccentMidDefault,
    accentAlt: imageAccentAltDefault,
    default: imageDefaultDefault,
    defaultAlt: imageDefaultAltDefault,
    inverse: imageInverseDefault,
  },
  "emba-theme": {
    accent: imageAccentEmba,
    accentLight: imageAccentLightEmba,
    accentMid: imageAccentMidEmba,
    accentAlt: imageAccentAltEmba,
    default: imageDefaultEmba,
    defaultAlt: imageDefaultAltEmba,
  },
  "humanities-theme": {
    accent: imageAccentHumanities,
    accentLight: imageAccentLightHumanities,
    accentMid: imageAccentMidHumanities,
    accentAlt: imageAccentAltHumanities,
    default: imageDefaultHumanities,
    defaultAlt: imageDefaultAltHumanities,
    inverse: imageInverseHumanities,
  },
  "law-theme": {
    accent: imageAccentLaw,
    accentLight: imageAccentLightLaw,
    accentMid: imageAccentMidLaw,
    accentAlt: imageAccentAltLaw,
    default: imageDefaultLaw,
    defaultAlt: imageDefaultAltLaw,
    inverse: imageInverseLaw,
  },
  "science-theme": {
    accent: imageAccentScience,
    accentLight: imageAccentLightScience,
    accentMid: imageAccentMidScience,
    accentAlt: imageAccentAltScience,
    default: imageDefaultScience,
    defaultAlt: imageDefaultAltScience,
    inverse: imageInverseScience,
  },
  "sgpa-theme": {
    accent: imageAccentSgpa,
    accentLight: imageAccentLightSgpa,
    accentMid: imageAccentMidSgpa,
    accentAlt: imageAccentAltSgpa,
    default: imageDefaultSgpa,
    defaultAlt: imageDefaultAltSgpa,
    inverse: imageInverseSgpa,
  },
}

const backgroundThemes = {
  "architecture-theme": {
    accent: backgroundImageAccentArchitecture,
    accentLight: backgroundImageAccentLightArchitecture,
    accentMid: backgroundImageAccentMidArchitecture,
    accentAlt: backgroundImageAccentAltArchitecture,
    default: backgroundImageDefaultArchitecture,
    defaultAlt: backgroundImageDefaultAltArchitecture,
    inverse: backgroundImageInverseArchitecture,
  },
  "business-theme": {
    accent: backgroundImageAccentBusiness,
    accentLight: backgroundImageAccentLightBusiness,
    accentMid: backgroundImageAccentMidBusiness,
    accentAlt: backgroundImageAccentAltBusiness,
    default: backgroundImageDefaultBusiness,
    defaultAlt: backgroundImageDefaultAltBusiness,
    inverse: backgroundImageInverseBusiness,
  },
  "default-theme": {
    accent: backgroundImageAccentDefault,
    accentLight: backgroundImageAccentLightDefault,
    accentMid: backgroundImageAccentMidDefault,
    accentAlt: backgroundImageAccentAltDefault,
    default: backgroundImageDefaultDefault,
    defaultAlt: backgroundImageDefaultAltDefault,
    inverse: backgroundImageInverseDefault,
  },
  "emba-theme": {
    accent: backgroundImageAccentEmba,
    accentLight: backgroundImageAccentLightEmba,
    accentMid: backgroundImageAccentMidEmba,
    accentAlt: backgroundImageAccentAltEmba,
    default: backgroundImageDefaultEmba,
    defaultAlt: backgroundImageDefaultAltEmba,
    inverse: backgroundImageInverseEmba,
  },
  "humanities-theme": {
    accent: backgroundImageAccentHumanities,
    accentLight: backgroundImageAccentLightHumanities,
    accentMid: backgroundImageAccentMidHumanities,
    accentAlt: backgroundImageAccentAltHumanities,
    default: backgroundImageDefaultHumanities,
    defaultAlt: backgroundImageDefaultAltHumanities,
    inverse: backgroundImageInverseHumanities,
  },
  "law-theme": {
    accent: backgroundImageAccentLaw,
    accentLight: backgroundImageAccentLightLaw,
    accentMid: backgroundImageAccentMidLaw,
    accentAlt: backgroundImageAccentAltLaw,
    default: backgroundImageDefaultLaw,
    defaultAlt: backgroundImageDefaultAltLaw,
    inverse: backgroundImageInverseLaw,
  },
  "science-theme": {
    accent: backgroundImageAccentScience,
    accentLight: backgroundImageAccentLightScience,
    accentMid: backgroundImageAccentMidScience,
    accentAlt: backgroundImageAccentAltScience,
    default: backgroundImageDefaultScience,
    defaultAlt: backgroundImageDefaultAltScience,
    inverse: backgroundImageInverseScience,
  },
  "sgpa-theme": {
    accent: backgroundImageAccentSgpa,
    accentLight: backgroundImageAccentLightSgpa,
    accentMid: backgroundImageAccentMidSgpa,
    accentAlt: backgroundImageAccentAltSgpa,
    default: backgroundImageDefaultSgpa,
    defaultAlt: backgroundImageDefaultAltSgpa,
    inverse: backgroundImageInverseSgpa,
  },
}

//LinkStyles
// Architecture-theme
import linkStyleArchitecture from "./architecture-theme/linkStyle.png"
import primaryStyleArchitecture from "./architecture-theme/primaryStyle.png"
import secondaryStyleArchitecture from "./architecture-theme/secondaryStyle.png"

// Business-theme
import linkStyleBusiness from "./business-theme/linkStyle.png"
import primaryStyleBusiness from "./business-theme/primaryStyle.png"
import secondaryStyleBusiness from "./business-theme/secondaryStyle.png"

// Default-theme
import linkStyleDefault from "./default-theme/linkStyle.png"
import primaryStyleDefault from "./default-theme/primaryStyle.png"
import secondaryStyleDefault from "./default-theme/secondaryStyle.png"

// Emba-theme
import linkStyleEmba from "./emba-theme/linkStyle.png"
import primaryStyleEmba from "./emba-theme/primaryStyle.png"
import secondaryStyleEmba from "./emba-theme/secondaryStyle.png"

// Humanities-theme
import linkStyleHumanities from "./humanities-theme/linkStyle.png"
import primaryStyleHumanities from "./humanities-theme/primaryStyle.png"
import secondaryStyleHumanities from "./humanities-theme/secondaryStyle.png"

// Law-theme
import linkStyleLaw from "./law-theme/linkStyle.png"
import primaryStyleLaw from "./law-theme/primaryStyle.png"
import secondaryStyleLaw from "./law-theme/secondaryStyle.png"

// Science-theme
import linkStyleScience from "./science-theme/linkStyle.png"
import primaryStyleScience from "./science-theme/primaryStyle.png"
import secondaryStyleScience from "./science-theme/secondaryStyle.png"

// Sgpa-theme
import linkStyleSgpa from "./sgpa-theme/linkStyle.png"
import primaryStyleSgpa from "./sgpa-theme/primaryStyle.png"
import secondaryStyleSgpa from "./sgpa-theme/secondaryStyle.png"

//MapIcons
// Architecture-theme
import bigArchitecture from "./architecture-theme/location-map/big.png"
import mediumArchitecture from "./architecture-theme/location-map/medium.png"
import smallArchitecture from "./architecture-theme/location-map/small.png"

// Business-theme
import bigBusiness from "./business-theme/location-map/big.png"
import mediumBusiness from "./business-theme/location-map/medium.png"
import smallBusiness from "./business-theme/location-map/small.png"

// Default-theme
import bigDefault from "./default-theme/location-map/big.png"
import mediumDefault from "./default-theme/location-map/medium.png"
import smallDefault from "./default-theme/location-map/small.png"

// Emba-theme
import bigEmba from "./emba-theme/location-map/big.png"
import mediumEmba from "./emba-theme/location-map/medium.png"
import smallEmba from "./emba-theme/location-map/small.png"

// Humanities-theme
import bigHumanities from "./humanities-theme/location-map/big.png"
import mediumHumanities from "./humanities-theme/location-map/medium.png"
import smallHumanities from "./humanities-theme/location-map/small.png"

// Law-theme
import bigLaw from "./law-theme/location-map/big.png"
import mediumLaw from "./law-theme/location-map/medium.png"
import smallLaw from "./law-theme/location-map/small.png"

// Science-theme
import bigScience from "./science-theme/location-map/big.png"
import mediumScience from "./science-theme/location-map/medium.png"
import smallScience from "./science-theme/location-map/small.png"

// Sgpa-theme
import bigSgpa from "./sgpa-theme/location-map/big.png"
import mediumSgpa from "./sgpa-theme/location-map/medium.png"
import smallSgpa from "./sgpa-theme/location-map/small.png"

const mapIcons = {
  "architecture-theme": {
    big: bigArchitecture,
    medium: mediumArchitecture,
    small: smallArchitecture,
  },
  "business-theme": {
    big: bigBusiness,
    medium: mediumBusiness,
    small: smallBusiness,
  },
  "default-theme": {
    big: bigDefault,
    medium: mediumDefault,
    small: smallDefault,
  },
  "emba-theme": {
    big: bigEmba,
    medium: mediumEmba,
    small: smallEmba,
  },
  "humanities-theme": {
    big: bigHumanities,
    medium: mediumHumanities,
    small: smallHumanities,
  },
  "law-theme": {
    big: bigLaw,
    medium: mediumLaw,
    small: smallLaw,
  },
  "science-theme": {
    big: bigScience,
    medium: mediumScience,
    small: smallScience,
  },
  "sgpa-theme": {
    big: bigSgpa,
    medium: mediumSgpa,
    small: smallSgpa,
  },
}

const linkStyle = {
  "architecture-theme": {
    link: linkStyleArchitecture,
    primary: primaryStyleArchitecture,
    secondary: secondaryStyleArchitecture,
  },
  "business-theme": {
    link: linkStyleBusiness,
    primary: primaryStyleBusiness,
    secondary: secondaryStyleBusiness,
  },
  "default-theme": {
    link: linkStyleDefault,
    primary: primaryStyleDefault,
    secondary: secondaryStyleDefault,
  },
  "emba-theme": {
    link: linkStyleEmba,
    primary: primaryStyleEmba,
    secondary: secondaryStyleEmba,
  },
  "humanities-theme": {
    link: linkStyleHumanities,
    primary: primaryStyleHumanities,
    secondary: secondaryStyleHumanities,
  },
  "law-theme": {
    link: linkStyleLaw,
    primary: primaryStyleLaw,
    secondary: secondaryStyleLaw,
  },
  "science-theme": {
    link: linkStyleScience,
    primary: primaryStyleScience,
    secondary: secondaryStyleScience,
  },
  "sgpa-theme": {
    link: linkStyleSgpa,
    primary: primaryStyleSgpa,
    secondary: secondaryStyleSgpa,
  },
}

export {
  themeIconEmail,
  themeIconChat,
  themeIconWhatsapp,
  themeIconPhone,
  themeIconVideo,
  themeHeaderStyle1,
  themeHeaderStyle2,
  calendar2CTA,
  cloudDownload,
  comments,
  download,
  downloadBox,
  edit,
  envelope,
  externalLink,
  lock,
  share,
  calendar,
  calendar2,
  mapPoint,
  comment,
  device,
  time,
  case2,
  shape,
  student,
  info,
  spacerDecorationNone,
  spacerDecorationLine,
  themes,
  backgroundThemes,
  linkStyle,
  mapIcons,
}
