import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { DocumentDownload, DocumentDownload2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Document Download",
  component: "DocumentDownload",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title Document",
          type: "TextField",
          key: "titleDocument",
          default: "Title Document",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Document file",
          type: "UrlField",
          key: "documentFile",
          helptext: "Add link to a pdf or any document type",
          placeholder: "Placeholder text",
          mandatory: true,
          hideable: false,
          advanced: true,
        },
        {
          title: "Image document",
          key: "imageDocument",
          type: "ImageField",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Module Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },

        {
          title: "Background style",
          key: "backgroundTheme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "DocumentDownload",
    titleDocument: "Title",
    theme: "accent",
    background: "dark",
    documentFile: {
      external: true,
      href: "",
      icon: "",
      label: "",
      newTab: false,
      noFollow: false,
      linkTo: null,
      linkToURL: null,
    },
    backgroundTheme: "default",
    imageDocument: {
      component: "Image",
      ...DEFAULT_IMAGE_FIELD,
    },
  },
  thumbnails: {
    "1x": DocumentDownload,
    "2x": DocumentDownload2x,
  },
}
