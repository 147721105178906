import { Image, Image2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Image",
  component: "Image",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "File",
          type: "ImageField",
          key: "file",
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "Veil percentage",
          type: "SliderField",
          key: "veil",
        },
      ],
    },
  ],
  default: {
    component: "Image",
    file: "",
    title: "",
    veil: "",
  },
  thumbnails: {
    "1x": Image,
    "2x": Image2x,
  },
}
