import { allModulesWhitelist } from "@helpers/schemas"
import { ProjectList, ProjectList2x } from "@thumbnails/Templates"
import {
  ProjectListLayout1,
  ProjectListLayout2,
  ProjectListLayout3,
  ProjectListLayout4,
} from "@layouts"

export default {
  schemaType: "template",
  displayName: "Project List",
  component: "ProjectList",
  dataPacks: ["PROJECTS"],
  type: { label: "Projects", value: "projects" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
      mandatory: true,
    },
    {
      title: "Title",
      key: "secondTitle",
      type: "TextField",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Subtitle",
      key: "subtitle",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Detail",
      key: "detail",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showRegionFilter",
              title: "Show Region filter",
              name: "regionFilter",
            },
            {
              value: "showSectorFilter",
              title: "Show Sector filter",
              name: "sectorFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "regionLabel",
          title: "Stage Region label",
          type: "TextField",
        },
        {
          key: "sectorLabel",
          title: "Sector label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "PROJECTS" }],
      key: "data",
    },
    {
      title: "Content hierarchy for SEO",
      type: "Select",
      key: "cardTitleTag",
      options: [
        {
          value: "h2",
          label: "H2",
        },
        {
          value: "h3",
          label: "H3",
        },
        {
          value: "h4",
          label: "H4",
        },
        {
          value: "span",
          label: "span",
        },
      ],
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 5,
      minValue: 1,
      maxValue: 6,
      mandatory: true,
    },
    {
      title: "Related content",
      key: "relatedContent",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
    },
  ],
  config: [
    {
      title: "Anchor ID",
      type: "TextField",
      key: "anchorID",
      prefix: "#",
      hideable: true,
      helptext:
        "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
    },
    {
      title: "Layout",
      key: "layout",
      type: "VisualUniqueSelection",
      options: [
        {
          value: "L001",
          img: ProjectListLayout1,
        },
        {
          value: "L002",
          img: ProjectListLayout2,
        },
        {
          value: "L003",
          img: ProjectListLayout3,
        },
        {
          value: "L004",
          img: ProjectListLayout4,
        },
      ],
      columns: 3,
    },
    {
      title: "Vertical Spacing",
      key: "verticalSpacing",
      type: "RadioGroup",
      options: [
        { name: "small", value: "small", title: "Small" },
        { name: "medium", value: "medium", title: "Medium" },
        { name: "large", value: "large", title: "Large" },
      ],
    },
  ],

  default: {
    type: "template",
    templateType: "ProjectList",
    templateOptions: [],
    filtersType: "simple",
    layout: "L001",
    verticalSpacing: "medium",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    cardTitleTag: "h3",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    hasDistributorData: false,
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "PROJECTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 15,
      preferenceLanguage: false,
    },
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 5,
    relatedContent: {
      component: "Section",
      name: "Related content",
      modules: [],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": ProjectList,
    "2x": ProjectList2x,
  },
}
