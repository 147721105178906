export const IMAGE = {
  simple: "https://images.qa.thesaurus.ie.edu/technology-jwobxg",
  thesaurus: "https://images.qa.thesaurus.ie.edu/oxygenbg_2",
  vertical: "https://images.qa.thesaurus.ie.edu/vertical-placeholder",
  verticalWidth: "2002",
  verticalHeight: "2502",
  verticalRatio: "2002/2502",
  horizontal: "https://images.qa.thesaurus.ie.edu/horizontal-placeholder",
  horizontalRatio: "1950/1300",

  logoHorizontal:
    "https://images.qa.thesaurus.ie.edu/logohorizontal-placeholder",
  verticalWidth: "800",
  verticalHeight: "450",
  verticalRatio: "800/450",

  logoSquared: "https://images.qa.thesaurus.ie.edu/logosquared-placeholder",
  verticalWidth: "800",
  verticalHeight: "450",
  verticalRatio: "800/450",

  lines: "https://images.qa.thesaurus.ie.edu/lines-placeholder",
  circle: "https://images.qa.thesaurus.ie.edu/circle-placeholder",
  hero1: "https://images.qa.thesaurus.ie.edu/hero-placeholder",
  hero2: "https://images.qa.thesaurus.ie.edu/hero2-placeholder",
  hero3: "https://images.qa.thesaurus.ie.edu/hero3-placeholder",
  hero4: "https://images.qa.thesaurus.ie.edu/hero4-placeholder",

  oxygen: "https://images.qa.thesaurus.ie.edu/oxygenbg_2",
  oxygenurl: "https://images.qa.thesaurus.ie.edu/oxygenbg_2",
}

export const VIDEO = {
  youtube: "https://www.youtube.com/watch?v=e8c-IA-kML8",
}
