import { getThemeOptions } from "@helpers/schemas"
import { Footer, Footer2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "School Footer",
  component: "SchoolFooter",
  category: "footer",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "Button",
          type: "ComponentContainer",
          key: "button",
          whiteList: ["Link"],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "SchoolFooter",
    title: "Footer title",
    button: {
      component: "Link",
      text: "All Undergraduate Degrees",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        external: false,
        size: null,
        icon: null,
      },
      theme: "secondary",
    },
    theme: "accent",
  },
  thumbnails: {
    "1x": Footer,
    "2x": Footer2x,
  },
}
