import { getThemeOptions } from "@helpers/schemas"
import {
  FeaturedTextBluePanels,
  FeaturedTextBluePanels2x,
} from "@thumbnails/Modules"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "module",
  displayName: "Featured text blue panels",
  component: "FeaturedTextBluePanels",
  category: "featured",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          mandatory: true,
          humanReadable: true,
          validators: { maxChar: 50 },
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          validators: { maxChar: 370 },
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "additionalContent",
          hideable: true,
          helptext: "Recommended minimum image size: 2508x522",
        },
        {
          title: "Blue panel 1",
          type: "FieldGroup",
          collapsed: true,
          fields: [
            {
              title: "Title",
              type: "HeadingField",
              key: "titleBluePanel1",
              advanced: true,
              mandatory: true,
              humanReadable: true,
              default: { tag: "h2", content: "Title" },
              options: [
                { value: "h1", label: "H1" },
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "span" },
              ],
            },
            {
              title: "Link",
              type: "ComponentContainer",
              whiteList: ["Link"],
              key: "linkBluePanel1",
              hideable: true,
            },
          ],
        },
        {
          title: "Blue panel 2",
          type: "FieldGroup",
          collapsed: true,
          fields: [
            {
              title: "Title",
              type: "HeadingField",
              key: "titleBluePanel2",
              advanced: true,
              mandatory: true,
              humanReadable: true,
              default: { tag: "h2", content: "Title" },
              options: [
                { value: "h1", label: "H1" },
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "span" },
              ],
            },
            {
              title: "Link",
              type: "ComponentContainer",
              whiteList: ["Link"],
              key: "linkBluePanel2",
              hideable: true,
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "noSpace", value: "noSpace", title: "No space" },
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "FeaturedTextBluePanels",
    verticalSpacing: "medium",
    theme: "defaultAlt",
    title: { content: "Title", tag: "h1" },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    link: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    titleBluePanel1: { content: "Title", tag: "h1" },
    linkBluePanel1: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    titleBluePanel2: { content: "Title", tag: "h1" },
    linkBluePanel2: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    additionalContent: {
      component: "Image",
      veil: 50,
      file: DEFAULT_IMAGE_COMPONENT.file,
    },
  },
  thumbnails: {
    "1x": FeaturedTextBluePanels,
    "2x": FeaturedTextBluePanels2x,
  },
}
