export default {
  es: {
    languageNames: {
      en: "English",
      es: "Español",
      de: "Deutsche",
    },
  },

  de: {
    languageNames: {
      en: "English",
      es: "Español",
      de: "Deutsche",
    },
  },

  en: {
    languageNames: {
      en: "English",
      es: "Español",
      de: "Deutsche",
    },
  },
}
