import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { allSectionList } from "@helpers/schemas"
import { FeaturedProgram, FeaturedProgram2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Featured Program",
  component: "FeaturedProgram",
  category: "studies",
  dataPacks: null,
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "School",
          type: "Select",
          key: "school",
          mandatory: true,
          options: [
            { value: "IENYC", label: "Instituto de empresa NYC" },
            { value: "BUS", label: "Business School" },
            { value: "LAW", label: "Law School" },
            { value: "ARQ", label: "School of Architecture and Design" },
            { value: "GPA", label: "School of Global and public Affairs" },
            {
              value: "SPEGA",
              label: "School of Politics, Economics & Global Affairs",
            },
            { value: "HMN", label: "Humanities School" },
            { value: "HST", label: "School of Science and Technology" },
            { value: "AL", label: "Alumni" },
            { value: "CR", label: "Corporate Relations" },
            { value: "CGC", label: "Center for the Gobernance of Change" },
            { value: "ENT", label: "Entrepreneurship" },
            { value: "XL", label: "Lifelong Learning" },
            { value: "TC", label: "Talent & Career" },
            { value: "FOUND", label: "IE Foundation" },
            { value: "FAID", label: "Financial Aid" },
            {
              value: "LEAD",
              label: "Center Middle East",
            },
            { value: "SST50", label: "SST 50" },
            { value: "Architecture50", label: "Architecture 50" },
            { value: "Business50", label: "Business 50" },
            { value: "Law50", label: "Law 50" },
            { value: "Spega50", label: "SPEGA 50" },
            { value: "University50", label: "University 50" },
            { value: "LifelongLearning50", label: "LifelongLearning 50" },
            { value: "Humanities50", label: "Humanities 50" },
          ],
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h3", content: "Program Name" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Text",
          type: "RichText",
          html: true,
          key: "text",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          mandatory: true,
          helptext: "Recommended minimum image size: 898x425",
        },
        {
          title: "Location",
          type: "TextArea",
          key: "location",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Format",
          type: "TextArea",
          key: "format",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Duration",
          type: "TextArea",
          key: "duration",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Intake",
          type: "TextArea",
          key: "intake",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Language",
          type: "TextArea",
          key: "language",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "FeaturedProgram",
    anchorID: null,
    verticalSpacing: "medium",
    school: "LAW",
    title: {
      content: "Program Name",
      tag: "h3",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    image: {
      component: "Image",
      veil: 50,
      file: DEFAULT_IMAGE_COMPONENT.file,
    },
    location: "Lorem Ipsum",
    format: "Lorem Ipsum",
    duration: "Lorem Ipsum",
    intake: "Lorem Ipsum",
    language: "Lorem Ipsum",
    link: {
      component: "Link",
      text: "Learn More",
      style: "secondary",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
  },
  thumbnails: {
    "1x": FeaturedProgram,
    "2x": FeaturedProgram2x,
  },
}
