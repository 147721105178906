import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { VideoTitle, VideoTitle2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Video With Title",
  component: "VideoTitle",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "URL Video",
          type: "TextField",
          key: "url",
          mandatory: true,
        },
        {
          title: "Thumbnail",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "thumbnail",
          helptext: "Recommended minimum image size: 941x529",
          hideable: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h3", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          type: "UniqueCheck",
          key: "automaticSubtitles",
          options: [
            {
              title: "Add automatic subtitles from Youtube",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "VideoTitle",
    title: { content: "Lorem ipsum", tag: "h3" },
    thumbnail: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    url: "",
    automaticSubtitles: false,
  },
  thumbnails: {
    "1x": VideoTitle,
    "2x": VideoTitle2x,
  },
}
