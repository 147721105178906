import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Principle Card",
  component: "PrinciplesCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Card not selected icon",
          type: "ImageField",
          key: "iconDefault",
          hideable: true,
          helptext:
            "Icon to be displayed when the card is not selected. Should be black with a transparent background.",
        },
        {
          title: "Card selected icon",
          type: "ImageField",
          key: "iconInverse",
          hideable: true,
          helptext:
            "Icon to be displayed when the card is selected. Should be white with a transparent background.",
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: false,
          mandatory: true,
          helptext:
            "This text will appear completely when the card is selected.",
          humanReadable: true,
        },
        {
          title: "Show an extract of the description in the card?",
          type: "RadioGroup",
          key: "showExtract",
          mandatory: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Anchor text",
          type: "TextField",
          key: "anchor",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Principle card background",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "cardBackground",
          hideable: true,
          helptext: "Recommended minimum image size: 502x420",
        },
        {
          title: "Card selected background",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "cardSelectBackground",
          hideable: true,
          helptext: "Recommended minimum image size: 502x420",
        },
        {
          title: "Do you want to add a link in the description?",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "PrinciplesCard",
    iconDefault: {
      ...DEFAULT_IMAGE_FIELD,
    },
    iconInverse: {
      ...DEFAULT_IMAGE_FIELD,
    },
    title: { content: "Title", tag: "h3" },
    description:
      "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor ut, iaculis quis, sem. Phasellus rhoncus. Aenean id metus id velit ullamcorper pulvinar. Vestibulum fermentum tortor id mi. Pellentesque ipsum. Nulla non arcu lacinia neque faucibus fringilla. Nulla non lectus sed ni",
    cardBackground: {
      component: "Image",
      veil: 50,
    },
    cardSelectBackground: {
      component: "Image",
      veil: 50,
    },
    showExtract: true,
    anchor: "Read more",
    link: { component: "Link" },
  },
}
