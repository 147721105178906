export const getNumberWithOrdinal = n => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const normalizeSpecialChars = text => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const getFileType = name => {
  return /\.([0-9a-z]+$)/.exec(name)?.[1]
}