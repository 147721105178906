import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Internal Event Card",
  component: "InternalEventCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Tag",
          key: "tag",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Date",
          type: "DateField",
          key: "dateTime",
          selectsRange: true,
        },
        {
          title: "Event Hour",
          key: "eventHour",
          type: "TextField",
        },
        {
          title: "Address",
          key: "address",
          type: "TextField",
        },
        {
          title: "Country",
          key: "country",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Distributor description",
          key: "distributorDescription",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Add to calendar button",
          type: "ComponentContainer",
          whiteList: ["AddToCalendar"],
          key: "addToCalendar",
        },
      ],
    },
  ],
  default: {
    component: "InternalEventCard",
    image: null,
    tag: null,
    dateTime: null,
    eventHour: null,
    language: null,
    address: null,
    country: null,
    distributorDescription: null,
    addToCalendar: {},
    eventAreas: null,
    eventLocation: null,
    eventFormat: null,
    programType: null,
    eventRegion: null,
    fullPath: null,
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
