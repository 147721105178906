import React from "react"

const AcademicVideo = React.lazy(() =>
  import(
    "./AcademicVideo/AcademicVideo" /* webpackChunkName: "template---src-AcademicVideo" */
  )
)
const ArticlesList = React.lazy(() =>
  import(
    "./ArticlesList/ArticlesList" /* webpackChunkName: "template---src-ArticlesList" */
  )
)
const AudiovisualList = React.lazy(() =>
  import(
    "./AudiovisualList/AudiovisualList" /* webpackChunkName: "template---src-AudiovisualList" */
  )
)
const AwardProposalForm = React.lazy(() =>
  import(
    "./AwardProposalForm/AwardProposalForm" /* webpackChunkName: "template---src-AwardProposalForm" */
  )
)
const BasicTemplate = React.lazy(() =>
  import(
    "./BasicTemplate/BasicTemplate" /* webpackChunkName: "template---src-BasicTemplate" */
  )
)
const EntrepreneurForm = React.lazy(() =>
  import(
    "./EntrepreneurForm/EntrepreneurForm" /* webpackChunkName: "template---src-EntrepreneurForm" */
  )
)
const EpicAwardDetail = React.lazy(() =>
  import(
    "./EpicAwardDetail/EpicAwardDetail" /* webpackChunkName: "template---src-EpicAwardDetail" */
  )
)
const EpicAwardList = React.lazy(() =>
  import(
    "./EpicAwardList/EpicAwardList" /* webpackChunkName: "template---src-EpicAwardList" */
  )
)
const Error404 = React.lazy(() =>
  import(
    "./Error404/Error404" /* webpackChunkName: "template---src-Error404" */
  )
)
const EventDetail = React.lazy(() =>
  import(
    "./EventDetail/EventDetail" /* webpackChunkName: "template---src-EventDetail" */
  )
)
const EventList = React.lazy(() =>
  import(
    "./EventList/EventList" /* webpackChunkName: "template---src-EventList" */
  )
)
const GeneralInfoDetail = React.lazy(() =>
  import(
    "./GeneralInfoDetail/GeneralInfoDetail" /* webpackChunkName: "template---src-GeneralInfoDetail" */
  )
)
const GeneralInfoList = React.lazy(() =>
  import(
    "./GeneralInfoList/GeneralInfoList" /* webpackChunkName: "template---src-GeneralInfoList" */
  )
)
const Landing = React.lazy(() =>
  import("./Landing/Landing" /* webpackChunkName: "template---src-Landing" */)
)
const LastNews = React.lazy(() =>
  import(
    "./NewsTemplates/LastNews" /* webpackChunkName: "template---src-LastNews" */
  )
)
const NewsDetail = React.lazy(() =>
  import(
    "./NewsTemplates/NewsDetail" /* webpackChunkName: "template---src-NewsDetail" */
  )
)
const NewsHome = React.lazy(() =>
  import(
    "./NewsTemplates/NewsHome" /* webpackChunkName: "template---src-NewsHome" */
  )
)
const NewsList = React.lazy(() =>
  import(
    "./NewsTemplates/NewsList" /* webpackChunkName: "template---src-NewsList" */
  )
)
const PersonList = React.lazy(() =>
  import(
    "./PersonList/PersonList" /* webpackChunkName: "template---src-PersonList" */
  )
)
const ProgramComparator = React.lazy(() =>
  import(
    "./ProgramComparator/ProgramComparator" /* webpackChunkName: "template---src-ProgramComparator" */
  )
)
const ProgramDetail = React.lazy(() =>
  import(
    "./ProgramDetail/ProgramDetail" /* webpackChunkName: "template---src-ProgramDetail" */
  )
)
const ProgramsList = React.lazy(() =>
  import(
    "./ProgramsList/ProgramsList" /* webpackChunkName: "template---src-ProgramsList" */
  )
)
const ProjectDetail = React.lazy(() =>
  import(
    "./ProjectDetail/ProjectDetail" /* webpackChunkName: "template---src-ProjectDetail" */
  )
)
const ProjectList = React.lazy(() =>
  import(
    "./ProjectList/ProjectList" /* webpackChunkName: "template---src-ProjectList" */
  )
)
const PublicationDetail = React.lazy(() =>
  import(
    "./PublicationDetail/PublicationDetail" /* webpackChunkName: "template---src-PublicationDetail" */
  )
)
const PublicationsList = React.lazy(() =>
  import(
    "./PublicationsList/PublicationsList" /* webpackChunkName: "template---src-PublicationsList" */
  )
)
const RecruitForm = React.lazy(() =>
  import(
    "./RecruitForm/RecruitForm" /* webpackChunkName: "template---src-RecruitForm" */
  )
)
const ScholarshipsList = React.lazy(() =>
  import(
    "./ScholarshipsList/ScholarshipsList" /* webpackChunkName: "template---src-ScholarshipsList" */
  )
)
const SearchResults = React.lazy(() =>
  import(
    "./SearchResults/SearchResults" /* webpackChunkName: "template---src-SearchResults" */
  )
)
const SitemapTemplate = React.lazy(() =>
  import(
    "./SitemapTemplate/SitemapTemplate" /* webpackChunkName: "template---src-SitemapTemplate" */
  )
)
const StoriesList = React.lazy(() =>
  import(
    "./StoriesList/StoriesList" /* webpackChunkName: "template---src-StoriesList" */
  )
)
const StoryDetail = React.lazy(() =>
  import(
    "./StoryDetail/StoryDetail" /* webpackChunkName: "template---src-StoryDetail" */
  )
)
const TeacherDetail = React.lazy(() =>
  import(
    "./TeacherDetail/TeacherDetail" /* webpackChunkName: "template---src-TeacherDetail" */
  )
)
const TeachersList = React.lazy(() =>
  import(
    "./TeachersList/TeachersList" /* webpackChunkName: "template---src-TeachersList" */
  )
)

export {
  AcademicVideo,
  ArticlesList,
  AudiovisualList,
  AwardProposalForm,
  BasicTemplate,
  EntrepreneurForm,
  EpicAwardDetail,
  EpicAwardList,
  Error404,
  EventDetail,
  EventList,
  GeneralInfoDetail,
  GeneralInfoList,
  Landing,
  LastNews,
  NewsDetail,
  NewsHome,
  NewsList,
  PersonList,
  ProgramComparator,
  ProgramDetail,
  ProgramsList,
  ProjectDetail,
  ProjectList,
  PublicationDetail,
  PublicationsList,
  RecruitForm,
  ScholarshipsList,
  SearchResults,
  SitemapTemplate,
  StoriesList,
  StoryDetail,
  TeacherDetail,
  TeachersList,
}
