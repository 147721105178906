const defaultRow = title => ({
  component: "TableRow",
  title,
  cellType: "text",
  title2: title,
  link: {
    component: "Link",
  },
})

const defaultRowArray = numRows => {
  const rows = []
  for (let i = 0; i < numRows; i++) {
    rows.push(defaultRow(`Row ${i + 1}`))
  }
  return rows
}

export default {
  schemaType: "component",
  displayName: "TableColumn",
  component: "TableColumn",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Column Header",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "Column Rows",
          type: "ComponentArray",
          whiteList: ["TableRow"],
          key: "rows",
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: "TableColumn",
    content: "content",
    title: "New Column",
    rows: defaultRowArray(4),
  },
}
