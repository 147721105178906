import {
  InformationStructure,
  InformationStructure2x,
} from "@thumbnails/Modules"
import BasicContent from "@schemas/modules/BasicContent"

export default {
  schemaType: "module",
  displayName: "Information structure",
  component: "InformationStructure",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          key: "heightComponentModule",
          title: "Module height size",
          type: "NumberField",
          default: 490,
          mandatory: true,
          helptext:
            "Enter a value between 300 and 700 in px if you want the module to have a different size in height.",
          validators: { minValue: 300, maxValue: 700 },
        },
        {
          title: "Tabs title",
          type: "TextField",
          key: "tabsTitle",
          hideable: true,
          humanReadable: true,
          helptext: "Shown on desktop",
        },
        {
          title: "Tabs duration",
          type: "TextField",
          key: "tabsDuration",
          hideable: true,
          humanReadable: true,
          helptext: "Shown on desktop",
        },
        {
          title: "Tabs Section",
          type: "ComponentArray",
          maxItems: 7,
          whiteList: ["StructureTabElement"],
          key: "tabModules",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "InformationStructure",
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { content: "Lorem Ipsum", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    detail: null,
    heightComponentModule: 490,
    tabsTitle: "Program Structure",
    tabsDuration: "20 Months",
    tabModules: [
      {
        component: "StructureTabElement",
        title: "Description",
        componentModules: [BasicContent.default],
      },
      {
        component: "StructureTabElement",
        title: "11 months",
        componentModules: [
          {
            component: "StructureTabElement",
            title: "1st term",
            subtitle: "3 months",
            componentModules: [BasicContent.default],
          },
        ],
      },
    ],
  },
  thumbnails: {
    "1x": InformationStructure,
    "2x": InformationStructure2x,
  },
}
