import { getThemeOptions } from "@helpers/schemas"
import {
  DEFAULT_IMAGE_FIELD,
  DEFAULT_IMAGE_COMPONENT,
} from "@constants/griddoImage"
import { Accordion50, Accordion502x } from "@thumbnails/Modules"
import Intro from "@schemas/modules/Intro"

export default {
  schemaType: "module",
  displayName: "Accordion 50%",
  component: "Accordion50",
  dataPacks: null,
  category: "interactive",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem Ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["AccordionElement", "FaqElement"],
          key: "accElements",
          mandatory: true,
        },
        {
          title: "Side content",
          type: "ComponentArray",
          maxItems: 1,
          whiteList: [
            "Intro",
            "BasicContent",
            "Accordion",
            "Image",
            "Video",
            "BasicCard",
          ],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Show images",
          type: "RadioGroup",
          key: "showImages",
          mandatory: true,
          options: [
            {
              value: "yes",
              title: "Yes",
              name: "yes",
            },
            {
              value: "no",
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Text size",
          type: "RadioGroup",
          key: "titleSize",
          mandatory: true,
          options: [
            {
              value: "s",
              title: "Small",
              name: "small",
            },
            {
              value: "m",
              title: "Large",
              name: "m",
            },
          ],
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "Accordion50",
    title: { content: "Lorem Ipsum", tag: "h4" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    detail: null,
    titleSize: "m",
    theme: "defaultAlt",
    showImages: "no",
    accElements: Array(4)
      .fill(0)
      .map((_, idx) => ({
        component: "AccordionElement",
        title: { content: `Title ${idx + 1}`, tag: "h3" },
        icon: { ...DEFAULT_IMAGE_FIELD },
        componentModules: [
          {
            component: "BasicContent",
            title: {
              content: "Default title",
              tag: "h1",
            },
            subtitle:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
            primaryLink: {
              component: "LinkModal",
              style: "primary",
              link: {
                text: "Link",
                linkType: "url",
                url: {
                  href: null,
                  linkToURL: null,
                  newTab: false,
                  noFollow: false,
                },
                modal: {
                  requestInfo: {
                    component: "RequestInfo",
                  },
                  floatingCTAMail: {
                    component: "FloatingCTAMail",
                  },
                  floatingCTALightbox: {
                    component: "FloatingCTALightbox",
                  },
                  floatingCTAVideo: {
                    component: "FloatingCTAVideo",
                  },
                },
              },
            },
            secondaryLink: {
              component: "LinkModal",
            },
            additionalContent: {
              image: {
                component: "LinkableImage",
                ...DEFAULT_IMAGE_COMPONENT,
                veil: 0,
              },
              video: {
                component: "Video",
              },
            },
            theme: "defaultAlt",
            layout: "L001",
            moduleOrder: null,
          },
        ],
      })),
    elements: [{ ...Intro.default }],
  },
  thumbnails: {
    "1x": Accordion50,
    "2x": Accordion502x,
  },
}
