import { allModulesWhitelist, getLinkStyles } from "@helpers/schemas"
import { PublicationsList, PublicationsList2x } from "@thumbnails/Templates"

export default {
  displayName: "Publications List",
  component: "PublicationsList",
  dataPacks: ["PUBLICATIONS"],
  type: { label: "Publications", value: "publications" },
  schemaType: "template",
  config: [
    {
      title: "Style",
      key: "linkStyle",
      type: "VisualUniqueSelection",
      options: getLinkStyles(),
      columns: 3,
    },
  ],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
    },
    {
      key: "detail",
      title: "Detail",
      type: "RichText",
      hideable: true,
      html: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showPublicationTypeFilter",
              title: "Show Publication type filter",
              name: "publicationTypeFilter",
            },
            {
              value: "showAcademicAreaFilter",
              title: "Show Academic Area filter",
              name: "academicAreaFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
        {
          key: "publicationTypeLabel",
          title: "Publication type label",
          type: "TextField",
        },
        {
          key: "academicAreaLabel",
          title: "Academic Area label",
          type: "TextField",
        },
      ],
    },
    {
      title: "Main Content Container",
      type: "FieldGroup",
      key: "mainContent",
      fields: [
        {
          type: "ReferenceField",
          sources: [
            { structuredData: "PUBLICATIONS" },
            { structuredData: "PUBLICATIONDETAIL" },
          ],
          key: "data",
        },
        {
          key: "noResultsText",
          title: "No Results Text",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Items/page",
          type: "NumberField",
          key: "itemsPerPage",
          default: 8,
          minValue: 4,
          maxValue: 40,
          mandatory: true,
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "Select option link",
          type: "RadioGroup",
          key: "optionLink",
          mandatory: true,
          required: true,
          options: [
            {
              name: "Link to detail",
              value: "linkToDetail",
              title: "Link to detail",
            },
            {
              name: "External link publication",
              value: "linkToExternal",
              title: "External link publication",
            },
          ],
        },
        {
          title: "Cards titles link",
          type: "RadioGroup",
          key: "titlesLink",
          mandatory: false,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Link text",
          type: "TextField",
          key: "linkText",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Show text of publications link",
          type: "RadioGroup",
          key: "showLinkTextPublications",
          mandatory: false,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
      ],
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      key: "relatedContent",
      maxItems: null,
      whiteList: allModulesWhitelist,
      hideable: true,
    },
  ],
  default: {
    type: "template",
    templateType: "PublicationsList",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    detail: "Lorem ipsum",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "PUBLICATIONS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "PUBLICATIONDETAIL",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 8,
      preferenceLanguage: false,
    },
    hasDistributorData: false,
    templateOptions: [],
    filtersType: "simple",
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 8,
    showLinkTextPublications: true,
    cardTitleTag: "h2",
    optionLink: "linkToDetail",
    titlesLink: false,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 1,
    },
    linkStyle: "secondary",
  },
  thumbnails: {
    "1x": PublicationsList,
    "2x": PublicationsList2x,
  },
}
