import { EntrepreneurForm, EntrepreneurForm2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "New entrepreneur - Form",
  component: "EntrepreneurForm",
  dataPacks: null,
  type: { label: "Static", value: "static" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
      ],
      key: "heroSection",
      mandatory: true,
    },
    {
      title: "Subtitle",
      type: "RichText",
      html: true,
      key: "subtitle",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Detail",
      type: "RichText",
      html: true,
      key: "detail",
      hideable: true,
      humanReadable: true,
    },
    {
      key: "title01",
      title: "Title 01",
      type: "TextField",
      mandatory: true,
      required: true,
      humanReadable: true,
    },
    {
      key: "title02",
      title: "Title 02",
      type: "TextField",
      mandatory: true,
      required: true,
      humanReadable: true,
    },
    {
      key: "sectors",
      title: "Sectors",
      type: "TextField",
      hideable: true,
      helptext: "Separate sectors with ';' (semicolon)",
      humanReadable: true,
    },
    {
      key: "emailTo",
      title: "Email to",
      type: "TextField",
      mandatory: true,
      required: true,
    },
    {
      key: "legalConditions",
      title: "Legal conditions page",
      type: "UrlField",
      advanced: true,
      mandatory: true,
      required: true,
    },
  ],
  default: {
    type: "template",
    templateType: "EntrepreneurForm",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    subtitle: null,
    detail: null,
    title01: "01. Founders information",
    title02: "02. Company information",
    emailTo: "alumni@ie.edu",
    legalConditions: "",
    sectors: "sector1;sector2;sector3",
  },
  thumbnails: {
    "1x": EntrepreneurForm,
    "2x": EntrepreneurForm2x,
  },
}
