import { HeroText, HeroText2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Text",
  component: "HeroText",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Info",
          key: "note01",
          type: "NoteField",
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "HeroText",
    note01: {
      text:
        "This module is not editable as it inherits the title of the page it is applied to.",
    },
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": HeroText,
    "2x": HeroText2x,
  },
}
