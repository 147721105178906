import { allModulesWhitelist } from "@helpers/schemas"
import { PersonList, PersonList2x } from "@thumbnails/Templates"
import { PersonListLayout1, PersonListLayout2 } from "@layouts"

export default {
  displayName: "Person List",
  component: "PersonList",
  dataPacks: ["PEOPLE"],
  type: { label: "People", value: "people" },
  schemaType: "template",
  config: [
    {
      title: "Layout",
      key: "layout",
      type: "VisualUniqueSelection",
      mandatory: true,
      options: [
        {
          value: "L002",
          img: PersonListLayout1,
        },
        {
          value: "L004",
          img: PersonListLayout2,
        },
      ],
      columns: 2,
    },
  ],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
    },
    {
      key: "subtitle",
      title: "Subtitle",
      type: "TextField",
      hideable: true,
      humanReadable: true,
    },
    {
      key: "detail",
      title: "Detail text",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showGenderFilter",
              title: "Show Gender filter",
              name: "genderFilter",
            },
            {
              value: "showRegionFilter",
              title: "Show Region filter",
              name: "regionFilter",
            },
            {
              value: "showAcademicAreaFilter",
              title: "Show Academic area filter",
              name: "academicAreaFilter",
            },
            {
              value: "showFacultyCategoryFilter",
              title: "Show Faculty category filter",
              name: "facultyCategoryFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "genderLabel",
          title: "Gender label",
          type: "TextField",
        },
        {
          key: "regionLabel",
          title: "Region label",
          type: "TextField",
        },
        {
          key: "academicAreaLabel",
          title: "Academic Area label",
          type: "TextField",
        },
        {
          key: "facultyCategoryLabel",
          title: "Faculty Category label",
          type: "TextField",
        },
      ],
    },
    {
      title: "",
      type: "ReferenceField",
      sources: [{ structuredData: "TEACHERS" }, { structuredData: "PEOPLE" }],
      key: "personData",
    },
    {
      title: "Show data on all cards",
      type: "RadioGroup",
      key: "showDataAllCards",
      mandatory: false,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Card Background",
      type: "ConditionalField",
      key: "cardBackground",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "Yes",
        },
        {
          value: false,
          title: "No",
          name: "No",
        },
      ],
      fields: [
        {
          title: "Use person image for background",
          type: "RadioGroup",
          key: "usePersonImageAsBg",
          mandatory: true,
          condition: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
        },
      ],
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      mandatory: true,
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      mandatory: true,
    },
    {
      title: "Show Featured Person",
      type: "ConditionalField",
      key: "featuredPerson",
      options: [
        {
          value: true,
          title: "Yes",
          name: "Yes",
        },
        {
          value: false,
          title: "No",
          name: "No",
        },
      ],
      fields: [
        {
          title: "Title for featured Person",
          type: "TextField",
          key: "titleForFeaturedPerson",
          hideable: true,
          condition: true,
          humanReadable: true,
        },
        {
          title: "Select featured Person",
          type: "ReferenceField",
          sources: [
            { structuredData: "TEACHERS" },
            { structuredData: "PEOPLE" },
          ],
          key: "data",
          condition: true,
        },
      ],
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "PersonList",
    layout: "L002",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    detail: "Lorem ipsum",
    templateOptions: [],
    filtersType: "simple",
    personData: {
      mode: "auto",
      order: "recent",
      sources: [
        {
          structuredData: "TEACHERS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "PEOPLE",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 15,
      preferenceLanguage: false,
    },
    showDataAllCards: false,
    cardBackground: false,
    usePersonImageAsBg: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 15,
    featuredPerson: false,
    data: {
      mode: "manual",
      order: "recent",
      sources: [{ structuredData: "TEACHERS" }, { structuredData: "PEOPLE" }],
      quantity: 1,
      preferenceLanguage: false,
    },
    hasDistributorData: true,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [
        {
          component: "CardCollection",
          theme: "defaultAlt",
          verticalSpacing: "medium",
          layout: "L002",
          kind: "BasicCard",
          title: { content: "Title", tag: "h2" },
          subtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          detail: null,
          elements: [
            {
              component: "BasicCard",
              title: {
                content: "Title",
                tag: "h4",
              },
              subtitle: "Lorem Ipsum",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
              auxInfo: "Auxiliar Information",
              link: {
                component: "LinkModal",
                style: "link",
                link: {
                  text: "Link",
                  linkType: "url",
                  url: {
                    href: null,
                    linkToURL: null,
                    linkTo: null,
                    newTab: false,
                    noFollow: false,
                  },
                  modal: {
                    requestInfo: {
                      component: "RequestInfo",
                    },
                    floatingCTAMail: {
                      component: "FloatingCTAMail",
                    },
                    floatingCTALightbox: {
                      component: "FloatingCTALightbox",
                    },
                    floatingCTAVideo: {
                      component: "FloatingCTAVideo",
                    },
                  },
                },
              },
              link2: {
                component: "LinkModal",
              },
              media: {
                image: {
                  component: "LinkableImage",
                },
                video: {
                  component: "Video",
                },
              },
            },
            {
              component: "BasicCard",
              title: {
                content: "Title",
                tag: "h4",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
              auxInfo: "Auxiliar Information",
              link: {
                component: "LinkModal",
                style: "link",
                link: {
                  text: "Link",
                  linkType: "url",
                  url: {
                    href: null,
                    linkToURL: null,
                    linkTo: null,
                    newTab: false,
                    noFollow: false,
                  },
                  modal: {
                    requestInfo: {
                      component: "RequestInfo",
                    },
                    floatingCTAMail: {
                      component: "FloatingCTAMail",
                    },
                    floatingCTALightbox: {
                      component: "FloatingCTALightbox",
                    },
                    floatingCTAVideo: {
                      component: "FloatingCTAVideo",
                    },
                  },
                },
              },
              link2: {
                component: "LinkModal",
              },
              media: {
                image: {
                  component: "LinkableImage",
                },
                video: {
                  component: "Video",
                },
              },
            },
            {
              component: "BasicCard",
              title: {
                content: "Title",
                tag: "h4",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
              auxInfo: "Auxiliar Information",
              link: {
                component: "LinkModal",
                style: "link",
                link: {
                  text: "Link",
                  linkType: "url",
                  url: {
                    href: null,
                    linkToURL: null,
                    linkTo: null,
                    newTab: false,
                    noFollow: false,
                  },
                  modal: {
                    requestInfo: {
                      component: "RequestInfo",
                    },
                    floatingCTAMail: {
                      component: "FloatingCTAMail",
                    },
                    floatingCTALightbox: {
                      component: "FloatingCTALightbox",
                    },
                    floatingCTAVideo: {
                      component: "FloatingCTAVideo",
                    },
                  },
                },
              },
              link2: {
                component: "LinkModal",
              },
              media: {
                image: {
                  component: "LinkableImage",
                },
                video: {
                  component: "Video",
                },
              },
            },
          ],
          groupingLink: {
            component: "Link",
          },
          titlesLink: false,
        },
        {
          component: "FeaturedAudiovisual",
          layout: "L001",
          elements: [
            {
              layout: "L001",
              component: "FeaturedText",
              theme: "accent",
              title: { content: "Title", tag: "h4" },
              size: "big",
              content: "Lorem Ipsum",
              link: {
                component: "Link",
                text: "Link",
                style: "primary",
                url: {
                  href: null,
                  url: "",
                  linkTo: null,
                  newTab: false,
                  noFollow: false,
                },
              },
              backgroundImage: {
                component: "Image",
              },
            },
          ],
        },
      ],
      sectionPosition: 3,
    },
  },
  thumbnails: {
    "1x": PersonList,
    "2x": PersonList2x,
  },
}
