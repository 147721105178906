import { Box, Text } from "@sqymagma/elements"
import React from "react"
import styled, { css } from "styled-components"
import Icons from "@icons"
import { themeGet as theme } from "@styled-system/theme-get"
import { GriddoLink } from "@griddo/core"
import { useI18n } from "@hooks"

const NoCropText = css`
  &::before,
  &::after {
    content: unset;
  }
`

const IconPlace = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
`

const Span = styled(Text)`
  /* bugfix/8834 */
  /* display: inline-flex; */
  transition: all ${theme("timming.hover")} ease;
  vertical-align: middle;
  &:hover {
    color: ${theme("colors.link01Hover")};
  }
  ${p =>
    p.fit &&
    css`
      line-height: 0;
    `};
  /* TODO: Solución para el croptext en elementos anidados */
  ${NoCropText}
`

const Anchor = ({
  display = "inline",
  fill = "link01",
  textStyle = "bodySmall",
  color,
  nowrap,
  position,
  children,
  external,
  internal,
  href,
  linkToURL,
  newTab,
  noFollow,
  title,
  iconHeight,
  appareance,
  ...props
}) => {
  const REL = `${newTab ? "noopener" : ""} ${noFollow ? "nofollow" : ""}`.trim()
  // Language
  const { getNestedTranslation: t } = useI18n()
  const openNewTab = t(
    "icons.ariaLabel.openNewTab",
    "It will open in a new tab"
  )
  return (
    <GriddoLink url={{ linkToURL, newTab, href }} rel={REL}>
      <Box
        {...props}
        style={{ whiteSpace: nowrap }}
        display={display}
        alignItems="center"
        verticalAlign="middle"
      >
        {newTab && title && (
          <span
            css={`
              clip-path: inset(100%);
              clip: rect(1px, 1px, 1px, 1px);
              height: 1px;
              overflow: hidden;
              position: absolute;
              white-space: nowrap;
              width: 1px;
            `}
          >
            {title + ". " + openNewTab}
          </span>
        )}
        <Span color={color ? color : "link01"} textStyle={textStyle}>
          {children}
        </Span>
        {internal || external ? (
          <IconPlace ml="xxs" style={{ top: position }}>
            <Icons
              name={external ? "externalLink" : "higher"}
              height={iconHeight ? iconHeight : "16px"}
              fill={fill}
              aria-hidden={true}
            />
          </IconPlace>
        ) : null}
      </Box>
    </GriddoLink>
  )
}

export default Anchor
