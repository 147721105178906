import { NewsDetail, NewsDetail2x } from "@thumbnails/Templates"
import { allModulesWhitelist } from "@helpers/schemas"
import { IMAGE } from "@constants/placeholders"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
//Module default
import SocialShare from "@schemas/modules/SocialShare"

export default {
  schemaType: "template",
  displayName: "News Detail",
  component: "NewsDetail",
  dataPacks: ["NEWS"],
  type: { label: "News", value: "news", mode: "detail" },
  content: [
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "stageTargets",
          placeholder: "Stage Target",
          source: "STAGE_TARGET",
        },
        {
          key: "centers",
          placeholder: "Centers & Departments",
          source: "CENTERS",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
      ],
    },
    {
      title: "Date",
      type: "DateField",
      key: "newsDate",
      mandatory: true,
    },
    {
      title: "Lead",
      type: "TextField",
      key: "lead",
      humanReadable: true,
    },
    {
      title: "Abstract",
      type: "TextField",
      key: "abstract",
      humanReadable: true,
    },
    {
      title: "LongAbstract",
      type: "RichText",
      html: true,
      key: "longAbstract",
      humanReadable: true,
    },
    {
      title: "Content",
      type: "Wysiwyg",
      key: "content",
      humanReadable: true,
    },

    {
      title: "Image or video?",
      type: "ConditionalField",
      key: "imageOrVideo",
      mandatory: true,
      required: true,
      options: [
        {
          value: "image",
          title: "Image",
          name: "image",
        },
        {
          value: "video",
          title: "Video",
          name: "video",
        },
      ],
      fields: [
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          condition: "image",
        },
        {
          title: "URL Video",
          type: "TextField",
          key: "video",
          mandatory: true,
          condition: "video",
        },
        {
          title: "Thumbnail",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "thumbnail",
          hideable: true,
          condition: "video",
        },
        {
          type: "UniqueCheck",
          key: "automaticSubtitles",
          condition: "video",
          options: [
            {
              title: "Add automatic subtitles from Youtube",
            },
          ],
        },
      ],
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],

  default: {
    type: "template",
    templateType: "NewsDetail",
    imageOrVideo: "image",
    categories: [],
    schools: [],
    breadCrumb: [],
    newsDate: null,
    image: {
      url: IMAGE.hero4,
    },
    thumbnail: {
      component: "Image",
      file: DEFAULT_IMAGE_COMPONENT.file,
    },
    lead: "Lead",
    abstract: "Abstract",
    longAbstract: "Long abstract",
    content: "",
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [{ ...SocialShare.default }],
      sectionPosition: 4,
    },
  },
  thumbnails: {
    "1x": NewsDetail,
    "2x": NewsDetail2x,
  },
}
