import { allModulesWhitelist } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { EpicAwardDetail, EpicAwardDetail2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "Epic Award Detail",
  component: "EpicAwardDetail",
  dataPacks: ["EPICAWARDS"],
  type: { label: "EpicAwards", value: "epicawards", mode: "detail" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: ["HeroText", "HeroQuote"],
      mandatory: true,
    },
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      mandatory: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "awardCategory",
          placeholder: "Awards Category",
          source: "AWARD_CATEGORY",
        },
        {
          key: "classificationType",
          placeholder: "Classification Type",
          source: "CLASSIFICATION_TYPE",
        },
        {
          key: "year",
          placeholder: "Year",
          source: "YEAR",
        },
      ],
    },
    {
      title: "Link",
      type: "ComponentContainer",
      whiteList: ["Link"],
      key: "link",
      hideable: true,
    },
    {
      key: "image",
      title: "Image",
      type: "ImageField",
      mandatory: true,
      helptext: "Shown on the distributor card",
    },
    {
      key: "quote",
      title: "Quote",
      type: "RichText",
      html: true,
      mandatory: true,
      helptext: "Shown on the distributor card",
      humanReadable: true,
    },
    {
      key: "position",
      title: "Position",
      type: "TextField",
      hideable: true,
      helptext: "Shown on the distributor card",
      humanReadable: true,
    },
    {
      key: "description",
      title: "Description",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      key: "who",
      title: "Who",
      type: "TextField",
      mandatory: true,
      helptext: "Shown on the distributor card",
    },
    {
      title: "Social Share",
      type: "ComponentArray",
      maxItems: 1,
      key: "socialShare",
      whiteList: ["SocialShare"],
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      key: "mainContent",
      whiteList: allModulesWhitelist,
    },
    {
      title: "Show pagination",
      type: "RadioGroup",
      key: "showPagination",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "Yes",
        },
        {
          value: false,
          title: "No",
          name: "No",
        },
      ],
    },
    {
      title: "Image",
      type: "ImageField",
      key: "cardBackground",
      helptext: "Shown on pagination",
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      hideable: true,
      whiteList: allModulesWhitelist,
    },
    {
      title: "Classification",
      key: "classification",
      type: "ComponentContainer",
      computed: page => {
        const classificationObject =
          page?.template?.categories?.classificationType?.[0]?.title || {}
        return classificationObject
      },
      hidden: true,
    },
  ],

  default: {
    type: "template",
    templateType: "EpicAwardDetail",
    theme: "default",
    data: {
      quantity: 1,
      mode: "navigation",
      order: "recent-asc",
    },
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    link: {
      component: "Link",
    },
    image: DEFAULT_IMAGE_FIELD,
    quote: "Lorem ipsum",
    position: "Lorem ipsum",
    description:
      "Velit vitae id torquent placerat semper per sollicitudin ac, facilisi potenti curabitur eget lorem nascetur adipiscing.",
    who: "Lorem ipsum",
    socialShare: {
      component: "Section",
      name: "Show Social Share",
      modules: [],
      sectionPosition: 3,
    },
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [],
      sectionPosition: 4,
    },
    showPagination: true,
    cardBackground: false,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 4,
    },
  },
  thumbnails: {
    "1x": EpicAwardDetail,
    "2x": EpicAwardDetail2x,
  },
}
