import { getThemeOptions } from "@helpers/schemas"
import { CypherCollection, CypherCollection2x } from "@thumbnails/Modules"
import {
  CypherCollectionLayout1,
  CypherCollectionLayout2,
  CypherCollectionLayout3,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Cypher Collection",
  component: "CypherCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Cyphers",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["CypherCard"],
          key: "elements",
          mandatory: true,
        },
        {
          title: "Grouping link",
          type: "ComponentContainer",
          key: "groupingLink",
          whiteList: ["Link"],
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CypherCollectionLayout1,
            },
            {
              value: "L002",
              img: CypherCollectionLayout2,
            },
            {
              value: "L003",
              img: CypherCollectionLayout3,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "CypherCollection",
    anchorID: null,
    theme: "accent",
    verticalSpacing: "medium",
    layout: "L002",
    title: { content: "Lorem Ipsum", tag: "h2" },
    subtitle: "Lorem Ipsum",
    detail: null,
    elements: [
      {
        component: "CypherCard",
        featuredText: "1",
        superIndex: "ST",
        title: "Ranking name",
        subtitle: "Lorem Ipsum",
        auxText: "Source",
        theme: "accent",
        textSize: "display01",
        showDecorations: false,
      },
      {
        component: "CypherCard",
        featuredText: "2",
        superIndex: "ND",
        title: "Ranking name",
        subtitle: "Lorem Ipsum",
        auxText: "Source",
        theme: "accent",
        textSize: "display01",
        showDecorations: false,
      },
      {
        component: "CypherCard",
        featuredText: "3",
        superIndex: "RD",
        title: "Ranking name",
        subtitle: "Lorem Ipsum",
        auxText: "Source",
        theme: "accent",
        textSize: "display01",
        showDecorations: false,
      },
    ],
    groupingLink: {
      component: "Link",
      text: "Link",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": CypherCollection,
    "2x": CypherCollection2x,
  },
}
