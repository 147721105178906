import { ProgramQuickFacts, ProgramQuickFacts2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Program Quick Facts",
  component: "ProgramQuickFacts",
  dataPacks: null,
  category: "studies",
  configTabs: [
    {
      title: "content",

      fields: [
        {
          title: "Quick Facts Element",
          type: "ComponentArray",
          maxItems: 6,
          whiteList: ["QuickFactElement"],
          key: "elements",
        },
      ],
    },

    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "ProgramQuickFacts",
    theme: "default",
    title: { content: "Program quick facts title" },
    elements: [
      {
        title: "duration",
        detail: "duration detail text",
        component: "QuickFactElement",
      },
      {
        title: "language",
        detail: "language detail text",
        component: "QuickFactElement",
      },
      {
        title: "location",
        detail: "location detail text",
        component: "QuickFactElement",
      },
      {
        title: "intake",
        detail: "intake detail text",
        component: "QuickFactElement",
      },
    ],
  },
  thumbnails: {
    "1x": ProgramQuickFacts,
    "2x": ProgramQuickFacts2x,
  },
}
