import { getThemeOptions } from "@helpers/schemas"
import { StudentPath, StudentPath2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "StudentPath",
  component: "StudentPath",
  dataPacks: null,
  category: "content",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          key: "elements",
          whiteList: ["TextStep", "ImageStep", "FinalStep"],
          maxItems: null,
          mandatory: true,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Image Step Color",
          type: "ColorPicker",
          key: "imageStepColor",
          colors: ["#99D5DD", "#1A4798", "#EDEDED"],
          helptext: "Color for external shade",
        },
        {
          title: "Final Step Color",
          type: "ColorPicker",
          key: "finalStepColor",
          colors: ["#99D5DD", "#1A4798", "#EDEDED", "#FBCA1E"],
          helptext: "Color for external shade",
        },
        {
          title: "Lines Color",
          type: "ColorPicker",
          key: "linesColor",
          colors: [
            {
              theme: "default-theme",
              options: ["#000066", "#888A8C"],
            },
            {
              theme: "sgpa-theme",
              options: ["#00808F", "#888A8C"],
            },
            {
              theme: "business-theme",
              options: ["#000066", "#888A8C"],
            },
            {
              theme: "law-theme",
              options: ["#D90011", "#888A8C"],
            },
            {
              theme: "architecture-theme",
              options: ["#572672", "#888A8C"],
            },
            {
              theme: "science-theme",
              options: ["#6DC201", "#888A8C"],
            },
            {
              theme: "humanities-theme",
              options: ["#FF630F", "#888A8C"],
            },
          ],
          helptext: "Color for the lines",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "StudentPath",
    anchorID: null,
    theme: "defaultAlt",
    imageStepColor: "#99D5DD",
    finalStepColor: "#99D5DD",
    linesColor: "#888A8C",
    verticalSpacing: "medium",

    title: { content: "Lorem ipsum", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    detail: null,
    elements: [],
    groupingLink: {
      component: "Link",
      text: "Discover more",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": StudentPath,
    "2x": StudentPath2x,
  },
}
