import { PublicationCard, PublicationCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Publication Card",
  component: "PublicationCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Author/s",
          type: "TextField",
          key: "author",
          hideable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Auxiliar Information",
          type: "TextField",
          key: "auxInfo",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          helptext: "Recommended minimum image size: 184x262",
        },
      ],
    },
  ],
  default: {
    component: "PublicationCard",
    title: "Title",
    author: "Author's name",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id ipsum dui. Maecenas vel metus quis metus consectetur viver",
    link: {
      component: "Link",
      text: "Link",
      style: "link",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    auxInfo: "Auxiliar information",
    image: "",
  },
  thumbnails: {
    "1x": PublicationCard,
    "2x": PublicationCard2x,
  },
}
