import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { VerticalTabs, VerticalTabs2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Vertical Tabs",
  component: "VerticalTabs",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Vertical Tab Element",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["VerticalTabElement"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "VerticalTabs",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { content: "Title", tag: "h4" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    detail: null,
    elements: [
      {
        component: "VerticalTabElement",
        title: { content: "Título", tag: "h3" },
        componentModules: {
          stepTabs: {
            component: "StepTabs",
          },
          basicContent: {
            component: "BasicContent",
            title: {
              content: "Default title",
              tag: "h1",
            },
            subtitle:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
            primaryLink: {
              component: "LinkModal",
              style: "primary",
              link: {
                text: "Link",
                linkType: "url",
                url: {
                  linkToURL: null,
                  newTab: false,
                  noFollow: false,
                },
                modal: {
                  requestInfo: {
                    component: "RequestInfo",
                  },
                  floatingCTAMail: {
                    component: "FloatingCTAMail",
                  },
                  floatingCTALightbox: {
                    component: "FloatingCTALightbox",
                  },
                  floatingCTAVideo: {
                    component: "FloatingCTAVideo",
                  },
                },
              },
            },
            secondaryLink: {
              component: "LinkModal",
            },
            additionalContent: {
              image: {
                component: "LinkableImage",
                ...DEFAULT_IMAGE_COMPONENT,
                veil: 0,
              },
              video: {
                component: "Video",
              },
            },
            theme: "defaultAlt",
            layout: "L001",
            moduleOrder: null,
          },
        },
      },
    ],
  },
  thumbnails: {
    "1x": VerticalTabs,
    "2x": VerticalTabs2x,
  },
}
