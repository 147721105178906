import { Milestones, Milestones2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Milestones",
  component: "Milestones",
  dataPacks: null,
  category: "content",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Elements",
          type: "ComponentArray",
          key: "elements",
          whiteList: ["MilestoneAudiovisual", "MilestoneSlide"],
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
      ],
    },
  ],

  default: {
    component: "Milestones",
    theme: "accent",
    anchorID: null,

    elements: [],
  },
  thumbnails: {
    "1x": Milestones,
    "2x": Milestones2x,
  },
}
