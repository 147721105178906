import { getThemeOptions } from "@helpers/schemas"
import { SocialMedia, SocialMedia2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Social Media",
  component: "SocialMedia",
  category: "social",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "SocialMedia",
    title: "Follow us",
    theme: "accent",
  },
  thumbnails: {
    "1x": SocialMedia,
    "2x": SocialMedia2x,
  },
}
