import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import {
  ProgramsPresentation,
  ProgramsPresentation2x,
} from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Programs Presentation",
  component: "ProgramsPresentation",
  category: "studies",
  dataPacks: null,
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem Ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          mandatory: true,
          helptext: "Recommended minimum image size: 1045x598",
        },
        {
          title: "Program Categories",
          type: "ComponentArray",
          key: "elements",
          maxItems: null,
          whiteList: ["ProgramCategory"],
          mandatory: true,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "ProgramsPresentation",
    title: {
      content: "Lorem Ipsum",
      tag: "h1",
    },
    subtitle: null,
    image: { ...DEFAULT_IMAGE_FIELD },
    elements: [
      {
        component: "ProgramCategory",
        title: {
          content: "Category 1",
          tag: "h3",
        },
        image: { ...DEFAULT_IMAGE_FIELD },
        viewAllLink: { component: "Link" },
        elements: [
          {
            component: "ProgramDescription",
            title: { content: "Element 1", tag: "h4" },
            image: null,
            elements: [
              {
                component: "QuickFactElement",
                title: "Duration",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Language",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Location",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Intake",
                detail: "Lorem ipsum",
              },
            ],
            link: {
              component: "Link",
              text: "Link",
              style: "primary",
              url: {
                newTab: false,
                noFollow: false,
                external: true,
              },
            },
            link2: { component: "Link" },
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          },
          {
            component: "ProgramDescription",
            title: {
              content: "Element 2",
              tag: "h4",
            },
            image: null,
            elements: [
              {
                component: "QuickFactElement",
                title: "Duration",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Language",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Location",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Intake",
                detail: "Lorem ipsum",
              },
            ],
            link: {
              component: "Link",
              text: "Link",
              style: "primary",
              url: {
                url: "",
                linkTo: null,
                newTab: false,
                noFollow: false,
                external: true,
                size: null,
                icon: null,
                linkContainer: null,
              },
            },
            link2: { component: "Link" },
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          },
        ],
        auxText: null,
      },
      {
        component: "ProgramCategory",
        title: {
          content: "Category 2",
          tag: "h3",
        },
        image: { ...DEFAULT_IMAGE_FIELD },
        viewAllLink: { component: "Link" },
        elements: [
          {
            component: "ProgramDescription",
            title: {
              content: "Element 1",
              tag: "h4",
            },
            image: null,
            elements: [
              {
                component: "QuickFactElement",
                title: "Duration",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Language",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Location",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Intake",
                detail: "Lorem ipsum",
              },
            ],
            link: {
              component: "Link",
              text: "Link",
              style: "primary",
              url: {
                url: "",
                linkTo: null,
                newTab: false,
                noFollow: false,
                external: true,
                size: null,
                icon: null,
                linkContainer: null,
              },
            },
            link2: { component: "Link" },
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          },
          {
            component: "ProgramDescription",
            title: {
              content: "Element 2",
              tag: "h4",
            },
            image: null,
            elements: [
              {
                component: "QuickFactElement",
                title: "Duration",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Language",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Location",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Intake",
                detail: "Lorem ipsum",
              },
            ],
            link: {
              component: "Link",
              text: "Link",
              style: "primary",
              url: {
                url: "",
                linkTo: null,
                newTab: false,
                noFollow: false,
                external: true,
                size: null,
                icon: null,
                linkContainer: null,
              },
            },
            link2: { component: "Link" },
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          },
        ],
        auxText: null,
      },
      {
        component: "ProgramCategory",
        title: {
          content: "Category 3",
          tag: "h3",
        },
        image: { ...DEFAULT_IMAGE_FIELD },
        viewAllLink: { component: "Link" },
        elements: [
          {
            component: "ProgramDescription",
            title: {
              content: "Element 1",
              tag: "h4",
            },
            image: null,
            elements: [
              {
                component: "QuickFactElement",
                title: "Duration",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Language",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Location",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Intake",
                detail: "Lorem ipsum",
              },
            ],
            link: {
              component: "Link",
              text: "Link",
              style: "primary",
              url: {
                url: "",
                linkTo: null,
                newTab: false,
                noFollow: false,
                external: true,
                size: null,
                icon: null,
                linkContainer: null,
              },
            },
            link2: { component: "Link" },
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          },
          {
            component: "ProgramDescription",
            title: {
              content: "Element 2",
              tag: "h4",
            },
            image: null,
            elements: [
              {
                component: "QuickFactElement",
                title: "Duration",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Language",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Location",
                detail: "Lorem ipsum",
              },
              {
                component: "QuickFactElement",
                title: "Intake",
                detail: "Lorem ipsum",
              },
            ],
            link: {
              component: "Link",
              text: "Link",
              style: "primary",
              url: {
                url: "",
                linkTo: null,
                newTab: false,
                noFollow: false,
                external: true,
                size: null,
                icon: null,
                linkContainer: null,
              },
            },
            link2: { component: "Link" },
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          },
        ],
        auxText: null,
      },
    ],
    groupingLink: {
      component: "Link",
      text: "See All Programs",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": ProgramsPresentation,
    "2x": ProgramsPresentation2x,
  },
}
