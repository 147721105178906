import { SOCIAL_ICONS_MAP } from "@constants"
const isHttpLink = str => (str ? Boolean(str.match(/^http/g)) : false)

const isRouterLink = str => !isHttpLink(str)

function parseSocials(socialsObject, map = SOCIAL_ICONS_MAP) {
  if (!!socialsObject) {
    let socialsMapped = []
    Object.keys(socialsObject).map(key => {
      if (map[key]) {
        return (
          socialsObject[key].length &&
          socialsMapped.push({ icon: map[key], url: socialsObject[key] })
        )
      }
    })
    return socialsMapped
  }
  return null
}

const searchResultsPage = "https://www.ie.edu/search-results/"

export { parseSocials, isHttpLink, isRouterLink, searchResultsPage }
