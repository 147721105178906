import { Header, Header2x } from "@thumbnails/Modules"
import { themeHeaderStyle1, themeHeaderStyle2 } from "@theme-img"

export default {
  schemaType: "module",
  displayName: "Header",
  component: "Header",
  type: "header",
  category: "header",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Name",
          key: "title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },

        {
          title: "Show top navigation",
          type: "ConditionalField",
          key: "showTopNavigation",
          options: [
            { value: true, title: "Yes", name: "yes" },
            { value: false, title: "No", name: "no" },
          ],
          fields: [
            {
              title: "Title",
              key: "note01",
              type: "NoteField",
              condition: true,
            },
            {
              title: "Check options to add on Top Navigation",
              type: "CheckGroup",
              key: "topNavigationContent",
              condition: true,
              options: [
                {
                  value: "showBot",
                  title: "Show bot",
                  name: "showBot",
                },
                {
                  value: "showSocialMedia",
                  title: "Show social media",
                  name: "showSocialMedia",
                },
              ],
            },
            {
              title: "Order social media icons",
              type: "ConditionalField",
              key: "orderSocialIcons",
              condition: true,
              options: [
                { value: true, title: "Yes", name: "yes" },
                { value: false, title: "No", name: "no" },
              ],
              fields: [
                {
                  title: "Select social media order",
                  type: "CheckGroup",
                  key: "socialMediaOrder",
                  condition: true,
                  options: [
                    {
                      value: "instagram",
                      title: "Instagram",
                      name: "instagram",
                    },
                    {
                      value: "linkedIn",
                      title: "LinkedIn",
                      name: "linkedIn",
                    },
                    {
                      value: "twitter",
                      title: "X/Twitter",
                      name: "twitter",
                    },
                    {
                      value: "facebook",
                      title: "Facebook",
                      name: "facebook",
                    },
                    {
                      value: "tiktok",
                      title: "TikTok",
                      name: "tiktok",
                    },
                    {
                      value: "youtube",
                      title: "YouTube",
                      name: "youtube",
                    },
                    {
                      value: "podcast",
                      title: "Podcast",
                      name: "podcast",
                    },
                    {
                      value: "flickr",
                      title: "Flickr",
                      name: "flickr",
                    },
                    {
                      value: "snapchat",
                      title: "Snapchat",
                      name: "snapchat",
                    },
                    {
                      value: "email",
                      title: "Newsletter",
                      name: "email",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          title: "Logo",
          type: "Select",
          key: "school",
          mandatory: true,
          options: [
            { value: "IE", label: "Instituto de empresa" },
            { value: "IENYC", label: "Instituto de empresa NYC" },
            { value: "BUS", label: "Business School" },
            {
              value: "CLIC",
              label: "Center for Corporate Learning and Talent Management",
            },
            { value: "XL", label: "Lifelong Learning" },
            { value: "LAW", label: "Law School" },
            { value: "ARQ", label: "School of Architecture and Design" },
            { value: "GPA", label: "School of Global and public Affairs" },
            {
              value: "SPEGA",
              label: "School of Politics, Economics & Global Affairs",
            },
            { value: "HMN", label: "Humanities School" },
            { value: "HST", label: "School of Science and Technology" },
            { value: "AL", label: "Alumni" },
            { value: "CGC", label: "Center for the Gobernance of Change" },
            { value: "CR", label: "Corporate Relations" },
            { value: "ENT", label: "Entrepreneurship" },
            { value: "UNI", label: "University" },
            { value: "TC", label: "Talent & Career" },
            { value: "FOUND", label: "IE Foundation" },
            { value: "FAID", label: "Financial Aid" },
            {
              value: "LEAD",
              label: "Center Middle East",
            },
            { value: "SST50", label: "SST 50" },
            { value: "Architecture50", label: "Architecture 50" },
            { value: "Business50", label: "Business 50" },
            { value: "Law50", label: "Law 50" },
            { value: "Spega50", label: "SPEGA 50" },
            { value: "University50", label: "University 50" },
            { value: "LifelongLearning50", label: "LifelongLearning 50" },
            { value: "Humanities50", label: "Humanities 50" },
          ],
        },

        {
          title: "Primary link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "primaryLink",
          hideable: true,
        },
        {
          title: "Secondary link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "secondaryLink",
          hideable: true,
        },
      ],
    },

    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          type: "UniqueCheck",
          key: "setAsDefault",
          options: [{ title: "Set as Header default" }],
        },

        {
          title: "Top menu",
          type: "AsyncSelect",
          entity: "menu_containers",
          key: "topMenu",
          mandatory: true,
        },

        {
          title: "Main menu",
          type: "AsyncSelect",
          entity: "menu_containers",
          key: "mainMenu",
          mandatory: true,
        },

        {
          type: "UniqueCheck",
          key: "sticky",
          options: [{ title: "Keep sticky on scroll" }],
          condition: true,
        },

        {
          title: "Add navigation banner",
          type: "ConditionalField",
          key: "navigationBanner",
          mandatory: false,
          options: [
            { value: true, title: "Yes", name: "yes" },
            { value: false, title: "No", name: "no" },
          ],
          fields: [
            {
              title: "Icon",
              type: "ImageField",
              key: "navigationBannerIcon",
              mandatory: false,
              condition: true,
            },
            {
              title: "Navigation Banner Text",
              type: "TextField",
              key: "navigationBannerText",
              hideable: true,
              condition: true,
              humanReadable: true,
            },
            {
              title: "Link",
              type: "ComponentContainer",
              whiteList: ["Link"],
              key: "navigationBannerLink",
              hideable: true,
              condition: true,
            },
            {
              title: "Background color",
              type: "ColorPicker",
              colors: [
                "#d9e3f0",
                "#f47373",
                "#697689",
                "#37d67a",
                "#2ccce4",
                "#555555",
                "#dce775",
                "#ff8a65",
                "#ba68c8",
              ],
              key: "navigationBannerBGColor",
              condition: true,
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "Header",
    type: "header",

    // Content
    title: "Header name",
    note01: {
      title: "",
      text: "To configure social links go to settings/general/social. To activate search feature go to settings/actionables. The Results Page must be previously created.",
    },
    showTopNavigation: true,
    topNavigationContent: [],
    orderSocialIcons: false,
    socialMediaOrder: [],
    school: "IE",
    primaryLink: {
      component: "Link",
    },
    secondaryLink: {
      component: "Link",
    },

    // Config
    setAsDefault: false,
    topMenu: null,
    mainMenu: null,
    sticky: true,
    navigationBannerIcon: null,
    navigationBanner: false,
    navigationBannerText: "lorem ipsum",
    navigationBannerLink: {
      component: "Link",
    },
    navigationBannerBGColor: "#50ABFF",
  },
  thumbnails: {
    "1x": Header,
    "2x": Header2x,
  },
  styles: {
    S001: themeHeaderStyle1,
    S002: themeHeaderStyle2,
  },
}
