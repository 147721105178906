import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { Slider, Slider2x, Slide, TabSlide } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Slider",
  component: "Slider",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h1", content: "Lorem Ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          elementUniqueSelection: true,
          maxItems: null,
          whiteList: ["Slide", "TabSlide"],
          key: "elements",
          mandatory: true,
        },
        {
          title: "Grouping link",
          type: "ComponentContainer",
          key: "groupingLink",
          whiteList: ["Link"],
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Slide Style",
          key: "kind",
          type: "VisualUniqueSelection",
          elementUniqueSelection: true,
          options: [
            {
              value: "Slide",
              img: Slide,
            },
            {
              value: "TabSlide",
              img: TabSlide,
            },
          ],
          columns: 3,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "Slider",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    kind: "Slide",
    title: {
      content: "Lorem Ipsum",
      tag: "h1",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    detail: null,
    groupingLink: {
      component: "Link",
      text: "Link",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    elements: [
      {
        component: "Slide",
        tabTitle: "Lorem Ipsum",
        image: {
          component: "Image",
          ...DEFAULT_IMAGE_COMPONENT,
        },
        title: {
          content: "Title",
          tag: "h2",
        },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        link: {
          component: "Link",
          text: "Link",
          style: "link",
          url: {
            url: "",
            linkTo: null,
            newTab: false,
            noFollow: false,
            external: true,
            size: null,
            icon: null,
            linkContainer: null,
          },
        },
      },
      {
        component: "Slide",
        tabTitle: "Lorem Ipsum",
        image: {
          component: "Image",
          ...DEFAULT_IMAGE_COMPONENT,
        },
        title: {
          content: "Title",
          tag: "h2",
        },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
        link: {
          component: "Link",
          text: "Link",
          style: "link",
          url: {
            url: "",
            linkTo: null,
            newTab: false,
            noFollow: false,
            external: true,
            size: null,
            icon: null,
            linkContainer: null,
          },
        },
      },
    ],
  },
  thumbnails: {
    "1x": Slider,
    "2x": Slider2x,
  },
}
