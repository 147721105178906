import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { VerticalSlide, VerticalSlide2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Vertical Slide",
  component: "VerticalSlide",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Additional content",
          type: "ComponentContainer",
          whiteList: ["LinkableImage", "Video"],
          key: "additionalContent",
          hideable: true,
          helptext: "Recommended minimum image size: 740x480",
        },
      ],
    },
  ],
  default: {
    component: "VerticalSlide",
    title: {
      content: "Default title",
      tag: "h1",
    },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    additionalContent: {
      image: {
        component: "LinkableImage",
        ...DEFAULT_IMAGE_COMPONENT,
      },
      video: {
        component: "Video",
      },
    },
  },
  thumbnails: {
    "1x": VerticalSlide,
    "2x": VerticalSlide2x,
  },
}
