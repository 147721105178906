import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { VideoPlaylist, VideoPlaylist2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Video Playlist",
  component: "VideoPlaylist",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Type of Links",
          type: "ConditionalField",
          key: "typeLinks",
          required: true,
          options: [
            {
              value: true,
              title: "Individual video",
              name: "individualVideo",
            },
            {
              value: false,
              title: "Url Playlist",
              name: "youtubePlaylist",
            },
          ],
          fields: [
            {
              title: "Individual video elements",
              type: "ComponentArray",
              maxItems: null,
              whiteList: ["VideoTitle"],
              key: "elements",
              mandatory: true,
              condition: true,
            },
            {
              title: "Url Playlist",
              key: "urlPlaylist",
              type: "TextField",
              mandatory: true,
              condition: false,
            },
            {
              title: "Number of playlist videos",
              type: "NumberField",
              key: "videosQuantity",
              default: 5,
              mandatory: true,
              helptext: "Max. 50 videos per playlist",
              validators: { maxValue: 50 },
              condition: false,
            },
            {
              type: "UniqueCheck",
              key: "automaticSubtitles",
              options: [
                {
                  title: "Add automatic subtitles from Youtube",
                },
              ],
              condition: false,
            },
          ],
        },
        {
          title: "Main Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "primaryLink",
          hideable: true,
        },
        {
          title: "Secondary Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "secondaryLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(["defaultAlt", "default", "inverse"]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "VideoPlaylist",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: {
      content: "Title",
      tag: "h2",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam convallis ornare mauris, eget vehicula lacus efficitur nulla.",
    detail: null,
    typeLinks: true,
    elements: [
      {
        component: "VideoTitle",
        title: "Lorem ipsum #1",
        url: "",
        thumbnail: { component: "Image", ...DEFAULT_IMAGE_COMPONENT },
      },
      {
        component: "VideoTitle",
        title: "Lorem ipsum #2",
        url: "",
        thumbnail: { component: "Image", ...DEFAULT_IMAGE_COMPONENT },
      },
      {
        component: "VideoTitle",
        title: "Lorem ipsum #3",
        url: "",
        thumbnail: { component: "Image", ...DEFAULT_IMAGE_COMPONENT },
      },
      {
        component: "VideoTitle",
        title: "Lorem ipsum #4",
        url: "",
        thumbnail: { component: "Image", ...DEFAULT_IMAGE_COMPONENT },
      },
    ],
    videosQuantity: 5,
    automaticSubtitles: false,
    primaryLink: {
      component: "Link",
      text: "Lorem ipsum",
      style: "primary",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        external: true,
        size: "m",
        icon: null,
        linkContainer: null,
      },
    },
    secondaryLink: {
      component: "Link",
    },
  },
  thumbnails: {
    "1x": VideoPlaylist,
    "2x": VideoPlaylist2x,
  },
}
