export function fillIntArray(n, from = 0) {
  return Array(n)
    .fill(0)
    .map((_, idx) => idx + from)
}

export const filterDuplicatedObjectsFromArray = arr =>
  arr.reduce((acc, current) => {
    const item = acc.find(item => item.value === current.value)
    return item ? acc : acc.concat([current])
  }, [])

export const groupArray = (data, n) => {
  var group = []
  for (var i = 0, j = 0; i < data.length; i++) {
    if (i >= n && i % n === 0) j++
    group[j] = group[j] || []
    group[j].push(data[i])
  }

  return group
}
