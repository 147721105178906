import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { FloatingCTAVideo, FloatingCTAVideo2x } from "@thumbnails/Modules"

export default {
  schemaType: "component",
  displayName: "FloatingCTA Video",
  component: "FloatingCTAVideo",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Video Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "URL Video",
          type: "TextField",
          key: "url",
          placeholder: "https://www.youtube.com/...",
          mandatory: true,
        },
        {
          title: "Thumbnail",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "thumbnail",
          helptext: "Recommended minimum image size: 941x529",
        },
      ],
    },
  ],
  default: {
    component: "FloatingCTAVideo",
    title: "Lorem ipsum",
    thumbnail: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    url: "",
  },
  thumbnails: {
    "1x": FloatingCTAVideo,
    "2x": FloatingCTAVideo2x,
  },
}
