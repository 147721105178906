export function objectIsEmptyOrNull(obj) {
  if (obj === null) {
    return true
  } else {
    return !Object.keys(obj).length
  }
}

export function flattenObject(ob) {
  var toReturn = {}
  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue
    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i])
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue
        toReturn[i + "." + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}

// Check if an object from schema defaults is empty:
// It has just three properties and they are called `component`, `editorID` and `parentEditorID`
export const apiComponentIsEmpty = componentObject => {
  return !Object.keys(componentObject).some(
    key => key !== "component" && key !== "editorID" && key !== "parentEditorID"
  )
}
