import { allModulesWhitelist } from "@helpers/schemas"
import { StoriesList, StoriesList2x } from "@thumbnails/Templates"
import { StoriesListLayout1, StoriesListLayout2 } from "@layouts"
import {
  StoriesDistributorD001,
  StoriesDistributorD002,
} from "@thumbnails/Modules"
//Module default
import SocialMedia from "@schemas/modules/SocialMedia"

export default {
  displayName: "Stories List",
  component: "StoriesList",
  dataPacks: ["STORIES"],
  type: { label: "Stories", value: "stories" },
  schemaType: "template",
  config: [
    {
      title: "Design",
      key: "design",
      type: "VisualUniqueSelection",
      mandatory: true,
      options: [
        {
          value: "D001",
          img: StoriesDistributorD001,
        },
        {
          value: "D002",
          img: StoriesDistributorD002,
        },
      ],
      columns: 2,
    },
    {
      title: "Layout",
      key: "layout",
      type: "VisualUniqueSelection",
      mandatory: true,
      options: [
        {
          value: "L001",
          img: StoriesListLayout1,
        },
        {
          value: "L002",
          img: StoriesListLayout2,
        },
      ],
      columns: 2,
      helptext: "Layout of 4 elements per row will be 5 for first design",
    },
  ],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
    },
    {
      key: "leadText",
      title: "Lead Text",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programsFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showGenderFilter",
              title: "Show Gender filter",
              name: "genderFilter",
            },
            {
              value: "showStageTargetFilter",
              title: "Show Stage Target filter",
              name: "stageTargetFilter",
            },
            {
              value: "showRegionFilter",
              title: "Show Region filter",
              name: "regionFilter",
            },
            {
              value: "showFormatFilter",
              title: "Show Format filter",
              name: "formatFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showProfilesFilter",
              title: "Show Profiles filter",
              name: "profilesFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "genderLabel",
          title: "Gender label",
          type: "TextField",
        },
        {
          key: "stageTargetLabel",
          title: "Stage target label",
          type: "TextField",
        },
        {
          key: "regionLabel",
          title: "Region label",
          type: "TextField",
        },
        {
          key: "formatLabel",
          title: "Format label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
        {
          key: "profilesLabel",
          title: "Profiles label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "STORIES" }],
      key: "data",
    },
    {
      title: "Content hierarchy for SEO",
      type: "Select",
      key: "cardTitleTag",
      options: [
        { value: "h2", label: "H2" },
        { value: "h3", label: "H3" },
        { value: "h4", label: "H4" },
        { value: "span", label: "Span" },
      ],
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 20,
      maxValue: 40,
      mandatory: true,
    },
    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],
  default: {
    type: "template",
    templateType: "StoriesList",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    leadText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    templateOptions: [],
    filtersType: "simple",
    layout: "L001",
    design: "D001",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "STORIES",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 20,
      preferenceLanguage: false,
    },
    cardTitleTag: "h2",
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 20,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [{ ...SocialMedia.default }],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": StoriesList,
    "2x": StoriesList2x,
  },
}
