import { getThemeOptions } from "@helpers/schemas"
import { BasicWysiwyg, BasicWysiwyg2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Basic Wysiwyg",
  component: "BasicWysiwyg",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Content title",
          type: "TextField",
          key: "contentTitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Main content",
          type: "Wysiwyg",
          key: "content",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
          mandatory: true,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "BasicWysiwyg",
    theme: "default",
    verticalSpacing: "medium",
    contentTitle: "Lorem ipsum",
    content: `
<h4>Lorem Ipsum</h4>
<p>Lorem Ipsum paragraph</p>`,
  },
  thumbnails: {
    "1x": BasicWysiwyg,
    "2x": BasicWysiwyg2x,
  },
}
