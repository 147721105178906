/**
 * Elimina una etiqueta del DOM buscándola por el id.
 * @param {string} id - id de la etiqueta.
 */
const removeTagById = id => {
  const element = document.getElementById(id)
  if (element) {
    element.parentNode.removeChild(element)
  }
}

/**
 * Elimina una etiqueta padre del DOM buscándola por el id.
 * @param {string} id - id de la etiqueta "hija"
 */
function removeParentTagById(id) {
  const element = document.getElementById(id)
  if (element) {
    element.parentElement.remove()
  }
}

/**
 * Elimina una etiqueta `<style>` del DOM buscándola por el contenido de un `#id` de CSS.
 * @param {string} idSelector - El selector `#id` que se utiliza para devolver la etiqueta `<style>`.
 * @example
 * removeStyleByIdSelector("container")
 * // Borrará todas las etiquetas `<style>` que contengan una entrada de id como:
 * <style>
 *     #container {
 * 	       ...
 *     }
 * </style>
 */
const removeStyleById = idSelector => {
  const styles = document.getElementsByTagName("style")
  Array.from(styles).forEach(style => {
    const styleContent = style.textContent
    const regex = new RegExp(`#${idSelector}\\s*\\{`)

    if (regex.test(styleContent)) {
      style.parentNode.removeChild(style)
    }
  })
}

/**
 * Crea una etiqueta `<script>` en el `<head>`.
 * @param {string} id - the id of the `<script>`.
 * @param {string} src - the src of the `<script>`.
 */
function createScriptTagInTheHead({ id, src }) {
  const script = document.createElement("script")
  script.id = id
  script.src = src
  document.head.appendChild(script)
}

/**
 * Maneja la integración del chatbot para que no se arrastre entre páginas donde
 * no está definido.  Esto arregla una carencia en Griddo al no controlar este
 * aspecto en las páginas renderizadas que usen el Router para navegar.  Esta
 * pensada para utilizarse con la API Browser de gatsby en `builder.browser.js`
 * @example
 * const onRouteUpdate = () => {
 *     chatbotAddon("ie-chatbot");
 * };
 */
function chatbotAddon() {
  const ADDON_CENTINEL_ID = "ie-chatbot"
  const CHATBOT_BUTTON_ID = "chatbot-button"
  const CHATBUTTON_SRC = "https://chatbot.dataoffice.ie.edu/chatbot/chatbot.js"
  const CHATBOT_ID = "chatbot-container"
  const CHATBOT_STYLE_ID = "chatbot-container"

  const chatbotCentinel = document.getElementById(ADDON_CENTINEL_ID)
  const chatbotButton = document.getElementById(CHATBOT_BUTTON_ID)

  // Si existe el centinela, es que tenemos que añadir el bot. Iyectamos el
  // script del botón del chatbot. Es lo que poníamos en AX `<script
  // src="https://chatbot........./>`.
  if (chatbotCentinel) {
    createScriptTagInTheHead({ id: CHATBOT_BUTTON_ID, src: CHATBUTTON_SRC })
  }

  // Si no existe el centinela pero si el button, borramos todo lo relacionado
  // con el bot.
  if (!chatbotCentinel && chatbotButton) {
    removeParentTagById(CHATBOT_ID)
    removeTagById(CHATBOT_BUTTON_ID)
    removeStyleById(CHATBOT_STYLE_ID)
  }
}

export { chatbotAddon }
