// This function returns the number o 9 of the zIndex (e.g. 3 is 999)
const Top = number => 10 ** number - 1

const zIndex = {
  modal: {
    main: Top(6),
    content: Top(7),
    close: Top(7),
  },
  header: {
    main: Top(5),
    outter1: Top(3),
    outter2: Top(5),
    outterDefault: Top(1),
    icons: Top(6),
    closeNav: Top(3),
    recursive: Top(4),
    subMenu: Top(3),
    subMenuWrapper: Top(4),
  },
}

export default zIndex
