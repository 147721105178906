import { HeroCarousel, HeroCarousel2x } from "@thumbnails/Modules"

import TextualPanel from "../components/TextualPanel"

export default {
  schemaType: "module",
  displayName: "Hero Carousel",
  component: "HeroCarousel",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Elements",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          whiteList: ["TextualPanel"],
          key: "elements",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Anchor Text",
          type: "TextField",
          key: "anchorText",
          hideable: true,
          humanReadable: true,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "HeroCarousel",
    anchorText: null,
    elements: [{ ...TextualPanel.default }],
  },
  thumbnails: {
    "1x": HeroCarousel,
    "2x": HeroCarousel2x,
  },
}
