import { getThemeOptions } from "@helpers/schemas"
import { SearchModule, SearchModule2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Search Module",
  component: "SearchModule",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "",
          key: "note01",
          type: "NoteField",
        },
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
      ],
    },
  ],
  default: {
    component: "SearchModule",
    verticalSpacing: "medium",
    theme: "defaultAlt",
    anchorID: null,
    note01: {
      title: "Important",
      text: "The Results Page must be previously created.",
    },
    title: { content: "Search IE", tag: "h2" },
  },
  thumbnails: {
    "1x": SearchModule,
    "2x": SearchModule2x,
  },
}
