import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { getThemeOptions } from "@helpers/schemas"
import {
  RequestInfoCollection,
  RequestInfoCollection2x,
} from "@thumbnails/Modules"

const requestInfoDefault = {
  component: "RequestInfoCard",
  image: {
    component: "Image",
    ...DEFAULT_IMAGE_COMPONENT,
  },
  name: "Name",
  role: "Role",
  description: null,
  showIntro: true,
  title: {
    content: "Título",
    tag: "h1",
  },
  subtitle:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
  detail: null,
  hasMeeting: true,
  hasWhatsapp: true,
  meetingLink: "",
  whatsappLink: "",
  meetingLinkCTA: "Book a Call",
  whatasppLinkCTA: "Click to Whatsapp",
  whatsappMsg: "Thank you for your interest!",
  componentModules1: {
    component: "IntroFormElement",
    formTitle: "Title 1",
    hasImage: false,
    formImage: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
  },
  componentModules2: {
    component: "IntroFormElement",
    formTitle: "Title 2",
    hasImage: false,
    formImage: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
  },
}

export default {
  schemaType: "module",
  displayName: "Request Info Collection",
  component: "RequestInfoCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Elements",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          key: "elements",
          whiteList: ["RequestInfoCard"],
        },
        {
          title: "Highlight",
          type: "UniqueCheck",
          label: "highlight",
          key: "highlight",
          helptext: "Highlight first element",
          options: [
            {
              title: "Highlight first element",
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "RequestInfoCollection",
    highlight: false,
    elements: [requestInfoDefault, requestInfoDefault],
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": RequestInfoCollection,
    "2x": RequestInfoCollection2x,
  },
}
