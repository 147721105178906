import { AwardProposalForm, AwardProposalForm2x } from "@thumbnails/Templates"

// Defaults from modules
import HeroText from "@schemas/modules/HeroText"

export default {
  schemaType: "template",
  displayName: "Award proposal - Form",
  component: "AwardProposalForm",
  dataPacks: null,
  type: { label: "Static", value: "static" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
      ],
      key: "heroSection",
      mandatory: true,
    },
    {
      title: "Subtitle",
      type: "RichText",
      html: true,
      key: "subtitle",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Detail",
      type: "RichText",
      html: true,
      key: "detail",
      hideable: true,
      humanReadable: true,
    },
    {
      key: "title01",
      title: "Title 01",
      type: "TextField",
      mandatory: true,
      humanReadable: true,
    },
    {
      key: "emailTo",
      title: "Email to",
      type: "TextField",
      mandatory: true,
    },
    {
      title: "Legal condition page",
      type: "UrlField",
      advanced: true,
      key: "legalConditions",
      mandatory: true,
    },
  ],
  default: {
    type: "template",
    templateType: "AwardProposalForm",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ ...HeroText.default }],
      sectionPosition: 1,
    },
    subtitle: null,
    detail: null,
    title01: "01. Proposal information",
    emailTo: "alumni@ie.edu",
    legalConditions: "",
  },
  thumbnails: {
    "1x": AwardProposalForm,
    "2x": AwardProposalForm2x,
  },
}
