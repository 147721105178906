import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { HeroQuote, HeroQuote2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Quote",
  component: "HeroQuote",
  dataPacks: null,
  category: "hero",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Quote",
          type: "TextField",
          key: "quote",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Author",
          type: "TextField",
          key: "author",
          hideable: true,
        },
        {
          title: "Position",
          type: "TextField",
          key: "position",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          hideable: true,
          helptext: "Recommended minimum image size: 2508x1030",
        },
      ],
    },
  ],

  default: {
    component: "HeroQuote",
    quote: "Lorem Ipsum",
    author: "Lorem Ipsum",
    position: "Lorem Ipsum",
    theme: "accent",
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
  },
  thumbnails: {
    "1x": HeroQuote,
    "2x": HeroQuote2x,
  },
}
