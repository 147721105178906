import { getThemeOptions } from "@helpers/schemas"
import {
  MilestoneAudiovisual,
  MilestoneAudiovisual2x,
} from "@thumbnails/Components"
import {
  MilestoneAudiovisualLayout1,
  MilestoneAudiovisualLayout2,
} from "@layouts"

export default {
  schemaType: "component",
  displayName: "Milestone Audiovisual",
  component: "MilestoneAudiovisual",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Slider Date",
          type: "TextField",
          key: "year",
          hideable: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h3", content: "Título" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          key: "subtitle",
          html: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Audiovisual content",
          type: "ComponentArray",
          key: "content",
          whiteList: ["Image", "Video", "MilestoneClaim", "MilestoneCypher"],
          maxItems: 3,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
        {
          title: "Social share",
          type: "RadioGroup",
          key: "socialShare",
          hideable: true,
          options: [
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
            {
              name: "no",
              value: false,
              title: "No",
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: MilestoneAudiovisualLayout1,
            },
            {
              value: "L002",
              img: MilestoneAudiovisualLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(["accent", "accentLight", "accentMid"]),
          columns: 8,
        },
      ],
    },
  ],
  default: {
    component: "MilestoneAudiovisual",
    layout: "L001",
    theme: "accent",
    title: { content: "Título", tag: "h3" },
    year: "1980",
    subtitle: "Lorem ipsum",
    content: [],
    groupingLink: {
      component: "Link",
    },
    socialShare: null,
  },
  thumbnails: {
    "1x": MilestoneAudiovisual,
    "2x": MilestoneAudiovisual2x,
  },
}
