import { allModulesWhitelist, getLinkStyles } from "@helpers/schemas"
import { AcademicVideo, AcademicVideo2x } from "@thumbnails/Templates"

export default {
  displayName: "Articles List",
  component: "ArticlesList",
  dataPacks: ["ARTICLES"],
  type: { label: "Articles", value: "articles" },
  schemaType: "template",
  config: [
    {
      title: "Style",
      key: "primaryLinkStyle",
      type: "VisualUniqueSelection",
      options: getLinkStyles(),
      columns: 3,
    },
  ],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
      ],
      key: "heroSection",
      mandatory: true,
    },
    {
      title: "Detail text",
      type: "RichText",
      html: true,
      key: "detailText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "ARTICLES" }],
      key: "data",
      title: "",
    },
    {
      title: "No Results Text",
      type: "TextField",
      key: "noResultsText",
      mandatory: true,
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 4,
      mandatory: true,
    },
    {
      title: "Cards titles link",
      type: "RadioGroup",
      key: "titlesLink",
      mandatory: false,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],
  default: {
    type: "template",
    templateType: "ArticlesList",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    detailText: "Lorem ipsum",
    templateOptions: [],
    filtersType: "simple",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "ARTICLES",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      quantity: 4,
      preferenceLanguage: false,
    },
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 4,
    titlesLink: false,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related content",
      modules: [],
      sectionPosition: 2,
    },
    primaryLinkStyle: "primary",
  },
  thumbnails: {
    "1x": AcademicVideo,
    "2x": AcademicVideo2x,
  },
}
