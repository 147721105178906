import { getThemeOptions } from "@helpers/schemas"
import VideoTitle from "@schemas/components/VideoTitle"
import { VideoGallery, VideoGallery2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Video Gallery",
  component: "VideoGallery",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          default: "Lorem ipsum",
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default: "Lorem ipsum",
          humanReadable: true,
        },
        {
          title: "Content",
          type: "ConditionalField",
          key: "content",
          hideable: true,
          options: [
            {
              value: "automatic",
              title: "Automatic",
              name: "automatic",
            },
            {
              value: "manual",
              title: "Manual",
              name: "manual",
            },
          ],
          fields: [
            {
              title: "Videos",
              type: "ComponentArray",
              key: "videos",
              whiteList: ["VideoTitle"],
              mandatory: true,
              condition: "manual",
            },
            {
              title: "YouTube Playlist",
              key: "playlist",
              type: "TextField",
              condition: "automatic",
            },
            {
              title: "Playlist videos",
              type: "NumberField",
              key: "videosQuantity",
              helptext: "Max. 50 videos",
              validators: { maxValue: 50 },
              condition: "automatic",
            },
          ],
        },
      ],
    },

    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "VideoGallery",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    title: { tag: "h2", content: "Title" },
    subtitle: null,
    detail: null,
    content: "automatic",
    videosQuantity: 10,
    videos: [{ ...VideoTitle.default }],
  },
  thumbnails: {
    "1x": VideoGallery,
    "2x": VideoGallery2x,
  },
}
