import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Masonry Card",
  component: "MasonryCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Type",
          type: "RadioGroup",
          key: "type",
          mandatory: true,
          options: [
            {
              value: "article",
              title: "Article",
              name: "article",
            },
            {
              value: "video",
              title: "Video",
              name: "video",
            },
            {
              value: "podcast",
              title: "Podcast",
              name: "podcast",
            },
            {
              value: "infographic",
              title: "Infographic",
              name: "infographic",
            },
            {
              value: "discover",
              title: "Discover",
              name: "discover",
            },
          ],
        },
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          mandatory: true,
          key: "image",
          helptext: "Recommended minimum image size: 502x420",
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Category",
          type: "TextField",
          key: "category",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "UrlField",
          key: "link",
          mandatory: true,
          advanced: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Size",
          type: "RadioGroup",
          key: "size",
          mandatory: true,
          options: [
            {
              value: "big",
              title: "Big",
              name: "big",
            },
            {
              value: "medium",
              title: "Medium",
              name: "medium",
            },
            {
              value: "small",
              title: "Small",
              name: "small",
            },
          ],
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "MasonryCard",
    type: "article",
    image: {
      component: "Image",
      veil: 30,
      file: { ...DEFAULT_IMAGE_FIELD },
    },
    title: {
      content: "Title",
      tag: "h4",
    },
    category: "Lorem ipsum",
    link: {
      external: true,
      href: "",
      icon: "",
      label: "",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    size: "big",
    theme: "default",
  },
}
