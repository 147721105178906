export default {
  schemaType: "component",
  component: "HeroSideCard",
  displayName: "Hero Side Card",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Type of content",
          type: "ConditionalField",
          key: "typeOfContent",
          mandatory: true,
          required: true,
          options: [
            {
              value: "news",
              title: "News",
              name: "News",
            },
            {
              value: "events",
              title: "Events",
              name: "Events",
            },
            {
              value: "featuredContent",
              title: "Featured Content",
              name: "Featured Content",
            },
          ],
          fields: [
            {
              condition: "news",
              title: "",
              type: "ReferenceField",
              sources: [{ structuredData: "NEWS" }],
              key: "newsData",
            },
            {
              condition: "news",
              title: "Content hierarchy for SEO",
              type: "Select",
              key: "cardTitleTag",
              options: [
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "Span" },
              ],
            },
            {
              condition: "events",
              title: "",
              type: "ReferenceField",
              sources: [
                { structuredData: "EVENTS" },
                { structuredData: "EVENTS_AUTO" },
              ],
              key: "eventsData",
            },
            {
              condition: "events",
              title: "Content hierarchy for SEO",
              type: "Select",
              key: "cardTitleTag",
              options: [
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "Span" },
              ],
            },
            {
              condition: "featuredContent",
              title: "Title",
              type: "HeadingField",
              key: "FCTitle",
              advanced: true,
              default: { tag: "span", content: "Title" },
              options: [
                { value: "h1", label: "H1" },
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "span" },
              ],
              humanReadable: true,
            },
            {
              condition: "featuredContent",
              title: "Subtitle",
              type: "RichText",
              html: true,
              key: "FCSubtitle",
              humanReadable: true,
            },
            {
              condition: "featuredContent",
              title: "Image",
              type: "ComponentContainer",
              whiteList: ["Image"],
              key: "FCImage",
            },
            {
              condition: "featuredContent",
              title: "Link",
              type: "ComponentContainer",
              whiteList: ["Link"],
              key: "FCLink",
              hideable: true,
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "HeroSideCard",
    typeOfContent: "news",
    cardTitleTag: "h2",
    newsData: {
      mode: "auto",
      sources: [
        {
          structuredData: "NEWS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      quantity: 1,
    },
    eventsData: {
      mode: "auto",
      sources: [
        {
          structuredData: "EVENTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      quantity: 1,
    },
    FCTitle: {
      content: "Title",
      tag: "span",
    },
    FCSubtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    FCImage: {
      component: "Image",
    },
    FCLink: {
      component: "Link",
      style: "primary",
      text: "Link",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
}
