import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { VerticalSlider, VerticalSlider2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Vertical Slider",
  component: "VerticalSlider",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h1", content: "Lorem Ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["VerticalSlide"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "VerticalSlider",
    title: {
      content: "Title",
      tag: "h1",
    },
    subtitle: "Lorem ipsum",
    detail: null,
    theme: "default",
    elements: [
      {
        component: "VerticalSlide",
        title: {
          content: "Default title",
          tag: "h1",
        },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",

        additionalContent: {
          image: {
            component: "LinkableImage",
            ...DEFAULT_IMAGE_COMPONENT,
            veil: 0,
          },
          video: {
            component: "Video",
          },
        },
      },
    ],
  },
  thumbnails: {
    "1x": VerticalSlider,
    "2x": VerticalSlider2x,
  },
}
