import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { TextStep, TextStep2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Text Step",
  component: "TextStep",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          hideable: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          humanReadable: true,
          advanced: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
        },
        {
          title: "Detail",
          type: "Wysiwyg",
          key: "detail",
          hideable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "TextStep",
    image: { ...DEFAULT_IMAGE_FIELD },
    title: { content: "Title", tag: "h3" },
    detail: "<p>Lorem ipsum</p>",
    link: {
      component: "Link",
    },
  },
  thumbnails: {
    "1x": TextStep,
    "2x": TextStep2x,
  },
}
