import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Horizontal Tab Element",
  component: "HorizontalTabElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Component",
          type: "ComponentArray",
          mandatory: true,
          whiteList: [
            "BasicContent",
            "CardCollection",
            "Accordion",
            "Accordion50",
            "FeaturedAudiovisual",
            "FeaturedBlock",
            "Testimonial",
            "Slider",
            "NewsDistributor",
          ],
          key: "componentModules",
        },
      ],
    },
  ],
  default: {
    component: "HorizontalTabElement",
    title: { content: "Title", tag: "h3" },
    image: "",
    componentModules: [
      {
        component: "BasicContent",
        title: {
          content: "Default title",
          tag: "h1",
        },
        subtitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
        primaryLink: {
          component: "LinkModal",
          style: "primary",
          link: {
            text: "Link",
            linkType: "url",
            url: {
              linkToURL: null,
              newTab: false,
              noFollow: false,
            },
            modal: {
              requestInfo: {
                component: "RequestInfo",
              },
              floatingCTAMail: {
                component: "FloatingCTAMail",
              },
              floatingCTALightbox: {
                component: "FloatingCTALightbox",
              },
              floatingCTAVideo: {
                component: "FloatingCTAVideo",
              },
            },
          },
        },
        secondaryLink: {
          component: "LinkModal",
        },
        additionalContent: {
          image: {
            component: "LinkableImage",
            ...DEFAULT_IMAGE_FIELD,
            veil: 0,
          },
          video: {
            component: "Video",
          },
        },
        theme: "defaultAlt",
        layout: "L001",
        moduleOrder: null,
      },
    ],
  },
}
