import { FooterSection, FooterSection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Footer Section",
  component: "FooterSection",
  dataPacks: null,
  category: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Modules",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["Footer"],
          key: "modules",
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "Footer Section",
    modules: [],
  },
  thumbnails: {
    "1x": FooterSection,
    "2x": FooterSection2x,
  },
}
