import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Step Tab Element",
  component: "StepTabElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Basic Content",
          type: "ComponentContainer",
          key: "basicContent",
          whiteList: ["BasicContent"],
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: "StepTabElement",
    title: {
      content: "Título",
    },
    basicContent: {
      component: "BasicContent",
      title: {
        content: "BasicContent Title 1",
        tag: "h1",
      },
      subtitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id ipsum dui. Maecenas vel metus quis metus consectetur viverra bibendum eget ex. Vestibulum id ornare odio. Vestibulum sed ex aliquam, viverra nulla eget, tincidunt",
      primaryLink: {
        component: "LinkModal",
        style: "primary",
        link: {
          text: "Link",
          linkType: "url",
          url: {
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
          modal: {
            requestInfo: {
              component: "RequestInfo",
            },
            floatingCTAMail: {
              component: "FloatingCTAMail",
            },
            floatingCTALightbox: {
              component: "FloatingCTALightbox",
            },
            floatingCTAVideo: {
              component: "FloatingCTAVideo",
            },
          },
        },
      },
      secondaryLink: {
        component: "LinkModal",
      },
      additionalContent: {
        image: {
          component: "LinkableImage",
          ...DEFAULT_IMAGE_COMPONENT,
          veil: 0,
        },
        video: {
          component: "Video",
        },
      },
      theme: "defaultAlt",
      layout: "L001",
      elements: [],
      moduleOrder: null,
    },
  },
}
