import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { WebinarCollection, WebinarCollection2x } from "@thumbnails/Modules"
import { WebinarCollectionLayout1, WebinarCollectionLayout2 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Webinar Collection",
  component: "WebinarCollection",
  category: "articlesAndEvents",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          title: "Event cards",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["EventCard"],
          key: "elements",
          mandatory: true,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: WebinarCollectionLayout1,
            },
            {
              value: "L002",
              img: WebinarCollectionLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "WebinarCollection",
    theme: "defaultAlt",
    title: {
      content: "Title",
      tag: "h2",
    },
    subtitle: "Lorem Ipsum",
    elements: [
      {
        component: "EventCard",
        tag: null,
        title: {
          content: "Event Card Title",
          tag: "h4",
        },
        caption: "Caption",
        subtitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id ipsum dui. Maecenas vel metus quis metus consectetur viver",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci dapibus ultrices in iaculis nunc sed augue. Enim tortor at auctor urna nunc id. Vitae congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Quam vulputate dignissim suspendisse in. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Viverra justo nec ultrices dui. Lectus nulla at volutpat diam ut.",
        auxInfo1: "Auxiliar Information 1",
        auxInfo2: "Auxiliar Information 2",
        link: {
          component: "Link",
          text: "Registro",
          style: "primary",
          url: {
            url: "",
            newTab: false,
            noFollow: false,
            size: null,
            icon: null,
            linkContainer: null,
            linkTo: null,
          },
        },
        addToCalendar: {
          component: "AddToCalendar",
        },
        image: {
          ...DEFAULT_IMAGE_FIELD,
        },
      },
    ],
    groupingLink: {
      component: "Link",
      text: "See All Webinars",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": WebinarCollection,
    "2x": WebinarCollection2x,
  },
}
