import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Tab Slide",
  component: "TabSlide",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ComponentContainer",
          maxItems: null,
          whiteList: ["Image"],
          key: "image",
          mandatory: true,
          helptext: "Recommended minimum image size: 1496x558",
        },
        {
          title: "Tab Title",
          key: "tabTitle",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "TabSlide",
    image: {
      component: "Image",
      veil: 40,
      file: DEFAULT_IMAGE_COMPONENT.file,
    },
    tabTitle: "Lorem Ipsum",
    title: {
      content: "Title",
      tag: "h2",
    },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: {
      component: "Link",
      text: "Link",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
      style: "link",
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
