import { HeroMosaic, HeroMosaic2x } from "@thumbnails/Modules"

import HeroMosaicCard from "../components/HeroMosaicCard"

export default {
  schemaType: "module",
  displayName: "Hero Mosaic",
  component: "HeroMosaic",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Elements",
          type: "ComponentArray",
          mandatory: true,
          whiteList: ["HeroMosaicCard"],
          key: "elements",
          maxItems: 7,
          helptext: "From 4 to 7 elements",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "HeroMosaic",
    elements: [
      { ...HeroMosaicCard.default },
      { ...HeroMosaicCard.default },
      { ...HeroMosaicCard.default },
      { ...HeroMosaicCard.default },
    ],
  },
  thumbnails: {
    "1x": HeroMosaic,
    "2x": HeroMosaic2x,
  },
}
