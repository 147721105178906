import { getThemeOptions } from "@helpers/schemas"
import { LogoCollection, LogoCollection2x } from "@thumbnails/Modules"
import {
  LogoCollectionLayout1,
  LogoCollectionLayout2,
  LogoCollectionLayout3,
  LogoCollectionLayout4,
  LogoCollectionLayout5,
} from "@layouts"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "module",
  displayName: "Logo Collection",
  component: "LogoCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "LogoCards",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          key: "elements",
          whiteList: ["LogoCard"],
        },
        {
          title: "Logo Background",
          type: "RadioGroup",
          key: "logoBackground",
          options: [
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
            {
              name: "no",
              value: false,
              title: "No",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          type: "UniqueCheck",
          key: "slider",
          mandatory: true,
          options: [
            {
              title: "Visualize by Slider",
            },
          ],
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: LogoCollectionLayout1,
            },
            {
              value: "L002",
              img: LogoCollectionLayout2,
            },
            {
              value: "L003",
              img: LogoCollectionLayout3,
            },
            {
              value: "L004",
              img: LogoCollectionLayout4,
            },
            {
              value: "L005",
              img: LogoCollectionLayout5,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "LogoCollection",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    layout: "L002",
    slider: false,
    title: { content: "Title", tag: "h2" },
    subtitle: "Subtitle",
    detail: "Detail",
    elements: [
      {
        component: "LogoCard",
        image: DEFAULT_IMAGE_FIELD,
      },
      {
        component: "LogoCard",
        image: DEFAULT_IMAGE_FIELD,
      },
      {
        component: "LogoCard",
        image: DEFAULT_IMAGE_FIELD,
      },
    ],
    logoBackground: true,
    groupingLink: {
      component: "Link",
    },
  },
  thumbnails: {
    "1x": LogoCollection,
    "2x": LogoCollection2x,
  },
}
