import { allModulesWhitelist } from "@helpers/schemas"
import { Sitemap, Sitemap2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "Sitemap",
  component: "SitemapTemplate",
  dataPacks: null,
  type: { label: "Static", value: "static", special: "sitemap" },
  singleInstance: true,
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
      ],
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: 1,
      key: "mainContent",
      whiteList: ["Sitemap"],
      mandatory: true,
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],
  configTabs: [],
  default: {
    type: "template",
    templateType: "SitemapTemplate",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    mainContent: {
      component: "Section",
      name: "Sitemap",
      modules: [{ component: "Sitemap" }],
      sectionPosition: 2,
    },
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 3,
    },
  },
  thumbnails: {
    "1x": Sitemap,
    "2x": Sitemap2x,
  },
}
