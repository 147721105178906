import { getLinkStyles } from "@helpers/schemas"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import {
  themeIconEmail,
  themeIconChat,
  themeIconWhatsapp,
  themeIconPhone,
  themeIconVideo,
} from "@theme-img"

export default {
  schemaType: "component",
  displayName: "FloatingCTAElement",
  component: "FloatingCTAElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "Icon",
          type: "ConditionalField",
          key: "icon",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },

            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "Icon",
              key: "iconSelection",
              type: "VisualUniqueSelection",
              options: [
                {
                  value: "email",
                  img: themeIconEmail,
                },
                {
                  value: "chat",
                  img: themeIconChat,
                },
                {
                  value: "whatsapp",
                  img: themeIconWhatsapp,
                },
                {
                  value: "phone",
                  img: themeIconPhone,
                },
                {
                  value: "video",
                  img: themeIconVideo,
                },
              ],
              columns: 6,
            },
          ],
        },
        {
          title: "Component or Link",
          type: "ConditionalField",
          key: "compOrLinkOrMailTo",
          mandatory: true,
          required: true,
          options: [
            {
              value: "component",
              title: "Component",
              name: "Component",
            },
            {
              value: "link",
              title: "Link",
              name: "Link",
            },
            {
              value: "mailTo",
              title: "Mail To",
              name: "MailTo",
            },
          ],
          fields: [
            {
              condition: "component",
              title: "Component",
              type: "ComponentContainer",
              hideable: true,
              mandatory: true,
              whiteList: [
                "FloatingCTALightbox",
                "FloatingCTAMail",
                "FloatingCTAVideo",
                "RequestInfo",
              ],
              key: "componentModules",
            },
            {
              condition: "link",
              key: "ctaLink",
              title: "Link",
              type: "UrlField",
              mandatory: true,
              advanced: true,
            },
            {
              condition: "mailTo",
              key: "mailToMail",
              title: "E Mail",
              type: "TextField",
              placeholder: "email@email.com",
              mandatory: true,
            },
            {
              condition: "mailTo",
              key: "mailToSubject",
              title: "Subject",
              type: "TextField",
              placeholder: "Subject",
            },
          ],
        },
      ],
    },

    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "style",
          type: "VisualUniqueSelection",
          options: getLinkStyles(),
          columns: 3,
        },
      ],
    },
  ],
  default: {
    component: "FloatingCTAElement",
    title: "CTA Text",
    icon: true,
    iconSelection: "email",
    style: "primary",
    compOrLink: "component",
    componentModules: {
      floatingCTALightbox: { component: "FloatingCTALightbox" },
      floatingCTAMail: { component: "FloatingCTAMail" },
      RequestInfo: { component: "RequestInfo" },
      floatingCTAVideo: { component: "FloatingCTAVideo" },
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
