import { getThemeOptions } from "@helpers/schemas"
import { Table, Table2x } from "@thumbnails/Modules"

const defaultRow = title => ({
  component: "TableRow",
  title,
  cellType: "text",
  title2: title,
  link: {
    component: "Link",
  },
})

const defaultRowArray = numRows => {
  const rows = []
  for (let i = 0; i < numRows; i++) {
    rows.push(defaultRow(`Row ${i + 1}`))
  }
  return rows
}

export default {
  schemaType: "module",
  displayName: "Table",
  component: "Table",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Table Columns",
          type: "ComponentArray",
          maxItems: null,
          key: "columns",
          whiteList: ["TableColumn"],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "Table",
    anchorID: null,
    theme: "defaultAlt",
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    subtitle: null,
    detail: null,
    groupingLink: {
      component: "Link",
      text: "Link",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    columns: [
      {
        component: "TableColumn",
        title: "Column 1",
        rows: defaultRowArray(4),
      },
      {
        component: "TableColumn",
        title: "Column 2",
        rows: defaultRowArray(4),
      },
      {
        component: "TableColumn",
        title: "Column 3",
        rows: defaultRowArray(4),
      },
    ],
  },
  thumbnails: {
    "1x": Table,
    "2x": Table2x,
  },
}
