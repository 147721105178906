import { getThemeOptions } from "@helpers/schemas"
import { TextBluePanel, TextBluePanel2x } from "@thumbnails/Components"
import { TextBluePanelLayout1, TextBluePanelLayout2 } from "@layouts"

export default {
  schemaType: "component",
  displayName: "Blue Panel",
  component: "BluePanel",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: TextBluePanelLayout1,
            },
            {
              value: "L002",
              img: TextBluePanelLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],
  default: {
    component: "BluePanel",
    title: { content: "Title", tag: "h4" },
    layout: "L001",
    theme: "accent",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    icon: {},
  },
  thumbnails: {
    "1x": TextBluePanel,
    "2x": TextBluePanel2x,
  },
}
