import { getThemeOptions } from "@helpers/schemas"
import { Countdown, Countdown2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Countdown",
  component: "Countdown",
  category: "featured",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h3", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          key: "subtitle",
          hideable: true,
          html: true,
          humanReadable: true,
        },
        {
          key: "counterDate",
          title: "Counter date",
          type: "DateField",
          hideable: true,
        },
        {
          key: "counterHour",
          title: "Counter hour",
          type: "TimeField",
          hideable: true,
          helptext: "12 Hour format",
        },
        {
          title: "Expiration message",
          type: "RichText",
          html: true,
          key: "expirationMessage",
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "Countdown",
    anchorID: null,
    theme: "accent",

    title: { tag: "h3", content: "Title" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    expirationMessage:
      "Mensaje para establecer automáticamente cuando la fecha haya expirado.",
  },
  thumbnails: {
    "1x": Countdown,
    "2x": Countdown2x,
  },
}
