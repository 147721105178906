import { getThemeOptions } from "@helpers/schemas"
import {
  DEFAULT_IMAGE_FIELD,
  DEFAULT_IMAGE_COMPONENT,
} from "@constants/griddoImage"
import { Wysiwyg, Wysiwyg2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Wysiwyg",
  component: "Wysiwyg",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Show hero image",
          type: "ConditionalField",
          key: "showHero",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
          fields: [
            {
              title: "Image",
              type: "ImageField",
              key: "image",
              hideable: true,
              condition: true,
              helptext: "Recommended minimum image size: 2508x600",
            },
            {
              title: "Image size",
              type: "ConditionalField",
              key: "imageSize",
              condition: true,
              options: [
                {
                  value: "small",
                  title: "Small",
                  name: "small",
                },
                {
                  value: "big",
                  title: "Big",
                  name: "big",
                },
              ],
            },
            {
              title: "Veil percentage",
              type: "SliderField",
              key: "imgVeil",
              hideable: true,
              condition: true,
            },
            {
              title: "Title option",
              type: "ConditionalField",
              key: "titleOption",
              condition: true,
              options: [
                {
                  value: true,
                  title: "Yes",
                  name: "yes",
                },
                {
                  value: false,
                  title: "No",
                  name: "no",
                },
              ],
              fields: [
                {
                  title: "Title",
                  type: "TextField",
                  key: "title",
                  condition: true,
                  humanReadable: true,
                },
                {
                  title: "Subtitle",
                  type: "TextField",
                  key: "subtitle",
                  hideable: true,
                  condition: true,
                  humanReadable: true,
                },
                {
                  title: "Quote",
                  type: "TextField",
                  key: "quote",
                  condition: false,
                  humanReadable: true,
                },
              ],
            },
            {
              title: "Title option",
              type: "ConditionalField",
              key: "titleOption",
              condition: false,
              options: [
                {
                  value: true,
                  title: "Yes",
                  name: "yes",
                },
                {
                  value: false,
                  title: "No",
                  name: "no",
                },
              ],
              fields: [
                {
                  title: "Title",
                  type: "TextField",
                  key: "title",
                  condition: true,
                  humanReadable: true,
                },
                {
                  title: "Subtitle",
                  type: "TextField",
                  key: "subtitle",
                  hideable: true,
                  condition: true,
                  humanReadable: true,
                },
                {
                  title: "Title align",
                  type: "RadioGroup",
                  key: "titleAlign",
                  condition: true,
                  options: [
                    {
                      value: "left",
                      title: "Left",
                      name: "left",
                    },
                    {
                      value: "center",
                      title: "Center",
                      name: "center",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "Content title",
          type: "RichText",
          html: true,
          key: "contentTitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Introduction",
          type: "RichText",
          html: true,
          key: "introduction",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Featured visual content",
          type: "ComponentContainer",
          whiteList: ["LinkableImage", "Video"],
          key: "featuredVisualContent",
          hideable: true,
          helptext: "Recommended minimum image size: 1312x580",
        },
        {
          title: "Main content",
          type: "Wysiwyg",
          key: "content",
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "Wysiwyg",
    anchorID: null,
    theme: "default",
    showHero: true,
    image: null,
    imageSize: "small",
    titleOption: true,
    titleAlign: "left",
    title: "Lorem Ipsum",
    subtitle: "Lorem Ipsum",
    quote: "Lorem Ipsum",
    contentTitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    introduction:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    featuredVisualContent: {
      image: {
        component: "LinkableImage",
        ...DEFAULT_IMAGE_COMPONENT,
      },
      video: {
        component: "Video",
      },
    },
    content: `
<blockquote>
<p>“Lorem Ipsum”</p>
</blockquote>
<h4>Lorem Ipsum</h4>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>`,
    link: {
      component: "Link",
    },
  },
  thumbnails: {
    "1x": Wysiwyg,
    "2x": Wysiwyg2x,
  },
}
