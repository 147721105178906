export default [
  {
    key: "TopMenu",
    display: "Top Menu",
  },
  {
    key: "MainMenu",
    display: "Main Menu",
  },
  {
    key: "FooterMenu",
    display: "Footer Menu",
  },
]
