export function subThemeIsEmpty(obj) {
  return !Object.keys(obj).length
}

export function addBreakpointAlias(theme) {
  theme.mediaquery?.mediaqueries.forEach(mq => {
    theme.breakpoints[mq.label] = mq.minWidth
  })
  return { ...theme }
}

export const procUnit = (u, fn = x => x, returnJustNumber) => {
  const unitLess = parseFloat(u.match(/\d*\.?\d*/)[0])
  const unit = u.split(unitLess.toString())[1]
  const unitLessProccesed = fn(unitLess)
  return returnJustNumber ? unitLessProccesed : `${unitLessProccesed}${unit}`
}

const px2em = (pixelValue, returnJustNumber) => {
  const emValue = procUnit(pixelValue, x => x / 16, true)
  return returnJustNumber ? emValue : `${emValue}em`
}

const negativeValue = value => {
  return procUnit(value, x => -x)
}

export const fract = fraction => {
  if (!isNaN(fraction)) {
    return fraction
  }
  if (typeof fraction === "string") {
    const [a, b] = fraction.split("/").map(i => parseFloat(i.trim()))
    return a / b
  }
  return fraction
}
