import { Milestone, Milestone2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Milestone",
  component: "Milestone",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          key: "image",
          type: "ImageField",
          hideable: true,
        },
        {
          title: "Pretitle",
          key: "pretitle",
          type: "TextField",
          hideable: true,
          default: "Pretitle",
          humanReadable: true,
        },
        {
          title: "Title",
          key: "title",
          type: "HeadingField",
          hideable: true,
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Detail",
          key: "detail",
          type: "Wysiwyg",
          hideable: true,
        },
        {
          title: "Description",
          key: "description",
          type: "Wysiwyg",
          hideable: true,
        },
        {
          title: "Link",
          key: "link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          hideable: true,
        },
        {
          title: "Featured content",
          key: "featured",
          type: "ConditionalField",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "Milestone",
    pretitle: null,
    title: { tag: "h4", content: "Title" },
    detail: "<p>Lorem Ipsum</p>",
    description: "<p>Lorem Ipsum</p>",
    link: {
      component: "Link",
      text: "Link",
      style: "link",
      url: {
        href: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    featured: false,
  },
  thumbnails: {
    "1x": Milestone,
    "2x": Milestone2x,
  },
}
