import { useContext } from "react"
import { ThemeContext } from "styled-components"
import useMediaQuery from "./useMediaQuery"

const useLessThan = label => {
  const theme = useContext(ThemeContext)

  const { minWidth } = theme?.mediaquery?.mediaqueries.find(
    mq => mq.label === label
  )

  const isLessThan = useMediaQuery(`(max-width: ${minWidth})`)

  return isLessThan
}

export default useLessThan
