import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Audiovisual Card",
  component: "AudiovisualCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "URL Video",
          type: "TextField",
          key: "url",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Thumbnail",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "thumbnail",
          helptext: "Recommended minimum image size: 836x836",
        },
        {
          key: "dateTime",
          title: "Date",
          type: "DateField",
          hideable: true,
          helptext: "This date will be used to sort in distributors and lists.",
        },
      ],
    },
  ],
  default: {
    component: "AudiovisualCard",
    title: null,
    description: null,
    url: "",
    thumbnail: {
      component: "Image",
      file: DEFAULT_IMAGE_COMPONENT.file,
    },
    dateTime: null,
  },
}
