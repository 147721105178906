import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Textual Panel",
  component: "TextualPanel",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Content",
          type: "RichText",
          html: true,
          key: "content",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "backgroundImage",
          helptext: "Recommended minimum image size: 2508x1030",
        },
      ],
    },
  ],

  default: {
    component: "TextualPanel",
    title: { content: "Title", tag: "h4" },
    content: null,
    link: {
      component: "Link",
      text: "Link",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    backgroundImage: {
      component: "Image",
      veil: 50,
      file: DEFAULT_IMAGE_FIELD,
    },
    theme: "accent",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
