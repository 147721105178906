const PROJECTS = {
  title: "Projects",
  dataPacks: ["PROJECTS"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["ProjectDetail"],
    fields: [
      {
        key: "units",
        title: "Unit",
        from: "categories.units",
        type: "AsyncCheckGroup",
        source: "UNIT",
      },
      {
        key: "schools",
        title: "School",
        from: "categories.schools",
        type: "AsyncCheckGroup",
        source: "SCHOOLS",
        showList: true,
        indexable: true,
      },
      {
        key: "pathways",
        title: "Pathway",
        from: "categories.pathways",
        type: "AsyncCheckGroup",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        from: "categories.programs",
        type: "AsyncCheckGroup",
        source: "PROGRAMS",
      },
      {
        key: "sector",
        title: "Sector",
        from: "categories.sector",
        type: "AsyncCheckGroup",
        source: "SECTOR",
      },
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        from: "categories.subjectAreas",
        type: "AsyncCheckGroup",
        source: "SUBJECT_AREA",
      },
      {
        key: "regions",
        title: "Region",
        from: "categories.regions",
        type: "AsyncCheckGroup",
        source: "REGION",
      },
      {
        key: "centers",
        title: "Centers & Departments",
        from: "categories.centers",
        type: "AsyncCheckGroup",
        source: "CENTERS",
      },
      {
        key: "topics",
        title: "Topic",
        from: "categories.topics",
        type: "AsyncCheckGroup",
        source: "TOPIC",
      },
      {
        key: "title",
        title: "Title",
        type: "HeadingField",
        from: "title",
        mandatory: true,
      },
      {
        key: "image",
        title: "Image",
        type: "ImageField",
        from: "image",
        mandatory: true,
        helptext: "Shown on the distributor card",
      },
      {
        key: "program",
        title: "Program",
        type: "AsyncSelect",
        from: "program",
        source: "PROGRAMS",
        hideable: true,
      },
      {
        key: "data",
        type: "ReferenceField",
        from: "data",
        title: "Teacher (Automatic)",
        selectionType: ["manual"],
        sources: [{ structuredData: "TEACHERS" }],
        helptext: "Shown on the distributor card",
      },
      {
        key: "author",
        title: "Author",
        type: "ConditionalField",
        hideable: true,
        from: "author",
      },
      {
        key: "authorTeacherManual",
        title: "Teacher (Manual)",
        type: "TextField",
        from: "authorTeacherManual",
        helptext: "Shown on the distributor card",
      },
      {
        key: "authorStudentManual",
        title: "Student (Manual)",
        type: "TextField",
        from: "authorStudentManual",
        helptext: "Shown on the distributor card",
      },
      {
        key: "subject",
        title: "Subject",
        type: "TextField",
        from: "subject",
        hideable: true,
        humanReadable: true,
      },
      {
        key: "date",
        title: "Date",
        from: "date",
        type: "TextField",
        hideable: true,
      },
      {
        key: "dateTime",
        title: "Date",
        from: "dateTime",
        type: "DateField",
        indexable: true,
      },
      {
        key: "description",
        title: "Description",
        from: "description",
        type: "RichText",
        html: true,
        hideable: true,
        humanReadable: true,
      },
      {
        key: "tutor",
        title: "Tutor",
        type: "ConditionalField",
        hideable: true,
        from: "tutor",
      },
      {
        key: "tutorManual",
        title: "Tutor manual",
        type: "TextField",
        from: "tutorManual",
      },
      {
        key: "tutorData",
        type: "ReferenceField",
        from: "tutorData",
        title: "Tutor data",
        selectionType: ["manual"],
        sources: [{ structuredData: "TEACHERS" }],
      },
      {
        key: "projectLink",
        title: "Project Link",
        type: "ComponentContainer",
        whiteList: ["Link"],
        from: "projectLink",
      },
    ],
  },
  clone: null,
  defaultValues: null,
}

export { PROJECTS }
