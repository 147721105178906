const GRIDDO_URL = "../../images"

const FALL_BACK_IMAGE_SRC = {
  title: "404 Not Found",
  alt: "404 image",
  description: "Fallback 404 image",
  url: "https://images.qa.thesaurus.ie.edu/fallback-404-image",
}

const DEFAULT_IMAGE_FIELD = {
  title: "Oxygen",
  alt: "Oxygen image",
  url: "https://images.qa.thesaurus.ie.edu/oxygenbg_2",
}

const DEFAULT_IMAGE_COMPONENT = {
  title: "Oxygen",
  alt: "Oxygen image",
  veil: 50,
  file: { ...DEFAULT_IMAGE_FIELD },
}

export {
  DEFAULT_IMAGE_FIELD,
  DEFAULT_IMAGE_COMPONENT,
  FALL_BACK_IMAGE_SRC,
  GRIDDO_URL,
}
