import { useEffect, useState } from "react"

const mqListener = (mql, handler) => {
  mql.addEventListener("change", handler)
}

const useMediaQuery = mediaQuery => {
  const [isVerified, setIsVerified] = useState(null)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    mqListener(mediaQueryList, documentChangeHandler)

    documentChangeHandler()

    return () => {
      mqListener(mediaQueryList, documentChangeHandler)
    }
  }, [mediaQuery])

  return isVerified
}

export default useMediaQuery
