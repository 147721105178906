import { allModulesWhitelist } from "@helpers/schemas"
import { ScholarshipsList, ScholarshipsList2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "Scholarships List",
  component: "ScholarshipsList",
  dataPacks: ["SCHOLARSHIP"],
  type: { label: "Scholarship", value: "scholarship" },

  config: [],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
      mandatory: true,
    },
    {
      key: "title",
      title: "Intro Title",
      type: "TextField",
      hideable: true,
      humanReadable: true,
    },
    {
      key: "subtitle",
      title: "Subtitle",
      type: "RichText",
      html: true,
      hideable: true,
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
      humanReadable: true,
    },
    {
      key: "detail",
      title: "Detail",
      type: "RichText",
      html: true,
      hideable: true,
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Program filter",
              name: "programFilter",
            },
            {
              value: "showTypeFilter",
              title: "Show Type filter",
              name: "typeFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "typeLabel",
          title: "Type label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "SCHOLARSHIP" }],
      key: "data",
      mandatory: true,
    },
    {
      title: "Content hierarchy for SEO",
      type: "Select",
      key: "cardTitleTag",
      options: [
        { value: "h2", label: "H2" },
        { value: "h3", label: "H3" },
        { value: "h4", label: "H4" },
        { value: "span", label: "Span" },
      ],
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      mandatory: true,
      humanReadable: true,
    },
    {
      title: "Items / page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 16,
      mandatory: true,
    },
    {
      title: "Cards titles link",
      type: "RadioGroup",
      key: "titlesLink",
      mandatory: false,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],

  default: {
    type: "template",
    templateType: "ScholarshipsList",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    title: "Title",
    subtitle: null,
    detail: null,
    templateOptions: [],
    filtersType: "simple",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "SCHOLARSHIP",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      preferenceLanguage: false,
    },
    cardTitleTag: "h3",
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 16,
    titlesLink: false,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 2,
    },
  },

  thumbnails: {
    "1x": ScholarshipsList,
    "2x": ScholarshipsList2x,
  },
}
