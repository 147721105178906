import { ScrollIndicator, ScrollIndicator2x } from "@thumbnails/Modules"
import { getThemeOptions } from "@helpers/schemas"

const defCustomLink = (title, link) => ({
  component: "AnchorLink",
  url: {
    external: true,
    href: link,
    icon: "",
    label: "",
    newTab: false,
    noFollow: false,
    linkTo: null,
  },
  text: title,
})

export default {
  schemaType: "module",
  displayName: "Scroll indicator",
  component: "ScrollIndicator",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          key: "showProgressBar",
          title: "Show progress bar?",
          helptext:
            "This only affects to desktop and tablet. Mobile always show progress bar.",
          type: "RadioGroup",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          type: "ArrayFieldGroup",
          title: "Anchor links",
          key: "elements",
          name: "Anchor links",
          fields: [
            {
              title: "Text",
              type: "TextField",
              key: "text",
              required: true,
              humanReadable: true,
            },
            {
              title: "URL",
              type: "UrlField",
              key: "url",
              advanced: true,
              required: true,
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(["defaultAlt", "default"]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "ScrollIndicator",
    showProgressBar: true,
    theme: "defaultAlt",
    elements: [
      defCustomLink("Link 1", ""),
      defCustomLink("Link 2", ""),
      defCustomLink("Link 3", ""),
    ],
  },
  thumbnails: {
    "1x": ScrollIndicator,
    "2x": ScrollIndicator2x,
  },
}
