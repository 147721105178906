export const SPACING = {
  modulePadding: ["xs", "xs", "xs", "s", "s", "s", "m"],
  ModuleVerticalPadding: { default: "m", m: "l", xxl: "xl" },
  moduleVerticalSpacing: {
    small: { default: "24px", m: "32px", xxl: "56px" },
    medium: { default: "32px", m: "48px", xxl: "64px" },
    large: { default: "48px", m: "64px", xxl: "80px" },
  },
  moduleSidePadding: {
    default: "16px",
    s: "48px",
    m: "56px",
    l: "7.5vw",
    xxxl: "92px",
  },
}
