const EPICAWARDS = {
  title: "EpicAwards",
  dataPacks: ["EPICAWARDS"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["EpicAwardDetail"],
    fields: [
      {
        key: "title",
        title: "Title",
        type: "TextField",
        from: "title",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "units",
        title: "Unit",
        from: "categories.units",
        type: "AsyncCheckGroup",
        source: "UNIT",
      },
      {
        key: "schools",
        title: "Schools",
        from: "categories.schools",
        type: "AsyncCheckGroup",
        source: "SCHOOLS",
      },
      {
        key: "pathways",
        title: "Pathways",
        from: "categories.pathways",
        type: "AsyncCheckGroup",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        from: "categories.programs",
        type: "AsyncCheckGroup",
        source: "PROGRAMS",
      },
      {
        key: "awardCategory",
        title: "Award Category",
        from: "categories.awardCategory",
        type: "AsyncCheckGroup",
        source: "AWARD_CATEGORY",
      },
      {
        key: "classificationType",
        title: "Classification Type",
        from: "categories.classificationType",
        type: "AsyncCheckGroup",
        source: "CLASSIFICATION_TYPE",
        showList: true,
        indexable: true,
      },
      {
        key: "year",
        title: "Year",
        from: "categories.year",
        type: "AsyncCheckGroup",
        source: "YEAR",
      },
      {
        key: "image",
        title: "Image",
        type: "ImageField",
        from: "image",
        mandatory: true,
        helptext: "Shown on the distributor card",
      },
      {
        key: "quote",
        title: "Quote",
        type: "RichText",
        html: true,
        from: "quote",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "position",
        title: "Position",
        type: "TextField",
        from: "position",
        hideable: true,
        humanReadable: true,
      },
      {
        key: "who",
        title: "Who",
        type: "TextField",
        from: "who",
        mandatory: true,
      },
      {
        key: "classification",
        title: "Classification type",
        type: "ComponentContainer",
        from: "classification",
      },
    ],
    searchFrom: ["quote"],
  },
  clone: null,
  defaultValues: null,
}

export { EPICAWARDS }
