import { LogoCard, LogoCard2x } from "@thumbnails/Components"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Logo Card",
  component: "LogoCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "URL",
          type: "UrlField",
          advanced: true,
          key: "url",
          hideable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          mandatory: true,
          key: "image",
        },
      ],
    },
  ],
  default: {
    component: "LogoCard",
    url: {
      external: true,
      href: "",
      icon: "",
      label: "",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
  },
  thumbnails: {
    "1x": LogoCard,
    "2x": LogoCard2x,
  },
}
