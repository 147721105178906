import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import {
  RequestInfoWithModal,
  RequestInfoWithModal2x,
} from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Request Info",
  component: "RequestInfo",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Show Intro",
          type: "ConditionalField",
          key: "showIntro",
          helptext: "",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "Description",
              type: "RichText",
              html: true,
              key: "description",
              hideable: true,
              humanReadable: true,
            },
            {
              condition: true,
              title: "Picture",
              type: "ComponentContainer",
              whiteList: ["Image"],
              key: "image",
              helptext: "Recommended minimum image size: 2508x1030",
            },
            {
              condition: true,
              title: "Name",
              type: "TextField",
              key: "name",
              hideable: true,
            },
            {
              condition: true,
              title: "Role",
              type: "TextField",
              key: "role",
              hideable: true,
              humanReadable: true,
            },
          ],
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "Modal Data",
          },
        },

        {
          title: "Mode",
          type: "ConditionalField",
          key: "mode",
          helptext: "",
          options: [
            {
              name: "Meeting Link",
              value: "meetingLink",
              title: "Meeting Link",
            },
            {
              name: "Whatsapp Link",
              value: "whatsappLink",
              title: "Whatsapp Link",
            },
          ],
          fields: [
            {
              condition: "meetingLink",
              title: "Meeting Link",
              type: "TextField",
              key: "meetingLink",
              placeholder: "https://app.hubspot.com/meetings/...",
            },
            {
              condition: "whatsappLink",
              title: "Whatsapp Link Message",
              type: "TextField",
              key: "whatsappMsg",
              placeholder: "Thank you for your interest!",
            },
            {
              condition: "whatsappLink",
              title: "Whatsapp Link",
              type: "TextField",
              key: "whatsappLink",
              placeholder: "https://wa.me/+346...",
            },
          ],
        },
        {
          title: "FORM",
          type: "ComponentContainer",
          hideable: true,
          mandatory: true,
          whiteList: ["IntroFormElement"],
          key: "componentModules",
        },
      ],
    },
  ],

  default: {
    component: "RequestInfo",
    anchorID: null,
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    name: "Name",
    role: "Role",
    title: {
      content: "Título",
      tag: "h1",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
    detail:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
    showIntro: true,
    hasMeeting: true,
    hasWhatsapp: false,
    meetingLink: "",
    whatsappLink: "",
    whatsappMsg: "Thank you for your interest!",
    description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    componentModules: {
      component: "IntroFormElement",
      formTitle: "Title",
      formImage: {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
    },
  },
  thumbnails: {
    "1x": RequestInfoWithModal,
    "2x": RequestInfoWithModal2x,
  },
}
