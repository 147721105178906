import { LogoCard, LogoCard2x } from "@thumbnails/Components"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Logo",
  component: "Logo",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ImageField",
          mandatory: true,
          key: "image",
        },
      ],
    },
  ],
  default: {
    component: "Logo",
    image: DEFAULT_IMAGE_FIELD,
  },
  thumbnails: {
    "1x": LogoCard,
    "2x": LogoCard2x,
  },
}
