import { chatbotAddon } from "./src/adapter/addons/chatbot"

/**
 * @type {import("gatsby").GatsbyBrowser["onRouteUpdate"]}
 */
const onRouteUpdate = () => {
  chatbotAddon()
}

export default {
  onRouteUpdate,
}
