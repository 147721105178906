import { getThemeOptions } from "@helpers/schemas"
import Chart from "@schemas/components/Chart"
import { ChartCollection, ChartCollection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Chart Collection",
  component: "ChartCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "HeadingField",
          advanced: true,
          hideable: true,
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Elements",
          key: "elements",
          type: "ComponentArray",
          whiteList: ["Chart"],
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "ChartCollection",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    title: { content: "Title", tag: "h2" },
    elements: [
      { ...Chart.default },
      { ...Chart.default },
      { ...Chart.default },
    ],
  },
  thumbnails: {
    "1x": ChartCollection,
    "2x": ChartCollection2x,
  },
}
