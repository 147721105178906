import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { StepTabGroup, StepTabGroup2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Step Tabs",
  component: "StepTabs",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Elements",
          type: "ComponentArray",
          key: "elements",
          maxItems: 5,
          mandatory: true,
          whiteList: ["StepTabElement"],
        },
        {
          title: "Show Numbers",
          type: "RadioGroup",
          mandatory: true,
          key: "showNumbers",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [],
    },
  ],
  default: {
    component: "StepTabs",
    elements: [
      {
        component: "StepTabElement",
        title: { content: "Title" },
        basicContent: {
          component: "BasicContent",
          title: {
            content: "Default title 1",
            tag: "h1",
          },
          subtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          primaryLink: {
            component: "LinkModal",
            style: "primary",
            link: {
              text: "Link",
              linkType: "url",
              url: {
                linkToURL: null,
                newTab: false,
                noFollow: false,
              },
              modal: {
                requestInfo: {
                  component: "RequestInfo",
                },
                floatingCTAMail: {
                  component: "FloatingCTAMail",
                },
                floatingCTALightbox: {
                  component: "FloatingCTALightbox",
                },
                floatingCTAVideo: {
                  component: "FloatingCTAVideo",
                },
              },
            },
          },
          secondaryLink: {
            component: "LinkModal",
          },
          additionalContent: {
            image: {
              component: "LinkableImage",
              ...DEFAULT_IMAGE_COMPONENT,
              veil: 0,
            },
            video: {
              component: "Video",
            },
          },
          theme: "defaultAlt",
          layout: "L001",
          moduleOrder: null,
        },
      },
      {
        component: "StepTabElement",
        title: { content: "Title" },
        basicContent: {
          component: "BasicContent",
          title: {
            content: "Default title 2",
            tag: "h1",
          },
          subtitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          primaryLink: {
            component: "Link",
            text: "Link 1",
            url: {
              url: "",
              linkTo: null,
              newTab: false,
              noFollow: false,
              size: null,
              icon: null,
              linkContainer: null,
            },
            theme: "primary",
          },
          secondaryLink: {
            component: "Link",
          },
          additionalContent: {
            image: {
              component: "LinkableImage",
              ...DEFAULT_IMAGE_COMPONENT,
              veil: 0,
            },
            video: {
              component: "Video",
            },
          },
          theme: "defaultAlt",
          layout: "L001",
          moduleOrder: null,
        },
      },
    ],
    showNumbers: true,
  },
  thumbnails: {
    "1x": StepTabGroup,
    "2x": StepTabGroup2x,
  },
}
