import { getThemeOptions } from "@helpers/schemas"
import { CypherCard, CypherCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Cypher Card",
  component: "CypherCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Prefix",
          type: "TextField",
          key: "prefix",
          hideable: true,
        },
        {
          title: "Cypher",
          type: "TextField",
          key: "featuredText",
          mandatory: true,
          hideable: true,
          helptext:
            "Only numbers are allowed. This field uses the English numerical punctuation system",
        },
        {
          title: "Suffix",
          type: "TextField",
          key: "suffix",
          hideable: true,
        },
        {
          title: "Super Index",
          type: "TextField",
          key: "superIndex",
          hideable: true,
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "TextField",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Auxiliar Text",
          type: "TextField",
          key: "auxText",
          hideable: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Text size",
          type: "RadioGroup",
          key: "textSize",
          mandatory: true,
          options: [
            {
              name: "SIZE01",
              value: "display01",
              title: "Big",
            },
            {
              name: "SIZE02",
              value: "headingxl",
              title: "Medium",
            },
            {
              name: "SIZE03",
              value: "headingl",
              title: "Small",
            },
          ],
        },
        {
          type: "UniqueCheck",
          key: "showDecorations",
          options: [
            {
              title: "Show decorations",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "CypherCard",
    prefix: "",
    featuredText: "1",
    suffix: "",
    superIndex: "ST",
    title: "Ranking name",
    subtitle: "Lorem Ipsum",
    auxText: "Source",
    theme: "accent",
    textSize: "display01",
    showDecorations: true,
  },
  thumbnails: {
    "1x": CypherCard,
    "2x": CypherCard2x,
  },
}
