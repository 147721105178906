import { getLinkStyles } from "@helpers/schemas"
import {
  calendar2CTA,
  cloudDownload,
  comments,
  download,
  downloadBox,
  edit,
  envelope,
  externalLink,
  lock,
  share,
  themeIconWhatsapp,
  themeIconPhone,
} from "@theme-img"

export default {
  schemaType: "component",
  displayName: "Link Modal",
  component: "LinkModal",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          type: "LinkField",
          key: "link",
          whiteList: [
            "RequestInfo",
            "FloatingCTAMail",
            "FloatingCTALightbox",
            "FloatingCTAVideo",
          ],
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "style",
          type: "VisualUniqueSelection",
          options: getLinkStyles(),
          columns: 3,
        },
        {
          title: "Show icon",
          type: "ConditionalField",
          key: "showIcon",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Icon",
              key: "icon",
              type: "VisualUniqueSelection",
              options: [
                {
                  value: "calendar2",
                  img: calendar2CTA,
                },
                {
                  value: "downloadBox",
                  img: downloadBox,
                },
                {
                  value: "email",
                  img: envelope,
                },
                {
                  value: "cloudDownload",
                  img: cloudDownload,
                },
                {
                  value: "whatsapp",
                  img: themeIconWhatsapp,
                },
                {
                  value: "phone",
                  img: themeIconPhone,
                },
                {
                  value: "comments",
                  img: comments,
                },
                {
                  value: "aDownload",
                  img: download,
                },
                {
                  value: "edit2",
                  img: edit,
                },
                {
                  value: "externalLink",
                  img: externalLink,
                },
                {
                  value: "lock",
                  img: lock,
                },
                {
                  value: "share",
                  img: share,
                },
              ],
              columns: 6,
              condition: true,
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "LinkModal",
    style: "primary",
    showIcon: false,
    link: {
      text: "Link",
      linkType: "url",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
      modal: {
        requestInfo: {
          component: "RequestInfo",
        },
        floatingCTAMail: {
          component: "FloatingCTAMail",
        },
        floatingCTALightbox: {
          component: "FloatingCTALightbox",
        },
        floatingCTAVideo: {
          component: "FloatingCTAVideo",
        },
      },
    },
  },
}
