import { RegistrationWall } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Registration wall",
  component: "RegistrationWall",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title (Closed)",
          type: "TextField",
          key: "titleClosed",
          hideable: true,
          helptext:
            "This title will appear while the user does not submit the form.",
          humanReadable: true,
        },
        {
          title: "Title (Opened)",
          type: "TextField",
          key: "titleOpened",
          mandatory: true,
          helptext:
            "This title will appear when the user does submit the form.",
          humanReadable: true,
        },
        {
          title: "FORM",
          type: "ComponentContainer",
          mandatory: true,
          whiteList: ["IntroFormElement"],
          key: "componentModules",
        },
        {
          title: "When should registration wall be activated?",
          type: "RadioGroup",
          key: "activate",
          helptext: "",
          options: [
            {
              name: "pageLoads",
              value: "pageLoads",
              title: "When the page loads",
            },
            {
              name: "linkClicked",
              value: "linkClicked",
              title: "When a link is clicked",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "RegistrationWall",
    titleClosed: "Enter email to access the publications",
    titleOpened: "Thanks! Now you can visit the page",
    componentModules: { component: "DownloadFormComponent" },
    activate: "pageLoads",
  },
  thumbnails: {
    "1x": RegistrationWall,
    "2x": RegistrationWall,
  },
}
