import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { IconCard, IconCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Icon Card",
  component: "IconCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Icon",
          type: "ImageField",
          key: "icon",
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: "IconCard",
    title: {
      content: "Title",
      tag: "h4",
    },
    icon: {
      ...DEFAULT_IMAGE_FIELD,
    },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link: {
      component: "LinkModal",
      style: "link",
      link: {
        text: "Link",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
  },
  thumbnails: {
    "1x": IconCard,
    "2x": IconCard2x,
  },
}
