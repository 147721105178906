export const getFilterObject = (userFilters, editorFilters, filters) => {
  const presetFilters = editorFilters
  const filterArray = []
  let filtersToOmit = []
  if (userFilters && userFilters.length) {
    userFilters.forEach(userFilter => {
      Object.keys(filters).forEach(filter => {
        const filterFound = filters[filter].items.find(
          item => item.id === userFilter.id
        )

        if (filterFound) {
          filtersToOmit.push(filter)
          if (!filterArray.find(filter => filter.id === filterFound.id)) {
            filterArray.push(filterFound)
          }
        }
      })
    })
  }

  presetFilters?.length &&
    presetFilters.map(item => {
      if (!userFilters || !userFilters?.length) {
        filterArray.push(item)
      } else {
        filters &&
          Object.keys(filters).forEach(filter => {
            // si un campo para filtrar está duplicado en la lista de los filtros preset (presetFilters) y los filtros de usuario final (userFilters),
            // nos quedamos con el seleccionado por el usuario final, así que se omiten los presetFilters del mismo campo que estén en userFilters
            if (!filtersToOmit.includes(filter)) {
              const filterToAdd = filters[filter].items.find(
                el => el.id === item.id
              )
              if (filterToAdd) {
                filterArray.push(filterToAdd)
              }
            }
          })
      }
    })

  return filterArray
}
