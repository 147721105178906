import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { HeroClaim, HeroClaim2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Hero Claim",
  component: "HeroClaim",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          hideable: true,
          default: { tag: "h1", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "TextField",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          hideable: true,
          helptext: "Recommended minimum image size: 2508x1030",
        },
        {
          title: "Logos",
          key: "logos",
          type: "ComponentArray",
          maxItems: 3,
          whiteList: ["Logo"],
        },
      ],
    },
  ],

  default: {
    component: "HeroClaim",
    title: { content: "Title", tag: "h1" },
    subtitle: "Lorem Ipsum",
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
    logos: [],
  },
  thumbnails: {
    "1x": HeroClaim,
    "2x": HeroClaim2x,
  },
}
