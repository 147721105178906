import { getThemeOptions } from "@helpers/schemas"
import { DownloadCollection, DownloadCollection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Download Collection",
  component: "DownloadCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Default placeholder" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          hideable: true,
          key: "subtitle",
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          key: "elements",
          whiteList: ["DownloadDocument"],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    layout: "L001",
    theme: "defaultAlt",
    component: "DownloadCollection",
    title: { content: "Lorem Ipsum", tag: "h2" },
    subtitle: "Lorem Ipsum",
    detail: null,
    elements: [
      {
        component: "DownloadDocument",
      },
      {
        component: "DownloadDocument",
      },
    ],
  },
  thumbnails: {
    "1x": DownloadCollection,
    "2x": DownloadCollection2x,
  },
}
