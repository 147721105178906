import { allModulesWhitelist } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { EpicAwardList, EpicAwardList2x } from "@thumbnails/Templates"

//Module default
import FeaturedBlock from "@schemas/modules/FeaturedBlock"

export default {
  displayName: "Epic Award List",
  component: "EpicAwardList",
  dataPacks: ["EPICAWARDS"],
  type: { label: "EpicAwards", value: "epicawards" },
  schemaType: "template",
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
    },
    {
      title: "Content",
      type: "FieldGroup",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "titleContent",
          advanced: true,
          hideable: true,
          default: { tag: "h1", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitleContent",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detailContent",
          hideable: true,
          humanReadable: true,
        },
        {
          key: "additionalContent",
          title: "Additional Contet",
          type: "ComponentContainer",
          from: "publication",
          whiteList: ["BasicBoxedCard"],
          hideable: true,
        },
      ],
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programsFilter",
            },
            {
              value: "showAwardCategoryFilter",
              title: "Show Awards Categories filter",
              name: "awardCategoryFilter",
            },
            {
              value: "showClassificationFilter",
              title: "Show Classification Type filter",
              name: "classificationFilter",
            },
            {
              value: "showYearFilter",
              title: "Show Year filter",
              name: "yearFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "awardsCategoriesLabel",
          title: "Awards Categories label",
          type: "TextField",
        },
        {
          key: "classificationTypeLabel",
          title: "Classification Type label",
          type: "TextField",
        },
        {
          key: "yearLabel",
          title: "Year label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "EPICAWARDS" }],
      key: "data",
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 28,
      maxValue: 40,
      mandatory: true,
    },

    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],
  default: {
    type: "template",
    templateType: "EpicAwardList",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [
        {
          component: "HeroClaim",
          title: { content: "Title", tag: "h1" },
          subtitle: "Lorem Ipsum",
          image: {
            ...DEFAULT_IMAGE_FIELD,
          },
          logos: [],
        },
      ],
      sectionPosition: 1,
    },
    titleContent: { content: "Title", tag: "h2" },
    subtitleContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    detailContent: null,
    additionalContent: {
      component: "BasicBoxedCard",
      title: {
        content: "Title",
        tag: "h1",
      },
      tag: null,
      subtitle: null,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
      auxInfo: null,
      link: {
        component: "LinkModal",
        style: "primary",
        link: {
          text: "Link",
          linkType: "url",
          url: {
            url: "",
            linkTo: null,
            newTab: false,
            noFollow: false,
            size: null,
            icon: null,
            linkContainer: null,
          },
          modal: {
            requestInfo: {
              component: "RequestInfo",
            },
            floatingCTAMail: {
              component: "FloatingCTAMail",
            },
            floatingCTALightbox: {
              component: "FloatingCTALightbox",
            },
            floatingCTAVideo: {
              component: "FloatingCTAVideo",
            },
          },
        },
      },
      link2: {
        component: "LinkModal",
      },
      media: {
        image: {
          component: "LinkableImage",
        },
        video: {
          component: "Video",
        },
      },
    },
    templateOptions: [],
    filtersType: "simple",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "EPICAWARDS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 20,
      preferenceLanguage: false,
    },
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 28,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [{ ...FeaturedBlock.default }],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": EpicAwardList,
    "2x": EpicAwardList2x,
  },
}
