import { allModulesWhitelist } from "@helpers/schemas"
import { SearchResults, SearchResults2x } from "@thumbnails/Templates"

export default {
  displayName: "Search Results",
  component: "SearchResults",
  dataPacks: null,
  type: { label: "Static", value: "static" },
  singleInstance: true,
  schemaType: "template",
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
      ],
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      key: "mainContent",
      whiteList: allModulesWhitelist,
      mandatory: true,
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "SearchResults",
    theme: "defaultAlt",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [],
      sectionPosition: 2,
    },
    relatedContent: {
      component: "Section",
      name: "Closure Content",
      modules: [],
      sectionPosition: 3,
    },
  },
  thumbnails: {
    "1x": SearchResults,
    "2x": SearchResults2x,
  },
}
