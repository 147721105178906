import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { ValueCard, ValueCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Value Card",
  component: "ValueCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Video",
          type: "ComponentContainer",
          whiteList: ["Video"],
          key: "video",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "ValueCard",
    title: { content: "Title from defaults ValueCard", tag: "h4" },
    video: {
      component: "Video",
      url: "",
      thumbnail: {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
    },
  },
  thumbnails: {
    "1x": ValueCard,
    "2x": ValueCard2x,
  },
}
