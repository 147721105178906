import { FeaturedAudiovisual, FeaturedAudiovisual2x } from "@thumbnails/Modules"
import {
  FeaturedAudioLayout1,
  FeaturedAudioLayout2,
  FeaturedAudioLayout3,
  FeaturedAudioLayout4,
  FeaturedAudioLayout5,
  FeaturedAudioLayout6,
  FeaturedAudioLayout7,
  FeaturedAudioLayout8,
  FeaturedAudioLayout9,
  FeaturedAudioLayout10,
  FeaturedAudioLayout11,
  FeaturedAudioLayout12,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Featured Audiovisual",
  component: "FeaturedAudiovisual",
  category: "featured",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Elements",
          type: "ComponentArray",
          maxItems: null,
          mandatory: true,
          whiteList: ["FeaturedText", "Video", "VideoTitle", "LinkableImage"],
          key: "elements",
          helptext: "Recommended minimum image size: 2508x570",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: FeaturedAudioLayout1,
            },
            {
              value: "L002",
              img: FeaturedAudioLayout2,
            },
            {
              value: "L003",
              img: FeaturedAudioLayout3,
            },
            {
              value: "L004",
              img: FeaturedAudioLayout4,
            },
            {
              value: "L005",
              img: FeaturedAudioLayout5,
            },
            {
              value: "L006",
              img: FeaturedAudioLayout6,
            },
            {
              value: "L007",
              img: FeaturedAudioLayout7,
            },
            {
              value: "L008",
              img: FeaturedAudioLayout8,
            },
            {
              value: "L009",
              img: FeaturedAudioLayout9,
            },
            {
              value: "L010",
              img: FeaturedAudioLayout10,
            },
            {
              value: "L011",
              img: FeaturedAudioLayout11,
            },
            {
              value: "L012",
              img: FeaturedAudioLayout12,
            },
          ],
          columns: 2,
        },
        {
          title: "Auto Height",
          key: "autoHeight",
          helptext: "This option applies to layouts: 1, 2, 3, 4, 5 and 6",
          type: "RadioGroup",
          options: [
            { name: "yes", value: "true", title: "Yes" },
            { name: "no", value: "false", title: "No" },
          ],
        },
        {
          type: "UniqueCheck",
          key: "noImageOnMobile",
          options: [
            {
              title: "Do not show image on mobile",
            },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "FeaturedAudiovisual",
    layout: "L001",
    autoHeight: false,
    noImageOnMobile: false,
    elements: [
      {
        layout: "L001",
        component: "FeaturedText",
        theme: "accent",
        title: { content: "Title", tag: "h4" },
        size: "big",
        content: "Lorem Ipsum",
        link: {
          component: "Link",
          text: "Link",
          style: "primary",
          url: {
            url: "",
            linkTo: null,
            newTab: false,
            noFollow: false,
          },
        },
        backgroundImage: {
          component: "Image",
        },
      },
    ],
  },
  thumbnails: {
    "1x": FeaturedAudiovisual,
    "2x": FeaturedAudiovisual2x,
  },
}
