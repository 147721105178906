import { getThemeOptions } from "@helpers/schemas"
import { InformativeHeader, InformativeHeader2x } from "@thumbnails/Modules"

export default {
  schemaType: "modules",
  displayName: "Informative Header",
  component: "InformativeHeader",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Icon",
          type: "ImageField",
          key: "icon",
          mandatory: false,
          hideable: true,
          helptext: "You can upload an icon of at least 170x170",
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          mandatory: true,
          default: { content: "Lorem Ipsum", tag: "h2" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Content",
          type: "Wysiwyg",
          key: "content",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Additional content",
          type: "ComponentContainer",
          whiteList: ["LinkableImage", "Video"],
          key: "additionalContent",
          hideable: true,
          helptext: "Recommended minimum image size: 792x514",
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-ancho",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],
  default: {
    component: "InformativeHeader",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    icon: {},
    title: { content: "Lorem Ipsum", tag: "h2" },
    subtitle: "Lorem Ipsum",
    content: "Lorem Ipsum",
    additionalContent: {
      image: {
        component: "LinkableImage",
      },
      video: {
        component: "Video",
      },
    },
    groupingLink: {
      component: "Link",
    },
    moduleOrder: null,
  },
  thumbnails: {
    "1x": InformativeHeader,
    "2x": InformativeHeader2x,
  },
}
