import { BasicBoxedCard, BasicBoxedCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Basic Boxed Card",
  component: "BasicBoxedCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Tag",
          type: "TextField",
          key: "tag",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Auxiliar Information",
          type: "TextField",
          key: "auxInfo",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Secondary Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link2",
          hideable: true,
        },
        {
          title: "Media",
          type: "ComponentContainer",
          whiteList: ["LinkableImage", "Video"],
          key: "media",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "BasicBoxedCard",
    title: {
      content: "Title",
      tag: "h1",
    },
    tag: null,
    subtitle: "Lorem Ipsum",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    auxInfo: "Auxiliar Information",
    link: {
      component: "LinkModal",
      style: "link",
      link: {
        text: "Link",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    link2: {
      component: "LinkModal",
    },
    media: {
      image: {
        component: "LinkableImage",
      },
      video: {
        component: "Video",
      },
    },
  },
  thumbnails: {
    "1x": BasicBoxedCard,
    "2x": BasicBoxedCard2x,
  },
}
