const PUBLICATIONDETAIL = {
  title: "PublicationDetail",
  dataPacks: ["PUBLICATIONS"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["PublicationDetail"],
    fields: [
      {
        key: "publicationType",
        title: "Publication type",
        from: "categories.publicationType",
        type: "AsyncCheckGroup",
        source: "PUBLICATION_TYPE",
      },
      {
        key: "academicArea",
        title: "Academic area",
        from: "categories.academicArea",
        type: "AsyncCheckGroup",
        source: "ACADEMIC_AREA",
      },
      {
        key: "units",
        title: "Unit",
        from: "categories.units",
        type: "AsyncCheckGroup",
        source: "UNIT",
      },
      {
        key: "schools",
        title: "Schools",
        from: "categories.schools",
        type: "AsyncCheckGroup",
        source: "SCHOOLS",
      },
      {
        key: "pathways",
        title: "Pathways",
        from: "categories.pathways",
        type: "AsyncCheckGroup",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        type: "AsyncCheckGroup",
        from: "categories.programs",
        source: "PROGRAMS",
      },
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        from: "categories.subjectAreas",
        type: "AsyncCheckGroup",
        source: "SUBJECT_AREA",
        showList: true,
        indexable: true,
      },
      {
        key: "centers",
        title: "Centers & Departments",
        from: "categories.centers",
        type: "AsyncCheckGroup",
        source: "CENTERS",
      },
      {
        key: "topics",
        title: "Topic",
        from: "categories.topics",
        type: "AsyncCheckGroup",
        source: "TOPIC",
      },
      {
        key: "title",
        title: "Title",
        type: "HeadingField",
        from: "title",
        mandatory: true,
      },
      {
        key: "image",
        title: "Image",
        type: "ImageField",
        from: "image",
        hideable: true,
        helptext: "Shown on the distributor card",
      },
      {
        key: "dateTime",
        title: "Date",
        type: "DateField",
        from: "publicationDate",
        indexable: true,
        hideable: true,
      },
      {
        key: "author",
        title: "Author(s)",
        type: "TextField",
        from: "author",
        hideable: true,
      },
      {
        key: "publication",
        title: "Publication",
        type: "ComponentContainer",
        from: "publication",
        whiteList: ["Link"],
        hideable: true,
      },
      {
        key: "languageCard",
        title: "Language",
        type: "TextField",
        from: "languageCard",
        hideable: true,
      },
      {
        key: "description",
        title: "Abstract for distributor",
        type: "RichText",
        html: true,
        from: "description",
        hideable: true,
        helptext: "Shown on the distributor card",
        humanReadable: true,
      },
      {
        key: "auxInfo",
        title: "Auxiliar Information",
        type: "TextField",
        from: "auxInfo",
        hideable: true,
        humanReadable: true,
      },
    ],
    searchFrom: ["author", "description", "auxInfo", "publication"],
  },
  clone: null,
  defaultValues: null,
}

export { PUBLICATIONDETAIL }
