import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { FaqElement, FaqElement2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "FAQ Element",
  component: "FaqElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Icon",
          type: "ImageField",
          key: "icon",
          hideable: true,
        },
        {
          title: "Question",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h3", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Answer",
          type: "RichText",
          html: true,
          key: "answer",
          mandatory: true,
          helptext:
            "This text will also be displayed as Google's structured data",
          humanReadable: true,
        },
        {
          title: "Component",
          type: "ComponentArray",
          whiteList: ["BasicContent", "CardCollection", "Wysiwyg", "BasicCard"],
          key: "componentModules",
        },
      ],
    },
  ],

  default: {
    component: "FaqElement",
    icon: { ...DEFAULT_IMAGE_FIELD },
    title: { content: "Title", tag: "h3" },
    answer: "Lorem ipsum",
    componentModules: [],
  },

  thumbnails: {
    "1x": FaqElement,
    "2x": FaqElement2x,
  },
}
