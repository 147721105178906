import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Story Card",
  component: "StoryCard",
  dataPacks: null,
  configTabs: [
    { title: "content", fields: [] },
    { title: "config", fields: [] },
  ],
  default: {
    component: "StoryCard",
    image: DEFAULT_IMAGE_FIELD,
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
