import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Program Description",
  component: "ProgramDescription",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          default: { tag: "h4", content: "Element Name" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          hideable: true,
        },
        {
          title: "Quick Facts Element",
          type: "ComponentArray",
          maxItems: 6,
          whiteList: ["QuickFactElement"],
          key: "elements",
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Secondary Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link2",
          hideable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "ProgramDescription",
    title: {
      content: "Program Name",
      tag: "h4",
    },
    image: null,
    background: {
      ...DEFAULT_IMAGE_FIELD,
    },
    elements: [
      {
        component: "QuickFactElement",
        title: "Durtion",
        detail: "Lorem ipsum",
      },
      {
        component: "QuickFactElement",
        title: "Language",
        detail: "Lorem ipsum",
      },
      {
        component: "QuickFactElement",
        title: "Location",
        detail: "Lorem ipsum",
      },
      { component: "QuickFactElement", title: "Intake", detail: "Lorem ipsum" },
    ],
    link: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
        external: true,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    link2: {
      component: "Link",
    },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
