import { useContext } from "react"
import { ThemeContext } from "styled-components"
import useMediaQuery from "./useMediaQuery"

const useGreaterThan = label => {
  const theme = useContext(ThemeContext)

  const idx = theme?.mediaquery?.mediaqueries.findIndex(
    mq => mq.label === label
  )

  const { minWidth } = theme?.mediaquery?.mediaqueries[idx + 1]

  const isGreaterThan = useMediaQuery(`(min-width: ${minWidth})`)

  return isGreaterThan
}

export default useGreaterThan
