export const NEW_BG_IMAGE_RESPONSIVE = {
  fullWidth: [
    { breakpoint: "300px", width: "700" },
    { breakpoint: "350px", width: "700" },
    { breakpoint: "400px", width: "700" },
    { breakpoint: "450px", width: "700" },
    { breakpoint: "500px", width: "700" },
    { breakpoint: "550px", width: "700" },
    { breakpoint: "600px", width: "700" },
    { breakpoint: "650px", width: "700" },
    { breakpoint: "700px", width: "700" },
    { breakpoint: "750px", width: "750" },
    { breakpoint: "800px", width: "800" },
    { breakpoint: "850px", width: "850" },
    { breakpoint: "900px", width: "900" },
    { breakpoint: "1000px", width: "1000" },
    { breakpoint: "1100px", width: "1100" },
    { breakpoint: "1200px", width: "1200" },
    { breakpoint: "1300px", width: "1300" },
    { breakpoint: "1400px", width: "1400" },
    { breakpoint: "1500px", width: "1500" },
    { breakpoint: "1600px", width: "1600" },
  ],
  fullWidthAndFullHeight: {
    default: { width: "600" },
    m: { width: "1600" },
    l: { width: "2000" },
  },
  storyCard: [
    { breakpoint: "400px", width: "400" },
    { breakpoint: "450px", width: "450" },
    { breakpoint: "500px", width: "500" },
    { breakpoint: "550px", width: "550" },
    { breakpoint: "600px", width: "400" },
    { breakpoint: "650px", width: "400" },
    { breakpoint: "700px", width: "400" },
    { breakpoint: "750px", width: "400" },
    { breakpoint: "800px", width: "400" },
    { breakpoint: "850px", width: "400" },
    { breakpoint: "900px", width: "400" },
    { breakpoint: "1000px", width: "400" },
    { breakpoint: "1100px", width: "400" },
    { breakpoint: "1200px", width: "500" },
    { breakpoint: "1300px", width: "500" },
    { breakpoint: "1400px", width: "500" },
    { breakpoint: "1500px", width: "500" },
    { breakpoint: "1600px", width: "500" },
  ],
  card: {
    default: { width: "420" },
    s: { width: "420" },
    l: { width: "1216" },
  },
  heroCard: {
    default: { width: "420" },
    s: { width: "768" },
    m: { width: "1024" },
  },
  featuredProgram: {
    default: { width: "600" },
    s: { width: "768" },
    m: { width: "1024" },
    l: { width: "1216" },
    xl: { width: "1540" },
  },
  videoTitle: {
    default: { width: "600" },
    m: { width: "1024" },
  },
}

export const IMAGE_WIDTHS = [
  "50",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
  "1000",
]

export const INLINE_IMAGE_RESPONSIVE = {
  hero: [
    { breakpoint: null, width: "420" },
    { breakpoint: "420px", width: "420" },
    { breakpoint: "768px", width: "768" },
    { breakpoint: "1280px", width: "1280" },
  ],
  card: [
    { breakpoint: null, width: "420", height: "272" },
    { breakpoint: "420px", width: "420", height: "272" },
    { breakpoint: "768px", width: "420", height: "272" },
    { breakpoint: "1024px", width: "768", height: "498" },
  ],
  modal: [
    { breakpoint: null, width: "420" },
    { breakpoint: "420px", width: "420" },
    { breakpoint: "768px", width: "768" },
    { breakpoint: "1024px", width: "1024" },
    { breakpoint: "1280px", width: "1280" },
    { breakpoint: "1500px", width: "1500" },
    { breakpoint: "2000px", width: "2000" },
  ],
  iconMedium: [{ breakpoint: null, width: "96", height: "96" }],
  iconLarge: [{ breakpoint: null, width: "192", height: "192" }],
  avatar: [{ breakpoint: null, width: "192", height: "192" }],
  profile: [
    { breakpoint: null, width: "320", height: "240" },
    { breakpoint: "240px", width: "240", height: "240" },
  ],
  timeline: {
    default: { width: "288", height: "288" },
  },
  logo: [{ breakpoint: null, width: "225" }],
}

export const IMAGESIZES = {
  test: { width: 200, height: 200, crop: true },
  claustro_xl: { width: 150, height: 150, crop: true },
  descargas_l: { width: 100, height: 88, crop: true },
  destacadas_xl: { width: 1744, height: 1159, crop: true },
  distribuidoras_xl: { width: 1540, height: 576, crop: true },
  distribuidoras_l: { width: 740, height: 480, crop: true },
  distribuidoras_m: { width: 500, height: 420, crop: true },
  eventos_l: { width: 780, height: 406, crop: true },
  folletos_m: { width: 275, height: 400, crop: true },
  historias_l: { width: 734, height: 954, crop: true },
  historias_m: { width: 426, height: 554, crop: true },
  iconos_s: { width: 170, height: 170, crop: true },
  avatar: {
    width: 170,
    height: 170,
    crop: true,
    sizes: "(max-width: 170px) 100vw, 170px",
    srcSet: ["150", "160", "170"],
    heights: ["150", "160", "170"],
  },
  iconLarge: {
    width: 96,
    height: 96,
    crop: true,
    sizes: "(max-width: 960px) 100vw, 96px",
    srcSet: ["192"],
    heights: ["192"],
  },

  iconos_eventos_xs: { width: 734, height: 954, crop: true },
  imagen_y_video_xl: { width: 1550, height: 748, crop: true },
  logos_m: { width: 360, height: 9999, crop: false },
  logos_s: { width: 150, height: 9999, crop: false },
  miscelanea_xl: { width: 1744, height: 1159, crop: true },
  miscelanea_l: { width: 650, height: 650, crop: true },
  noticias_xl: { width: 1005, height: 523, crop: true },
  noticias_l: { width: 780, height: 406, crop: true },
  perfilcandidato_m: { width: 469, height: 604, crop: true },
  testimonios_xl: { width: 2000, height: 2000, crop: true },
  testimonios_s: { width: 160, height: 160, crop: true },
  usuarios_s: { width: 160, height: 160, crop: true },
  wysiwyg_xl: { width: 1005, height: 9999, crop: false },
  wysiwyg_l: { width: 470, height: 9999, crop: false },
  projects_xl: { width: 1542, height: 9999, crop: false },
  documentDownload: {
    width: 128,
    height: 185,
    crop: true,
    srcSet: ["128"],
    heights: ["185"],
  },
  profile: {
    heights: ["240", "196", "280"],
    srcSet: ["240", "196", "280"],
  },
}
