import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { Accordion, Accordion2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Accordion",
  component: "Accordion",
  dataPacks: null,
  category: "interactive",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Default placeholder" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Accordion elements",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["AccordionElement", "FaqElement"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Show images",
          type: "RadioGroup",
          key: "showImages",
          mandatory: true,
          options: [
            {
              value: "yes",
              title: "Yes",
              name: "yes",
            },
            {
              value: "no",
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Text size",
          type: "RadioGroup",
          key: "titleSize",
          mandatory: true,
          options: [
            {
              value: "s",
              title: "Small",
              name: "small",
            },
            {
              value: "m",
              title: "Large",
              name: "m",
            },
          ],
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "Accordion",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { content: "Lorem Ipsum", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    detail: null,
    titleSize: "s",
    showImages: "yes",
    elements: [
      {
        component: "AccordionElement",
        title: { content: "Title", tag: "h3" },
        icon: null,
        componentModules: [
          {
            component: "BasicContent",
            title: {
              content: "Default title",
              tag: "h1",
            },
            subtitle:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
            primaryLink: {
              component: "LinkModal",
              style: "primary",
              link: {
                text: "Link",
                linkType: "url",
                url: {
                  href: null,
                  linkToURL: null,
                  newTab: false,
                  noFollow: false,
                },
                modal: {
                  requestInfo: {
                    component: "RequestInfo",
                  },
                  floatingCTAMail: {
                    component: "FloatingCTAMail",
                  },
                  floatingCTALightbox: {
                    component: "FloatingCTALightbox",
                  },
                  floatingCTAVideo: {
                    component: "FloatingCTAVideo",
                  },
                },
              },
            },
            secondaryLink: {
              component: "LinkModal",
            },
            additionalContent: {
              image: {
                component: "LinkableImage",
                ...DEFAULT_IMAGE_COMPONENT,
                veil: 0,
              },
              video: {
                component: "Video",
              },
            },
            theme: "defaultAlt",
            layout: "L001",
            moduleOrder: null,
          },
        ],
      },
      {
        component: "AccordionElement",
        title: { content: "Title", tag: "h3" },
        icon: null,
        componentModules: [
          {
            component: "BasicContent",
            title: {
              content: "Default title",
              tag: "h1",
            },
            subtitle:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
            primaryLink: {
              component: "LinkModal",
              style: "primary",
              link: {
                text: "Link",
                linkType: "url",
                url: {
                  href: null,
                  linkToURL: null,
                  newTab: false,
                  noFollow: false,
                },
                modal: {
                  requestInfo: {
                    component: "RequestInfo",
                  },
                  floatingCTAMail: {
                    component: "FloatingCTAMail",
                  },
                  floatingCTALightbox: {
                    component: "FloatingCTALightbox",
                  },
                  floatingCTAVideo: {
                    component: "FloatingCTAVideo",
                  },
                },
              },
            },
            secondaryLink: {
              component: "LinkModal",
            },
            additionalContent: {
              image: {
                component: "LinkableImage",
                ...DEFAULT_IMAGE_COMPONENT,
                veil: 0,
              },
              video: {
                component: "Video",
              },
            },
            theme: "defaultAlt",
            layout: "L001",
            moduleOrder: null,
          },
        ],
      },
    ],
  },
  thumbnails: {
    "1x": Accordion,
    "2x": Accordion2x,
  },
}
