import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Event Card",
  component: "EventCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Tag",
          type: "TextField",
          key: "tag",
          hideable: true,
          default: "Luctus orci quam potenti mi donec fames",
          humanReadable: true,
        },
        {
          title: "Caption",
          type: "TextField",
          key: "caption",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Date",
          type: "DateField",
          mandatory: true,
          key: "dateTime",
          selectsRange: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,

          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Abstract",
          type: "RichText",
          html: true,
          key: "abstract",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Auxiliar Information 1",
          type: "TextField",
          key: "auxInfo1",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Auxiliar Information 2",
          type: "TextField",
          key: "auxInfo2",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Add to calendar button",
          type: "ComponentContainer",
          whiteList: ["AddToCalendar"],
          key: "addToCalendar",
          hideable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          helptext: "Recommended minimum image size: 748x502",
        },
      ],
    },
  ],
  default: {
    component: "EventCard",
    tag: null,
    title: {
      content: "Event Card Title",
      tag: "h4",
    },
    dateTime: "",
    caption: "Caption",
    abstract:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id ipsum dui. Maecenas vel metus quis metus consectetur viver",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci dapibus ultrices in iaculis nunc sed augue. Enim tortor at auctor urna nunc id. Vitae congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Quam vulputate dignissim suspendisse in. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Viverra justo nec ultrices dui. Lectus nulla at volutpat diam ut.",
    auxInfo1: "Auxiliar Information 1",
    auxInfo2: "Auxiliar Information 2",
    link: {
      component: "Link",
      text: "Registro",
      style: "primary",
      url: {
        url: "",
        newTab: false,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
        linkTo: null,
      },
    },
    addToCalendar: {
      component: "AddToCalendar",
    },
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
