import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import {
  calendar,
  calendar2,
  mapPoint,
  comment,
  device,
  time,
} from "@theme-img"

export default {
  schemaType: "component",
  displayName: "Quick Fact Element",
  component: "QuickFactElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          key: "detail",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Icon",
          key: "icon",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "calendar2",
              img: calendar2,
            },
            {
              value: "comment",
              img: comment,
            },
            {
              value: "mapPoint",
              img: mapPoint,
            },
            {
              value: "device",
              img: device,
            },
            {
              value: "calendar",
              img: calendar,
            },
            {
              value: "time",
              img: time,
            },
          ],
          columns: 6,
          helptext: "It will be used on some module cards",
        },
      ],
    },
    { title: "config", fields: [] },
  ],
  default: {
    component: "QuickFactElement",
    title: "Title",
    detail: "Lorem Ipsum",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
