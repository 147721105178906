import { getThemeOptions } from "@helpers/schemas"
import { EventsDistributor, EventsDistributor2x } from "@thumbnails/Modules"
import { CardCollectionLayout1, CardCollectionLayout2 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Events Distributor",
  component: "EventsDistributor",
  category: "articlesAndEvents",
  dataPacks: ["EVENTS"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    AudiovisualList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EpicAwardDetail: ["mainContent", "relatedContent"],
    EpicAwardList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramsList: ["relatedContent"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          type: "ReferenceField",
          sources: [
            { structuredData: "EVENTS" },
            { structuredData: "EVENTS_AUTO" },
          ],
          key: "data",
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
          humanReadable: true,
        },
        {
          title: "Filter by day",
          type: "ConditionalField",
          key: "filterByDay",
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Select events",
              type: "RadioGroup",
              key: "selectEvent",
              condition: true,
              mandatory: false,
              options: [
                {
                  value: "past",
                  title: "Past",
                  name: "past",
                },
                {
                  value: "futures",
                  title: "Futures",
                  name: "futures",
                },
              ],
            },
          ],
        },
        {
          title: "Link text",
          type: "TextField",
          key: "linkText",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            {
              value: "h2",
              label: "H2",
            },
            {
              value: "h3",
              label: "H3",
            },
            {
              value: "h4",
              label: "H4",
            },
            {
              value: "span",
              label: "span",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CardCollectionLayout1,
            },
            {
              value: "L002",
              img: CardCollectionLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "EventsDistributor",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    layout: "L001",
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    detail: null,
    data: {
      mode: "auto",
      order: "recent",
      sources: [
        {
          structuredData: "EVENTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "EVENTS_AUTO",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 2,
      preferenceLanguage: false,
    },
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    filterByDay: false,
    selectEvent: "futures",
    linkText: null,
    cardTitleTag: "h3",
    groupingLink: {
      component: "Link",
      text: "See All Events",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": EventsDistributor,
    "2x": EventsDistributor2x,
  },
}
