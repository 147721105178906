export default {
  schemaType: "component",
  displayName: "Multi Page Element",
  component: "MultiPageElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "Component",
          type: "ComponentArray",
          hideable: true,
          mandatory: true,
          whiteList: ["BasicContent"],
          key: "componentModules",
        },
      ],
    },

    {
      title: "Seo",
      fields: [
        {
          title: "Section Slug",
          key: "sectionSlug",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Meta title",
          key: "metaTitle",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Meta description",
          key: "metaDescription",
          type: "TextField",
          humanReadable: true,
        },
      ],
    },
  ],

  default: {
    component: "MultiPageElement",
    title: "Tab title",
    sectionSlug: "tab-01",
    metaTitle: "The meta title",
    metaDescription: "The meta description",
    componentModules: [],
  },
}
