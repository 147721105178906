import { getThemeOptions } from "@helpers/schemas"
import { FeaturedText, FeaturedText2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Featured Text",
  component: "FeaturedText",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Content",
          type: "RichText",
          html: true,
          key: "content",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "backgroundImage",
          helptext: "Recommended minimum image size: 2508x570",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
        {
          title: "Title size",
          type: "RadioGroup",
          key: "size",
          mandatory: true,
          options: [
            {
              name: "big",
              value: "big",
              title: "Big",
            },
            {
              name: "medium",
              value: "medium",
              title: "Medium",
            },
            {
              name: "small",
              value: "small",
              title: "Small",
            },
          ],
        },
        {
          title: "Text Position",
          type: "RadioGroup",
          key: "textPosition",
          mandatory: true,
          helptext:
            "This functionality doesn’t apply if you have selected “Yes” in auto height",
          options: [
            {
              name: "top",
              value: "top",
              title: "Top",
            },
            {
              name: "center",
              value: "center",
              title: "Center",
            },
            {
              name: "bottom",
              value: "bottom",
              title: "Bottom",
            },
          ],
        },
      ],
    },
  ],
  default: {
    layout: "L001",
    component: "FeaturedText",
    theme: "accent",
    title: { content: "Title", tag: "h4" },
    size: "big",
    content: "Lorem Ipsum",
    textPosition: "bottom",
    link: {
      component: "Link",
    },
    backgroundImage: {
      component: "Image",
    },
  },
  thumbnails: {
    "1x": FeaturedText,
    "2x": FeaturedText2x,
  },
}
