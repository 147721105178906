import { FALL_BACK_IMAGE_SRC } from "@constants/griddoImage"
import { Error404, Error4042x } from "@thumbnails/Templates"

export default {
  displayName: "Error 404",
  component: "Error404",
  dataPacks: null,
  type: { label: "Static", value: "static", special: "404" },
  singleInstance: true,
  schemaType: "template",
  content: [
    {
      title: "Error Title",
      type: "HeadingField",
      key: "errorTitle",
      advanced: true,
      mandatory: true,
      hideable: true,
      default: { tag: "h1", content: "Page not found" },
      options: [
        { value: "h1", label: "H1" },
        { value: "h2", label: "H2" },
        { value: "h3", label: "H3" },
        { value: "h4", label: "H4" },
        { value: "span", label: "span" },
      ],
      humanReadable: true,
    },
    {
      title: "Subtitle",
      type: "TextField",
      key: "subtitle",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Detail",
      type: "TextField",
      hideable: true,
      key: "detail",
      humanReadable: true,
    },
    {
      title: "Image",
      type: "ImageField",
      key: "image",
      hideable: true,
    },
    {
      title: "Link",
      type: "ComponentContainer",
      whiteList: ["Link"],
      mandatory: true,
      key: "link",
    },
  ],

  default: {
    type: "template",
    templateType: "Error404",
    errorTitle: { content: "Page not found", tag: "h1" },
    subtitle: "Sorry, the page you are looking for doesn’t exist.",
    detail: "Please, make sure that the address you enter is correct.",
    image: { ...FALL_BACK_IMAGE_SRC },
    theme: "defaultAlt",
    link: {
      component: "Link",
      text: "Go to the homepage",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": Error404,
    "2x": Error4042x,
  },
}
