import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Form Button",
  component: "FormButton",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Text",
          type: "TextField",
          key: "text",
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "FormButton",
    text: "Enviar",
    style: "primary",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
