const TEACHERS = {
  title: "Teachers",
  dataPacks: ["TEACHERS"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["TeacherDetail"],
    fields: [
      {
        key: "title",
        title: "Name",
        type: "TextField",
        from: "title",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "image",
        title: "Image",
        type: "ImageField",
        from: "image",
        mandatory: true,
      },
      {
        key: "imageBackground",
        title: "Background Image",
        type: "ImageField",
        from: "imageBackground",
        mandatory: true,
      },
      {
        key: "position",
        title: "Position",
        type: "TextField",
        from: "position",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "linkedin",
        title: "Linkedin",
        from: "linkedin",
        type: "UrlField",
        advanced: true,
      },
      {
        key: "units",
        title: "Unit",
        from: "categories.units",
        type: "AsyncCheckGroup",
        source: "UNIT",
        showList: true,
        indexable: true,
      },
      {
        key: "schools",
        title: "Schools",
        from: "categories.schools",
        type: "AsyncCheckGroup",
        source: "SCHOOLS",
      },
      {
        key: "pathways",
        title: "Pathways",
        from: "categories.pathways",
        type: "AsyncCheckGroup",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        from: "categories.programs",
        type: "AsyncCheckGroup",
        source: "PROGRAMS",
      },
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        from: "categories.subjectAreas",
        type: "AsyncCheckGroup",
        source: "SUBJECT_AREA",
      },
      {
        key: "genders",
        title: "Gender",
        from: "categories.genders",
        type: "AsyncCheckGroup",
        source: "GENDER",
      },
      {
        key: "regions",
        title: "Region",
        from: "categories.regions",
        type: "AsyncCheckGroup",
        source: "REGION",
      },
      {
        key: "academicArea",
        title: "Academic area",
        from: "categories.academicArea",
        type: "AsyncCheckGroup",
        source: "ACADEMIC_AREA",
      },
      {
        key: "facultyCategory",
        title: "Faculty category",
        from: "categories.facultyCategory",
        type: "AsyncCheckGroup",
        source: "FACULTY_CATEGORY",
      },
      {
        key: "topics",
        title: "Topic",
        from: "categories.topics",
        type: "AsyncCheckGroup",
        source: "TOPIC",
      },
    ],
  },
  clone: null,
  defaults: null,
}

export { TEACHERS }
