import * as React from "react"

import { getToken, getSiteID, getLang } from "@helpers"
import { usePage, useSite } from "@hooks"

const getOptions = () => {
  const lang = getLang()

  return {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      lang,
      ...getToken(),
    },
  }
}

const useSitemap = () => {
  const [state, setState] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const { apiUrl, header, footer, fullPath } = usePage()
  const { siteMetadata } = useSite()
  React.useEffect(() => {
    getSitemap()
  }, [])

  const getSitemap = async () => {
    const sitemap = {}

    sitemap["topMenu"] = [
      {
        label: siteMetadata?.title,
        children: header?.topMenu?.elements,
        url: {
          linkToURL: !!fullPath && `${fullPath.domainUrl}${fullPath.site}`,
        },
      },
    ]

    if (!siteMetadata) {
      const siteID = getSiteID()
      const siteUrl = `${apiUrl}/site/${siteID}`
      const siteRes = await fetch(siteUrl, getOptions())
      const { name } = await siteRes.json()
      sitemap["topMenu"][0].label = name
      sitemap.siteName = name
    }

    sitemap["mainMenu"] = header?.mainMenu?.elements

    const footerElements = footer?.legalMenu?.elements
    sitemap["footerMenu"] = [{ label: null, children: footerElements }]

    // Save all header and footer navigation
    sitemap.header = header
    sitemap.footer = footer

    setState(sitemap)
    setLoading(false)
  }

  return [state, loading]
}

export default useSitemap
