import { getThemeOptions } from "@helpers/schemas"
import { FullWidthForm } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Full Width Form",
  component: "FullWidthForm",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "FORM",
          type: "ComponentContainer",
          hideable: true,
          mandatory: true,
          whiteList: ["IntroFormElement"],
          key: "componentModules",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "FullWidthForm",
    title: null,
    anchorID: null,
    theme: "accent",
    subtitle: null,
    detail: null,
    componentModules: {
      component: "IntroFormElement",
      formTitle: "Title",
      hasImage: false,
    },
  },
  thumbnails: {
    "1x": FullWidthForm,
    "2x": FullWidthForm,
  },
}
