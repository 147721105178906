import { getThemeOptions } from "@helpers/schemas"
import { Sitemap, Sitemap2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Sitemap",
  component: "Sitemap",
  dataPacks: null,
  category: null,
  configTabs: [
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "Sitemap",
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": Sitemap,
    "2x": Sitemap2x,
  },
}
