export default {
  schemaType: "component",
  displayName: "File",
  component: "File",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "File",
          type: "FileField",
          key: "file",
        },
        {
          title: "Document Title",
          type: "TextField",
          key: "documentTitle",
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "File",
    file: "",
    documentTitle: "",
  },
}
