import { getThemeOptions } from "@helpers/schemas"
import { BasicContent, BasicContent2x } from "@thumbnails/Modules"
import {
  BasicContentLayout1,
  BasicContentLayout2,
  BasicContentLayout3,
  BasicContentLayout4,
  BasicContentLayout5,
  BasicContentLayout6,
  BasicContentLayout7,
} from "@layouts"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "module",
  displayName: "Basic Content",
  component: "BasicContent",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Content",
          type: "Wysiwyg",
          key: "content",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Primary link",
          type: "ComponentContainer",
          whiteList: ["LinkModal"],
          key: "primaryLink",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Secondary link",
          type: "ComponentContainer",
          whiteList: ["LinkModal"],
          key: "secondaryLink",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Visual Content",
          type: "ComponentContainer",
          whiteList: [
            "LinkableImage",
            "Video",
            "BasicCard",
            "BasicBoxedCard",
            "CTACard",
            "ImageCard",
            "IconCard",
            "BasicIconCard",
            "LogoCard",
            "AddressCard",
          ],
          key: "additionalContent",
          hideable: true,
          helptext: "Recommended minimum image size: 792x514",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: [
            {
              value: "L001",
              img: BasicContentLayout1,
            },
            {
              value: "L002",
              img: BasicContentLayout2,
            },
            {
              value: "L003",
              img: BasicContentLayout3,
            },
            {
              value: "L004",
              img: BasicContentLayout4,
            },
            {
              value: "L005",
              img: BasicContentLayout5,
            },
            {
              value: "L006",
              img: BasicContentLayout6,
            },
            {
              value: "L007",
              img: BasicContentLayout7,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "BasicContent",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    layout: "L001",
    title: { content: "Intro title", tag: "h1" },
    subtitle: "Subtitle intro",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    primaryLink: {
      component: "LinkModal",
      style: "primary",
      link: {
        text: "Link",
        linkType: "url",
        url: {
          href: null,
          linkToURL: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          requestInfo: {
            component: "RequestInfo",
          },
          floatingCTAMail: {
            component: "FloatingCTAMail",
          },
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
          floatingCTAVideo: {
            component: "FloatingCTAVideo",
          },
        },
      },
    },
    secondaryLink: {
      component: "LinkModal",
    },
    additionalContent: {
      image: {
        component: "LinkableImage",
        ...DEFAULT_IMAGE_COMPONENT,
      },
      video: {
        component: "Video",
      },
      card: {
        component: "CTACard",
      },
      basicCard: {
        component: "BasicCard",
      },
      basicBoxedCard: {
        component: "BasicBoxedCard",
      },
      imageCard: {
        component: "ImageCard",
      },
      iconCard: {
        component: "IconCard",
      },
      basicIconCard: {
        component: "BasicIconCard",
      },
      logoCard: {
        component: "LogoCard",
      },
      addressCard: {
        component: "AddressCard",
      },
    },
  },
  thumbnails: {
    "1x": BasicContent,
    "2x": BasicContent2x,
  },
}
