import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { FloatingCTA, FloatingCTA2x } from "@thumbnails/Modules"

const defCustomLink = (title, icon, mod) => ({
  component: "FloatingCTAElement",
  icon: true,
  iconSelection: icon,
  title: title,
  theme: "primary",
  componentModules: mod || {
    floatingCTALightbox: { component: "FloatingCTALightbox" },
    floatingCTAMail: { component: "FloatingCTAMail" },
    floatingCTAVideo: { component: "FloatingCTAVideo" },
    RequestInfo: { component: "RequestInfo" },
  },
})

export default {
  schemaType: "module",
  displayName: "Floating CTAs",
  component: "FloatingCTA",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Head Text",
          type: "TextField",
          key: "headText",
          humanReadable: true,
        },
        {
          title: "CTA elements",
          type: "ComponentArray",
          maxItems: 7,
          whiteList: ["FloatingCTAElement"],
          key: "elements",
          mandatory: true,
        },
        {
          title: "Reduced version icon",
          type: "ConditionalField",
          key: "iconVersion",
          mandatory: true,
          helptext: "Only for desktop.",
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },

            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "Icon",
              key: "iconSelection",
              type: "ImageField",
              mandatory: true,
            },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "FloatingCTA",
    theme: "defaultAlt",
    headText: "Contact Us",
    elements: [
      defCustomLink("Cta 1", "whatsapp"),
      defCustomLink("Cta 2", "email"),
      defCustomLink("Cta 3", "chat"),
    ],
    iconVersion: false,
    iconSelection: {
      ...DEFAULT_IMAGE_FIELD,
    },
  },
  thumbnails: {
    "1x": FloatingCTA,
    "2x": FloatingCTA2x,
  },
}
