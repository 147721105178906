import { getThemeOptions } from "@helpers/schemas"
import { StepsCollection, StepsCollection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Steps Collection",
  component: "StepsCollection",
  category: "collections",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          maxItems: 5,
          key: "elements",
          whiteList: ["StepCard"],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Title Size",
          type: "RadioGroup",
          key: "titleSize",
          helptext: "",
          options: [
            {
              name: "Text XL",
              value: "large",
              title: "Text XL",
            },
            {
              name: "Text S",
              value: "small",
              title: "Text S",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "StepsCollection",
    anchorID: null,
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    subtitle: null,
    detail: null,
    theme: "default",
    titleSize: "small",
    elements: [
      {
        component: "StepCard",
        title: {
          content: "Lorem Ipsum",
          tag: "h4",
        },
        link: {
          url: "",
          text: "",
        },
      },
      {
        component: "StepCard",
        title: {
          content: "Lorem Ipsum",
          tag: "h4",
        },
        link: {
          url: "",
          text: "",
        },
      },
      {
        component: "StepCard",
        title: {
          content: "Lorem Ipsum",
          tag: "h4",
        },
        link: {
          url: "",
          text: "",
        },
      },
    ],
    groupingLink: {
      component: "Link",
      text: "Grouping link",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": StepsCollection,
    "2x": StepsCollection2x,
  },
}
