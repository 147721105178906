import { HeroSlider, HeroSlider2x } from "@thumbnails/Modules"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "module",
  displayName: "Hero Slider",
  component: "HeroSlider",
  category: "hero",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Component",
          type: "ComponentArray",
          whiteList: ["Slide"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "HeroSlider",
    elements: [
      {
        component: "Slide",
        title: { content: "Title" },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: {
          component: "Image",
          veil: 50,
          file: DEFAULT_IMAGE_FIELD,
        },
      },
      {
        component: "Slide",
        title: { content: "Title" },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: {
          component: "Image",
          veil: 50,
          file: DEFAULT_IMAGE_FIELD,
        },
      },
    ],
  },
  thumbnails: {
    "1x": HeroSlider,
    "2x": HeroSlider2x,
  },
}
