import { getThemeOptions } from "@helpers/schemas"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "module",
  component: "Section",
  displayName: "Section",
  dataPacks: null,
  category: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Modules",
          type: "ComponentArray",
          maxItems: null,
          whiteList: [
            "CardCollection",
            "BasicContent",
            "HeroImage",
            "AddressCollection",
            "PersonDistributor",
            "EventsDistributor",
            "WebinarCollection",
            "PublicationDistributor",
            "FeaturedProgram",
          ],
          key: "modules",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "Section",
    layout: "L002",
    modules: [],
    title: {
      content: "Lorem Ipsum",
      tag: "h1",
    },
    subtitle: "Lorem Ipsum",
    content: "",
    sectionOrder: null,
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
