import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Quote",
  component: "Quote",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Quote",
          type: "RichText",
          html: true,
          key: "quote",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Name",
          type: "HeadingField",
          key: "name",
          mandatory: true,
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
        },
        {
          title: "Job Title",
          type: "RichText",
          html: true,
          key: "jobTitle",
          humanReadable: true,
        },
        {
          title: "Photo",
          type: "ImageField",
          key: "photo",
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "Quote",
    structuredData: "TESTIMONIALS",
    relatedPage: null,
    content: {
      image: {
        url: "https://images.qa.thesaurus.ie.edu/oxygenbg_2",
      },
      title: "Foo Name",
      jobTitle: "Master of the Universe",
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sem turpis, bibendum a lacus ac, tristique faucibus nisl. Vestibulum condimentum sed mi non tincidunt.",
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
