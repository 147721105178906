export default {
  schemaType: "component",
  displayName: "Scholarship Card",
  component: "ScholarshipCard",
  dataPacks: null,
  configTabs: [
    { title: "content", fields: [] },
    { title: "config", fields: [] },
  ],
  default: {
    component: "ScholarshipCard",
  },
}
