import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "module",
  displayName: "Slider Pagination",
  component: "SliderPagination",
  dataPacks: null,
  category: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          type: "ReferenceField",
          sources: [{ structuredData: "STORIES" }],
          key: "data",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "SliderPagination",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
