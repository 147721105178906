import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Download Document",
  component: "DownloadDocument",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          mandatory: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          hideable: true,
        },
        {
          title: "Document Link",
          type: "UrlField",
          key: "documentLink",
          advanced: true,
          helptext: "Add link to a pdf or any document type",
          placeholder: "Placeholder text",
        },
      ],
    },
  ],
  default: {
    component: "DownloadDocument",
    title: { content: "Title", tag: "h4" },
    image: null,
    fileType: true,
    documentLink: {
      href: null,
      linkToURL: null,
      newTab: false,
      noFollow: false,
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
