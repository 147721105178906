import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { ProfileCollection, ProfileCollection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Profile Collection",
  component: "ProfileCollection",
  category: "people",
  dataPacks: null,
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Image frame",
          type: "RadioGroup",
          key: "imageFrame",
          mandatory: true,
          options: [
            {
              value: "square",
              title: "Square",
              name: "square",
            },
            {
              value: "round",
              title: "Round",
              name: "round",
            },
          ],
        },
        {
          title: "Elements",
          type: "ComponentArray",
          key: "elements",
          maxItems: null,
          mandatory: true,
          whiteList: ["Profile"],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "ProfileCollection",
    imageFrame: "square",
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in fermentum purus.",
    elements: [
      {
        component: "Profile",
        image: { ...DEFAULT_IMAGE_FIELD },
        title: { content: "Título", tag: "h4" },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in fermentum purus.",
        link: {
          component: "Link",
        },
      },
      {
        component: "Profile",
        image: { ...DEFAULT_IMAGE_FIELD },
        title: { content: "Título", tag: "h4" },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in fermentum purus.",
        link: {
          component: "Link",
        },
      },
      {
        component: "Profile",
        image: { ...DEFAULT_IMAGE_FIELD },
        imageFrame: "square",
        title: { content: "Título", tag: "h4" },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in fermentum purus.",
        link: {
          component: "Link",
        },
      },
    ],
  },
  thumbnails: {
    "1x": ProfileCollection,
    "2x": ProfileCollection2x,
  },
}
