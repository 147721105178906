import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import {
  RequestInfoWithModal,
  RequestInfoWithModal2x,
} from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Request Info Card",
  component: "RequestInfoCard",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "image",
          mandatory: true,
          helptext: "Recommended minimum image size: 2508x1030",
        },
        {
          title: "Name",
          type: "TextField",
          key: "name",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Role",
          type: "TextField",
          key: "role",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          key: "description",
          html: true,
          hideable: true,
          validators: { maxChar: 80 },
          humanReadable: true,
          helptext: "Maximum 80 characters",
        },
        {
          title: "Show Intro",
          type: "ConditionalField",
          key: "showIntro",
          helptext:
            "If you choose 'No', the intro (image, name, role and description) will not be displayed in the modal window",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          key: "subtitle",
          html: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          key: "detail",
          html: true,
          hideable: true,
          humanReadable: true,
        },
        {
          key: "divider",
          title: "",
          type: "FieldsDivider",
          data: {
            title: "Modal Data",
          },
        },
        {
          title: "Book A Call",
          type: "ConditionalField",
          key: "hasMeeting",
          helptext: "",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "CTA Title",
              type: "TextField",
              key: "meetingLinkCTA",
              placeholder: "Book a Call",
              humanReadable: true,
            },
            {
              condition: true,
              title: "Meeting Link",
              type: "TextField",
              key: "meetingLink",
              placeholder: "https://app.hubspot.com/meetings/...",
            },
            {
              title: "FORM 1",
              condition: true,
              type: "ComponentContainer",
              hideable: true,
              mandatory: true,
              whiteList: ["IntroFormElement"],
              key: "componentModules1",
            },
          ],
        },
        {
          title: "Whatsapp",
          type: "ConditionalField",
          key: "hasWhatsapp",
          helptext: "",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "CTA Title",
              type: "TextField",
              key: "whatsappLinkCTA",
              placeholder: "Click to Whatsapp",
              humanReadable: true,
            },
            {
              condition: true,
              title: "Whatsapp Link Message",
              type: "TextField",
              key: "whatsappMsg",
              placeholder: "Thank you for your interest!",
              humanReadable: true,
            },
            {
              condition: true,
              title: "Whatsapp Link",
              type: "TextField",
              key: "whatsappLink",
              placeholder: "https://wa.me/+346...",
            },
            {
              condition: true,
              title: "FORM 2",
              type: "ComponentContainer",
              hideable: true,
              mandatory: true,
              whiteList: ["IntroFormElement"],
              key: "componentModules2",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "RequestInfoCard",
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    name: "Name",
    role: "Role",
    description: null,
    showIntro: true,
    title: {
      content: "Título",
      tag: "h1",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
    detail: null,
    hasMeeting: true,
    hasWhatsapp: false,
    meetingLink: "",
    whatsappLink: "",
    meetingLinkCTA: "Book a Call",
    whatasppLinkCTA: "Click to Whatsapp",
    whatsappMsg: "Thank you for your interest!",
    componentModules1: {
      component: "IntroFormElement",
      formTitle: "Title 1",
      hasImage: false,
      formImage: {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
    },
    componentModules2: {
      component: "IntroFormElement",
      formTitle: "Title 2",
      hasImage: false,
      formImage: {
        component: "Image",
        ...DEFAULT_IMAGE_COMPONENT,
      },
    },
  },
  thumbnails: {
    "1x": RequestInfoWithModal,
    "2x": RequestInfoWithModal2x,
  },
}
