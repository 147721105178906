import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { MasonryCollection, MasonryCollection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Masonry Collection",
  component: "MasonryCollection",
  category: "articlesAndEvents",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          key: "elements",
          whiteList: ["MasonryCard"],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "MasonryCollection",
    title: { content: "Title", tag: "h2" },
    subtitle: null,
    detail: null,
    elements: [
      {
        component: "MasonryCard",
        type: "article",
        image: {
          component: "Image",
          veil: 30,
          file: { ...DEFAULT_IMAGE_FIELD },
        },
        title: {
          content: "Title",
          tag: "h4",
        },
        category: "Lorem ipsum",
        link: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
        size: "big",
        theme: "default",
      },
      {
        component: "MasonryCard",
        type: "podcast",
        image: {
          component: "Image",
          veil: 30,
          file: { ...DEFAULT_IMAGE_FIELD },
        },
        title: {
          content: "Title",
          tag: "h4",
        },
        category: "Lorem ipsum",
        link: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
        size: "medium",
        theme: "default",
      },
      {
        component: "MasonryCard",
        type: "video",
        image: {
          component: "Image",
          veil: 30,
          file: { ...DEFAULT_IMAGE_FIELD },
        },
        title: {
          content: "Title",
          tag: "h4",
        },
        category: "Lorem ipsum",
        link: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
        size: "small",
        theme: "default",
      },
    ],
    groupingLink: {
      component: "Link",
      text: "IE insights",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    theme: "defaultAlt",
  },
  thumbnails: {
    "1x": MasonryCollection,
    "2x": MasonryCollection2x,
  },
}
