import { allModulesWhitelist } from "@helpers/schemas"
import { EventDetail, EventDetail2x } from "@thumbnails/Templates"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

// Defaults from modules
import HeroImage from "@schemas/modules/HeroImage"
import Wysiwyg from "@schemas/modules/Wysiwyg"
import FeaturedBlock from "@schemas/modules/FeaturedBlock"
import SocialShare from "@schemas/modules/SocialShare"

export default {
  schemaType: "template",
  displayName: "Event Detail",
  component: "EventDetail",
  dataPacks: ["EVENTS"],
  type: { label: "Events", value: "events", mode: "detail" },
  content: [
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      note: "Go to the Category or Global Data sections to create as many as you need.",
      elements: [
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "Schools",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathways",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "stageTargets",
          placeholder: "Stage Target",
          source: "STAGE_TARGET",
        },
        {
          key: "regions",
          placeholder: "Region",
          source: "REGION",
        },
        {
          key: "formats",
          placeholder: "Format",
          source: "FORMAT",
        },
        {
          key: "centers",
          placeholder: "Centers & Departments",
          source: "CENTERS",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
        {
          key: "eventTypes",
          placeholder: "Event Type",
          source: "EVENT_TYPE",
        },
      ],
    },
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
    },
    {
      title: "Event Data",
      type: "FieldGroup",
      key: "eventData",
      fields: [
        {
          title: "Format",
          type: "RadioGroup",
          key: "eventFormat",
          mandatory: true,
          options: [
            {
              value: "VIRTUAL",
              title: "Virtual",
              name: "VIRTUAL",
            },
            {
              value: "FACE_TO_FACE",
              title: "Face to face",
              name: "FACE_TO_FACE",
            },
            {
              value: "HYBRID",
              title: "Hybrid",
              name: "HYBRID",
            },
          ],
        },
        {
          title: "Image",
          key: "image",
          hideable: true,
          type: "ImageField",
          helptext:
            "Shown on the distributor card. Recommended minimum image size: 716x367",
        },
        {
          title: "Tag",
          key: "tag",
          hideable: true,
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Date",
          type: "DateField",
          mandatory: true,
          key: "dateTime",
          selectsRange: true,
        },
        {
          title: "Event Hour",
          key: "eventHour",
          mandatory: true,
          type: "TextField",
          placeholder: "hh:mm",
          helptext: "hh:mm",
        },
        {
          title: "Language",
          key: "language",
          mandatory: true,
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Address",
          key: "address",
          hideable: true,
          type: "TextField",
        },
        {
          title: "Country",
          key: "country",
          hideable: true,
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Distributor description",
          key: "distributorDescription",
          hideable: true,
          type: "TextField",
          helptext: "Shown on the distributor card",
          humanReadable: true,
        },
        {
          title: "Add to calendar button",
          type: "ComponentContainer",
          whiteList: ["AddToCalendar"],
          key: "addToCalendar",
          hideable: true,
        },
      ],
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      key: "mainContent",
      whiteList: allModulesWhitelist,
    },
    {
      title: "Show pagination",
      type: "RadioGroup",
      key: "showPagination",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Related Content",
      type: "ComponentArray",
      maxItems: null,
      key: "relatedContent",
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    data: {
      quantity: 1,
      mode: "navigation",
      order: "recent-asc",
    },
    type: "template",
    templateType: "EventDetail",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ ...HeroImage.default }],
      sectionPosition: 1,
    },
    eventFormat: "FACE_TO_FACE",
    image: DEFAULT_IMAGE_FIELD,
    tag: null,
    dateTime: "",
    eventHour: "",
    language: "Event language",
    address: "Lorem Ipsum",
    country: "Lorem Ipsum",
    distributorDescription: "Lorem Ipsum",
    addToCalendar: { component: "AddToCalendar" },
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [
        { ...Wysiwyg.default },
        { ...FeaturedBlock.default },
        { ...SocialShare.default },
      ],
      sectionPosition: 2,
    },

    showPagination: true,

    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 3,
    },
  },
  thumbnails: {
    "1x": EventDetail,
    "2x": EventDetail2x,
  },
}
