import { getThemeOptions } from "@helpers/schemas"
import { IntroTextCard, IntroTextCard2x } from "@thumbnails/Modules"
import {
  IntroTextCardLayout1,
  IntroTextCardLayout2,
  IntroTextCardLayout3,
  IntroTextCardLayout4,
  IntroTextCardLayout5,
  IntroTextCardLayout6,
  IntroTextCardLayout7,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Intro Text Card",
  component: "IntroTextCard",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          key: "card",
          title: "Card",
          type: "ComponentContainer",
          from: "publication",
          whiteList: ["BasicBoxedCard"],
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          mandatory: true,
          options: [
            {
              value: "L001",
              img: IntroTextCardLayout1,
            },
            {
              value: "L002",
              img: IntroTextCardLayout2,
            },
            {
              value: "L003",
              img: IntroTextCardLayout3,
            },
            {
              value: "L004",
              img: IntroTextCardLayout4,
            },
            {
              value: "L005",
              img: IntroTextCardLayout5,
            },
            {
              value: "L006",
              img: IntroTextCardLayout6,
            },
            {
              value: "L007",
              img: IntroTextCardLayout7,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Align Card and Text",
          type: "RadioGroup",
          key: "alignCardAndText",
          helptext: "",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "IntroTextCard",
    theme: "default",
    verticalSpacing: "medium",
    alignCardAndText: false,
    title: { content: "Intro title", tag: "h2" },
    subtitle: "Subtitle intro",
    detail: "Detail",
    layout: "L001",
    card: {
      component: "BasicBoxedCard",
      title: {
        content: "Title",
        tag: "h1",
      },
      tag: null,
      subtitle: "Lorem Ipsum",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
      auxInfo: "Auxiliar Information",
      link: {
        component: "LinkModal",
        style: "link",
        link: {
          text: "Link",
          linkType: "url",
          url: {
            href: null,
            linkToURL: null,
            linkTo: null,
            newTab: false,
            noFollow: false,
          },
          modal: {
            requestInfo: {
              component: "RequestInfo",
            },
            floatingCTAMail: {
              component: "FloatingCTAMail",
            },
            floatingCTALightbox: {
              component: "FloatingCTALightbox",
            },
            floatingCTAVideo: {
              component: "FloatingCTAVideo",
            },
          },
        },
      },
      link2: {
        component: "LinkModal",
      },
      media: {
        image: {
          component: "LinkableImage",
        },
        video: {
          component: "Video",
        },
      },
    },
  },
  thumbnails: {
    "1x": IntroTextCard,
    "2x": IntroTextCard2x,
  },
}
