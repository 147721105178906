import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Program Category",
  component: "ProgramCategory",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          mandatory: true,
          default: { tag: "h3", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          mandatory: true,
        },
        {
          title: "View all link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "viewAllLink",
          hideable: true,
        },
        {
          title: "Manual programs",
          type: "ComponentArray",
          key: "elements",
          maxItems: null,
          whiteList: ["ProgramDescription"],
          mandatory: true,
        },
        {
          title: "Auxiliar text",
          type: "RichText",
          html: true,
          key: "auxText",
          hideable: true,
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "ProgramCategory",
    title: {
      content: "Title",
      tag: "h3",
    },
    image: {
      ...DEFAULT_IMAGE_FIELD,
    },
    viewAllLink: { component: "Link" },
    elements: [
      {
        component: "ProgramDescription",
        title: {
          content: "Program Name",
          tag: "h4",
        },
        image: null,
        elements: [
          {
            component: "QuickFactElement",
            title: "Duration",
            detail: "Lorem ipsum",
          },
          {
            component: "QuickFactElement",
            title: "Language",
            detail: "Lorem ipsum",
          },
          {
            component: "QuickFactElement",
            title: "Location",
            detail: "Lorem ipsum",
          },
          {
            component: "QuickFactElement",
            title: "Intake",
            detail: "Lorem ipsum",
          },
        ],
        link: {
          component: "Link",
          text: "Link",
          style: "primary",
          url: {
            url: "",
            linkTo: null,
            newTab: false,
            noFollow: false,
            external: true,
            size: null,
            icon: null,
            linkContainer: null,
          },
        },
        link2: {
          component: "Link",
        },
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
      },
    ],
    auxText: null,
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
