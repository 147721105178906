import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { FinalStep, FinalStep2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Final Step",
  component: "FinalStep",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Image",
          type: "ImageField",
          key: "image",
          mandatory: true,
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          humanReadable: true,
          advanced: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
        },
      ],
    },
  ],
  default: {
    component: "FinalStep",
    image: DEFAULT_IMAGE_FIELD,
    title: { content: "Title", tag: "h3" },
  },
  thumbnails: {
    "1x": FinalStep,
    "2x": FinalStep2x,
  },
}
