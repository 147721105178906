import { getLinkStyles } from "@helpers/schemas"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Link",
  component: "Link",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Text",
          type: "TextField",
          key: "text",
          humanReadable: true,
        },
        {
          title: "URL",
          type: "UrlField",
          key: "url",
          advanced: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "style",
          type: "VisualUniqueSelection",
          options: getLinkStyles(),
          columns: 3,
        },
      ],
    },
  ],
  default: {
    component: "Link",
    text: "Link",
    url: {
      url: "",
      linkToURL: null,
      newTab: false,
      noFollow: false,
      size: null,
      icon: null,
      linkContainer: null,
    },
    style: "primary",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
