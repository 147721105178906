const GENERALINFO = {
  title: "GeneralInfo",
  dataPacks: ["GENERALINFO"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["GeneralInfoDetail"],
    fields: [
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        from: "gidCategories.subjectAreas",
        type: "AsyncCheckGroup",
        source: "SUBJECT_AREA",
      },
      {
        key: "regions",
        title: "Region",
        from: "gidCategories.regions",
        type: "AsyncCheckGroup",
        source: "REGION",
        showList: true,
        indexable: true,
      },
      {
        key: "centers",
        title: "Centers & Departments",
        from: "gidCategories.centers",
        type: "AsyncCheckGroup",
        source: "CENTERS",
      },
      {
        key: "topics",
        title: "Topic",
        from: "gidCategories.topics",
        type: "AsyncCheckGroup",
        source: "TOPIC",
      },
      {
        key: "gidName",
        title: "Name",
        type: "RichText",
        html: true,
        from: "gidName",
        mandatory: true,
        humanReadable: true,
      },
      {
        key: "gidImage",
        title: "Image",
        type: "ImageField",
        from: "gidImage",
        mandatory: true,
      },
      {
        key: "gidPagImage",
        title: "Pagination image",
        type: "ImageField",
        from: "gidPagImage",
      },
    ],
    searchFrom: ["gidName"],
  },
  clone: null,
  defaultValues: null,
}

export { GENERALINFO }
