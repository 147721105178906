import { getThemeOptions } from "@helpers/schemas"
import { FloatingCTAMail, FloatingCTAMail2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "FloatingCTA Mail",
  component: "FloatingCTAMail",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Show Intro Text",
          type: "ConditionalField",
          key: "showIntroText",
          helptext: "",
          options: [
            {
              name: "Yes",
              value: true,
              title: "Yes",
            },
            {
              name: "No",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "Title",
              type: "HeadingField",
              key: "title",
              advanced: true,
              hideable: true,
              default: { tag: "h2", content: "Title" },
              options: [
                { value: "h1", label: "H1" },
                { value: "h2", label: "H2" },
                { value: "h3", label: "H3" },
                { value: "h4", label: "H4" },
                { value: "span", label: "span" },
              ],
              humanReadable: true,
            },
            {
              condition: true,
              title: "Subtitle",
              type: "RichText",
              html: true,
              key: "subtitle",
              hideable: true,
              humanReadable: true,
            },
            {
              condition: true,
              title: "Detail",
              type: "RichText",
              html: true,
              key: "detail",
              hideable: true,
              humanReadable: true,
            },
          ],
        },
        {
          title: "Mail Box Title",
          type: "TextField",
          key: "mailTitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Mail Box Subtitle",
          type: "RichText",
          html: true,
          key: "mailSubtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Mail Address",
          type: "TextField",
          key: "mailAddress",
          placeholder: "ieschoolofAandD@ie.edu",
        },
        {
          title: "Mail Links",
          type: "CheckGroup",
          key: "mailLinks",
          options: [
            {
              value: "gmail",
              title: "Gmail",
              name: "gmail",
            },
            {
              value: "outlook",
              title: "Outlook",
              name: "outlook",
            },
            {
              value: "yahoo",
              title: "Yahoo",
              name: "yahoo",
            },
            {
              value: "default",
              title: "Your default email app",
              name: "default",
            },
            {
              value: "copy",
              title: "Copy to clipboard",
              name: "copy",
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "FloatingCTAMail",
    anchorID: null,
    theme: "defaultAlt",
    title: {
      content: "Lorem Ipsum",
      tag: "h2",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
    detail: null,
    showIntroText: true,
    mailTitle: "Mail Title",
    mailSubtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.",
    mailLinks: ",gmail,outlook,yahoo,default,copy",
    mailAddress: "Email_Address",
  },
  thumbnails: {
    "1x": FloatingCTAMail,
    "2x": FloatingCTAMail2x,
  },
}
