import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { ImageCard, ImageCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Image Card",
  component: "ImageCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Image",
          type: "ComponentContainer",
          whiteList: ["LinkableImage"],
          mandatory: true,
          key: "image",
          helptext: "Recommended minimum image size: 502x420",
        },
      ],
    },
  ],
  default: {
    component: "ImageCard",
    title: { content: "Lorem Ipsum Title", tag: "h4" },
    image: {
      component: "LinkableImage",
      ...DEFAULT_IMAGE_COMPONENT,
      veil: 0,
    },
  },
  thumbnails: {
    "1x": ImageCard,
    "2x": ImageCard2x,
  },
}
