import { Video, Video2x } from "@thumbnails/Components"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Video Text Card",
  component: "VideoTextCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "URL Video",
          type: "TextField",
          key: "url",
          mandatory: true,
        },
        {
          title: "Thumbnail",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "thumbnail",
          helptext: "Recommended minimum image size: 836x836",
        },
        {
          type: "UniqueCheck",
          key: "automaticSubtitles",
          options: [
            {
              title: "Add automatic subtitles from Youtube",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "VideoTextCard",
    title: null,
    thumbnail: {
      component: "Image",
      file: DEFAULT_IMAGE_COMPONENT.file,
    },
    url: "",
    automaticSubtitles: false,
  },
  thumbnails: {
    "1x": Video,
    "2x": Video2x,
  },
}
