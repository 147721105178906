import React from "react"
import styled, { css } from "styled-components"
import { system } from "styled-system"
import { Box } from "@sqymagma/elements"

const ADownload = React.lazy(() => import('./svg/arrows/a-download.svg' /* webpackChunkName: "icons" */))
const CloudDownload = React.lazy(() => import('./svg/arrows/cloud_download.svg' /* webpackChunkName: "icons" */))
const DownloadBox = React.lazy(() => import('./svg/arrows/download_box.svg' /* webpackChunkName: "icons" */))
const DropDown = React.lazy(() => import('./svg/arrows/drop-down.svg' /* webpackChunkName: "icons" */))
const DropUp = React.lazy(() => import('./svg/arrows/drop-up.svg' /* webpackChunkName: "icons" */))
const ExternalLink = React.lazy(() => import('./svg/arrows/external-link.svg' /* webpackChunkName: "icons" */))
const Higher = React.lazy(() => import('./svg/arrows/higher.svg' /* webpackChunkName: "icons" */))
const Left = React.lazy(() => import('./svg/arrows/left.svg' /* webpackChunkName: "icons" */))
const Less = React.lazy(() => import('./svg/arrows/less.svg' /* webpackChunkName: "icons" */))
const Right = React.lazy(() => import('./svg/arrows/right.svg' /* webpackChunkName: "icons" */))
const Attach = React.lazy(() => import('./svg/components/attach.svg' /* webpackChunkName: "icons" */))
const BotLight = React.lazy(() => import('./svg/components/bot-light.svg' /* webpackChunkName: "icons" */))
const Bot = React.lazy(() => import('./svg/components/bot.svg' /* webpackChunkName: "icons" */))
const Question = React.lazy(() => import('./svg/components/question.svg' /* webpackChunkName: "icons" */))
const Quote = React.lazy(() => import('./svg/components/quote.svg' /* webpackChunkName: "icons" */))
const Add1 = React.lazy(() => import('./svg/general/add-1.svg' /* webpackChunkName: "icons" */))
const Add = React.lazy(() => import('./svg/general/add.svg' /* webpackChunkName: "icons" */))
const AdvancedSearch = React.lazy(() => import('./svg/general/advancedSearch.svg' /* webpackChunkName: "icons" */))
const Award = React.lazy(() => import('./svg/general/award.svg' /* webpackChunkName: "icons" */))
const Calendar = React.lazy(() => import('./svg/general/calendar.svg' /* webpackChunkName: "icons" */))
const Calendar2 = React.lazy(() => import('./svg/general/calendar2.svg' /* webpackChunkName: "icons" */))
const Case2 = React.lazy(() => import('./svg/general/case2.svg' /* webpackChunkName: "icons" */))
const Close = React.lazy(() => import('./svg/general/close.svg' /* webpackChunkName: "icons" */))
const Comment = React.lazy(() => import('./svg/general/comment.svg' /* webpackChunkName: "icons" */))
const Comments = React.lazy(() => import('./svg/general/comments.svg' /* webpackChunkName: "icons" */))
const Device = React.lazy(() => import('./svg/general/device.svg' /* webpackChunkName: "icons" */))
const Document = React.lazy(() => import('./svg/general/document.svg' /* webpackChunkName: "icons" */))
const Documents = React.lazy(() => import('./svg/general/documents.svg' /* webpackChunkName: "icons" */))
const Edit2 = React.lazy(() => import('./svg/general/edit2.svg' /* webpackChunkName: "icons" */))
const Fix = React.lazy(() => import('./svg/general/fix.svg' /* webpackChunkName: "icons" */))
const Fixed = React.lazy(() => import('./svg/general/fixed.svg' /* webpackChunkName: "icons" */))
const Info = React.lazy(() => import('./svg/general/info.svg' /* webpackChunkName: "icons" */))
const List2 = React.lazy(() => import('./svg/general/list2.svg' /* webpackChunkName: "icons" */))
const Lock = React.lazy(() => import('./svg/general/lock.svg' /* webpackChunkName: "icons" */))
const Mailbox = React.lazy(() => import('./svg/general/mailbox.svg' /* webpackChunkName: "icons" */))
const MapPoint = React.lazy(() => import('./svg/general/mapPoint.svg' /* webpackChunkName: "icons" */))
const Microphone = React.lazy(() => import('./svg/general/microphone.svg' /* webpackChunkName: "icons" */))
const NotAdd = React.lazy(() => import('./svg/general/notAdd.svg' /* webpackChunkName: "icons" */))
const Ok = React.lazy(() => import('./svg/general/ok.svg' /* webpackChunkName: "icons" */))
const Phone = React.lazy(() => import('./svg/general/phone.svg' /* webpackChunkName: "icons" */))
const ProgramFavorite = React.lazy(() => import('./svg/general/program-favorite.svg' /* webpackChunkName: "icons" */))
const RadioCheck2 = React.lazy(() => import('./svg/general/radio-check2.svg' /* webpackChunkName: "icons" */))
const Remove = React.lazy(() => import('./svg/general/remove.svg' /* webpackChunkName: "icons" */))
const Search = React.lazy(() => import('./svg/general/search.svg' /* webpackChunkName: "icons" */))
const Search2 = React.lazy(() => import('./svg/general/search2.svg' /* webpackChunkName: "icons" */))
const Shape = React.lazy(() => import('./svg/general/shape.svg' /* webpackChunkName: "icons" */))
const Share = React.lazy(() => import('./svg/general/share.svg' /* webpackChunkName: "icons" */))
const Shield = React.lazy(() => import('./svg/general/shield.svg' /* webpackChunkName: "icons" */))
const Student = React.lazy(() => import('./svg/general/student.svg' /* webpackChunkName: "icons" */))
const Time = React.lazy(() => import('./svg/general/time.svg' /* webpackChunkName: "icons" */))
const Trash = React.lazy(() => import('./svg/general/trash.svg' /* webpackChunkName: "icons" */))
const User = React.lazy(() => import('./svg/general/user.svg' /* webpackChunkName: "icons" */))
const View = React.lazy(() => import('./svg/general/view.svg' /* webpackChunkName: "icons" */))
const BackArrowSlim = React.lazy(() => import('./svg/initials/back-arrow-slim.svg' /* webpackChunkName: "icons" */))
const BackArrow = React.lazy(() => import('./svg/initials/back-arrow.svg' /* webpackChunkName: "icons" */))
const CloseMenu = React.lazy(() => import('./svg/initials/close-menu.svg' /* webpackChunkName: "icons" */))
const DownArrow = React.lazy(() => import('./svg/initials/down-arrow.svg' /* webpackChunkName: "icons" */))
const Email = React.lazy(() => import('./svg/initials/email.svg' /* webpackChunkName: "icons" */))
const OpenMenuFit = React.lazy(() => import('./svg/initials/open-menu-fit.svg' /* webpackChunkName: "icons" */))
const OpenMenu = React.lazy(() => import('./svg/initials/open-menu.svg' /* webpackChunkName: "icons" */))
const PauseSimple = React.lazy(() => import('./svg/initials/pause-simple.svg' /* webpackChunkName: "icons" */))
const Pause = React.lazy(() => import('./svg/initials/pause.svg' /* webpackChunkName: "icons" */))
const PlaySimple = React.lazy(() => import('./svg/initials/play-simple.svg' /* webpackChunkName: "icons" */))
const Play = React.lazy(() => import('./svg/initials/play.svg' /* webpackChunkName: "icons" */))
const ArchitectureFooter = React.lazy(() => import('./svg/logos/architecture-footer.svg' /* webpackChunkName: "architecturefooter" */))
const Architecture = React.lazy(() => import('./svg/logos/architecture.svg' /* webpackChunkName: "logos" */))
const BusinessFooter = React.lazy(() => import('./svg/logos/business-footer.svg' /* webpackChunkName: "businessfooter" */))
const Business = React.lazy(() => import('./svg/logos/business.svg' /* webpackChunkName: "logos" */))
const HumanitiesFooter = React.lazy(() => import('./svg/logos/humanities-footer.svg' /* webpackChunkName: "humanitiesfooter" */))
const Humanities = React.lazy(() => import('./svg/logos/humanities.svg' /* webpackChunkName: "logos" */))
const LawFooter = React.lazy(() => import('./svg/logos/law-footer.svg' /* webpackChunkName: "lawfooter" */))
const Law = React.lazy(() => import('./svg/logos/law.svg' /* webpackChunkName: "logos" */))
const LifelonglearningFooter = React.lazy(() => import('./svg/logos/lifelonglearning-footer.svg' /* webpackChunkName: "lifelonglearningfooter" */))
const LifelonglearningMobile = React.lazy(() => import('./svg/logos/lifelonglearning-mobile.svg' /* webpackChunkName: "lifelonglearningmobile" */))
const Lifelonglearning = React.lazy(() => import('./svg/logos/lifelonglearning.svg' /* webpackChunkName: "logos" */))
const LogoAlumniFooter = React.lazy(() => import('./svg/logos/logo-alumni-footer.svg' /* webpackChunkName: "logoalumni" */))
const LogoAlumniLight = React.lazy(() => import('./svg/logos/logo-alumni-light.svg' /* webpackChunkName: "logoalumni" */))
const LogoAlumni = React.lazy(() => import('./svg/logos/logo-alumni.svg' /* webpackChunkName: "logoalumni" */))
const LogoBsFooter = React.lazy(() => import('./svg/logos/logo-bs-footer.svg' /* webpackChunkName: "logobs" */))
const LogoBsLight = React.lazy(() => import('./svg/logos/logo-bs-light.svg' /* webpackChunkName: "logobs" */))
const LogoBsMobile = React.lazy(() => import('./svg/logos/logo-bs-mobile.svg' /* webpackChunkName: "logobs" */))
const LogoBs = React.lazy(() => import('./svg/logos/logo-bs.svg' /* webpackChunkName: "logobs" */))
const LogoCcltmFooter = React.lazy(() => import('./svg/logos/logo-ccltm-footer.svg' /* webpackChunkName: "logoccltm" */))
const LogoCcltmLight = React.lazy(() => import('./svg/logos/logo-ccltm-light.svg' /* webpackChunkName: "logoccltm" */))
const LogoCcltm = React.lazy(() => import('./svg/logos/logo-ccltm.svg' /* webpackChunkName: "logoccltm" */))
const LogoCgcFooter = React.lazy(() => import('./svg/logos/logo-cgc-footer.svg' /* webpackChunkName: "logocgc" */))
const LogoCgcLight = React.lazy(() => import('./svg/logos/logo-cgc-light.svg' /* webpackChunkName: "logocgc" */))
const LogoCgc = React.lazy(() => import('./svg/logos/logo-cgc.svg' /* webpackChunkName: "logocgc" */))
const LogoCorpFooter = React.lazy(() => import('./svg/logos/logo-corp-footer.svg' /* webpackChunkName: "logocorp" */))
const LogoCorpLight = React.lazy(() => import('./svg/logos/logo-corp-light.svg' /* webpackChunkName: "logocorp" */))
const LogoCorpMobile = React.lazy(() => import('./svg/logos/logo-corp-mobile.svg' /* webpackChunkName: "logocorp" */))
const LogoCorp = React.lazy(() => import('./svg/logos/logo-corp.svg' /* webpackChunkName: "logocorp" */))
const LogoCrFooter = React.lazy(() => import('./svg/logos/logo-cr-footer.svg' /* webpackChunkName: "logocr" */))
const LogoCrLight = React.lazy(() => import('./svg/logos/logo-cr-light.svg' /* webpackChunkName: "logocr" */))
const LogoCr = React.lazy(() => import('./svg/logos/logo-cr.svg' /* webpackChunkName: "logocr" */))
const LogoEntrepreneurshipFooter = React.lazy(() => import('./svg/logos/logo-entrepreneurship-footer.svg' /* webpackChunkName: "logoentrepreneurship" */))
const LogoEntrepreneurshipLight = React.lazy(() => import('./svg/logos/logo-entrepreneurship-light.svg' /* webpackChunkName: "logoentrepreneurship" */))
const LogoEntrepreneurship = React.lazy(() => import('./svg/logos/logo-entrepreneurship.svg' /* webpackChunkName: "logoentrepreneurship" */))
const LogoFaidFooter = React.lazy(() => import('./svg/logos/logo-faid-footer.svg' /* webpackChunkName: "logofaid" */))
const LogoFaidLight = React.lazy(() => import('./svg/logos/logo-faid-light.svg' /* webpackChunkName: "logofaid" */))
const LogoFaid = React.lazy(() => import('./svg/logos/logo-faid.svg' /* webpackChunkName: "logofaid" */))
const LogoFoundFooter = React.lazy(() => import('./svg/logos/logo-found-footer.svg' /* webpackChunkName: "logofound" */))
const LogoFoundLight = React.lazy(() => import('./svg/logos/logo-found-light.svg' /* webpackChunkName: "logofound" */))
const LogoFound = React.lazy(() => import('./svg/logos/logo-found.svg' /* webpackChunkName: "logofound" */))
const LogoHumanitiesFooter = React.lazy(() => import('./svg/logos/logo-humanities-footer.svg' /* webpackChunkName: "logohumanities" */))
const LogoHumanitiesLight = React.lazy(() => import('./svg/logos/logo-humanities-light.svg' /* webpackChunkName: "logohumanities" */))
const LogoHumanitiesMobile = React.lazy(() => import('./svg/logos/logo-humanities-mobile.svg' /* webpackChunkName: "logohumanities" */))
const LogoHumanities = React.lazy(() => import('./svg/logos/logo-humanities.svg' /* webpackChunkName: "logohumanities" */))
const LogoLeadershipFooter = React.lazy(() => import('./svg/logos/logo-leadership-footer.svg' /* webpackChunkName: "logoleadership" */))
const LogoLeadershipLight = React.lazy(() => import('./svg/logos/logo-leadership-light.svg' /* webpackChunkName: "logoleadership" */))
const LogoLeadership = React.lazy(() => import('./svg/logos/logo-leadership.svg' /* webpackChunkName: "logoleadership" */))
const LogoLlFooter = React.lazy(() => import('./svg/logos/logo-ll-footer.svg' /* webpackChunkName: "logoll" */))
const LogoLlLight = React.lazy(() => import('./svg/logos/logo-ll-light.svg' /* webpackChunkName: "logoll" */))
const LogoLl = React.lazy(() => import('./svg/logos/logo-ll.svg' /* webpackChunkName: "logoll" */))
const LogoLsFooter = React.lazy(() => import('./svg/logos/logo-ls-footer.svg' /* webpackChunkName: "logols" */))
const LogoLsLight = React.lazy(() => import('./svg/logos/logo-ls-light.svg' /* webpackChunkName: "logols" */))
const LogoLsMobile = React.lazy(() => import('./svg/logos/logo-ls-mobile.svg' /* webpackChunkName: "logols" */))
const LogoLs = React.lazy(() => import('./svg/logos/logo-ls.svg' /* webpackChunkName: "logols" */))
const LogoNycLight = React.lazy(() => import('./svg/logos/logo-nyc-light.svg' /* webpackChunkName: "logonyc" */))
const LogoNyc = React.lazy(() => import('./svg/logos/logo-nyc.svg' /* webpackChunkName: "logonyc" */))
const LogoSoadFooter = React.lazy(() => import('./svg/logos/logo-soad-footer.svg' /* webpackChunkName: "logosoad" */))
const LogoSoadLight = React.lazy(() => import('./svg/logos/logo-soad-light.svg' /* webpackChunkName: "logosoad" */))
const LogoSoadMobile = React.lazy(() => import('./svg/logos/logo-soad-mobile.svg' /* webpackChunkName: "logosoad" */))
const LogoSoad = React.lazy(() => import('./svg/logos/logo-soad.svg' /* webpackChunkName: "logosoad" */))
const LogoSogpFooter = React.lazy(() => import('./svg/logos/logo-sogp-footer.svg' /* webpackChunkName: "logosogp" */))
const LogoSogpLight = React.lazy(() => import('./svg/logos/logo-sogp-light.svg' /* webpackChunkName: "logosogp" */))
const LogoSogpMobile = React.lazy(() => import('./svg/logos/logo-sogp-mobile.svg' /* webpackChunkName: "logosogp" */))
const LogoSogp = React.lazy(() => import('./svg/logos/logo-sogp.svg' /* webpackChunkName: "logosogp" */))
const LogoSohstFooter = React.lazy(() => import('./svg/logos/logo-sohst-footer.svg' /* webpackChunkName: "logosohst" */))
const LogoSohstLight = React.lazy(() => import('./svg/logos/logo-sohst-light.svg' /* webpackChunkName: "logosohst" */))
const LogoSohstMobile = React.lazy(() => import('./svg/logos/logo-sohst-mobile.svg' /* webpackChunkName: "logosohst" */))
const LogoSohst = React.lazy(() => import('./svg/logos/logo-sohst.svg' /* webpackChunkName: "logosohst" */))
const LogoSpegaFooter = React.lazy(() => import('./svg/logos/logo-spega-footer.svg' /* webpackChunkName: "logospega" */))
const LogoSpegaLight = React.lazy(() => import('./svg/logos/logo-spega-light.svg' /* webpackChunkName: "logospega" */))
const LogoSpegaMobile = React.lazy(() => import('./svg/logos/logo-spega-mobile.svg' /* webpackChunkName: "logospega" */))
const LogoSpega = React.lazy(() => import('./svg/logos/logo-spega.svg' /* webpackChunkName: "logospega" */))
const LogoTcFooter = React.lazy(() => import('./svg/logos/logo-tc-footer.svg' /* webpackChunkName: "logotc" */))
const LogoTcLight = React.lazy(() => import('./svg/logos/logo-tc-light.svg' /* webpackChunkName: "logotc" */))
const LogoTc = React.lazy(() => import('./svg/logos/logo-tc.svg' /* webpackChunkName: "logotc" */))
const LogoUFooter = React.lazy(() => import('./svg/logos/logo-u-footer.svg' /* webpackChunkName: "logou" */))
const LogoULight = React.lazy(() => import('./svg/logos/logo-u-light.svg' /* webpackChunkName: "logou" */))
const LogoUMobile = React.lazy(() => import('./svg/logos/logo-u-mobile.svg' /* webpackChunkName: "logou" */))
const LogoU = React.lazy(() => import('./svg/logos/logo-u.svg' /* webpackChunkName: "logou" */))
const ScienceFooter = React.lazy(() => import('./svg/logos/science-footer.svg' /* webpackChunkName: "sciencefooter" */))
const Science = React.lazy(() => import('./svg/logos/science.svg' /* webpackChunkName: "logos" */))
const SpegaFooter = React.lazy(() => import('./svg/logos/spega-footer.svg' /* webpackChunkName: "spegafooter" */))
const Spega = React.lazy(() => import('./svg/logos/spega.svg' /* webpackChunkName: "logos" */))
const UniversityFooter = React.lazy(() => import('./svg/logos/university-footer.svg' /* webpackChunkName: "universityfooter" */))
const University = React.lazy(() => import('./svg/logos/university.svg' /* webpackChunkName: "logos" */))
const Facebook = React.lazy(() => import('./svg/social-media/facebook.svg' /* webpackChunkName: "socialMedia" */))
const Flickr = React.lazy(() => import('./svg/social-media/flickr.svg' /* webpackChunkName: "socialMedia" */))
const Instagram = React.lazy(() => import('./svg/social-media/instagram.svg' /* webpackChunkName: "socialMedia" */))
const Linkedin = React.lazy(() => import('./svg/social-media/linkedin.svg' /* webpackChunkName: "socialMedia" */))
const Podcast = React.lazy(() => import('./svg/social-media/podcast.svg' /* webpackChunkName: "socialMedia" */))
const Snapchat = React.lazy(() => import('./svg/social-media/snapchat.svg' /* webpackChunkName: "socialMedia" */))
const Tiktok = React.lazy(() => import('./svg/social-media/tiktok.svg' /* webpackChunkName: "socialMedia" */))
const Twitter = React.lazy(() => import('./svg/social-media/twitter.svg' /* webpackChunkName: "socialMedia" */))
const Whatsapp = React.lazy(() => import('./svg/social-media/whatsapp.svg' /* webpackChunkName: "socialMedia" */))
const Youtube = React.lazy(() => import('./svg/social-media/youtube.svg' /* webpackChunkName: "socialMedia" */))

const filterProps = ["width", "height", "fill", "color", "bg", "display"]

const Base = css`
  * {
    ${system({
      fill: {
        property: "fill",
        scale: "colors",
      },
    })}
  }
`

const BaseADownload = styled(Box)
  .attrs(p => ({ as: ADownload }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCloudDownload = styled(Box)
  .attrs(p => ({ as: CloudDownload }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDownloadBox = styled(Box)
  .attrs(p => ({ as: DownloadBox }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDropDown = styled(Box)
  .attrs(p => ({ as: DropDown }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDropUp = styled(Box)
  .attrs(p => ({ as: DropUp }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseExternalLink = styled(Box)
  .attrs(p => ({ as: ExternalLink }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseHigher = styled(Box)
  .attrs(p => ({ as: Higher }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLeft = styled(Box)
  .attrs(p => ({ as: Left }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLess = styled(Box)
  .attrs(p => ({ as: Less }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseRight = styled(Box)
  .attrs(p => ({ as: Right }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseAttach = styled(Box)
  .attrs(p => ({ as: Attach }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseBotLight = styled(Box)
  .attrs(p => ({ as: BotLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseBot = styled(Box)
  .attrs(p => ({ as: Bot }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseQuestion = styled(Box)
  .attrs(p => ({ as: Question }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseQuote = styled(Box)
  .attrs(p => ({ as: Quote }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseAdd1 = styled(Box)
  .attrs(p => ({ as: Add1 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseAdd = styled(Box)
  .attrs(p => ({ as: Add }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseAdvancedSearch = styled(Box)
  .attrs(p => ({ as: AdvancedSearch }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseAward = styled(Box)
  .attrs(p => ({ as: Award }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCalendar = styled(Box)
  .attrs(p => ({ as: Calendar }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCalendar2 = styled(Box)
  .attrs(p => ({ as: Calendar2 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCase2 = styled(Box)
  .attrs(p => ({ as: Case2 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseClose = styled(Box)
  .attrs(p => ({ as: Close }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseComment = styled(Box)
  .attrs(p => ({ as: Comment }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseComments = styled(Box)
  .attrs(p => ({ as: Comments }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDevice = styled(Box)
  .attrs(p => ({ as: Device }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDocument = styled(Box)
  .attrs(p => ({ as: Document }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDocuments = styled(Box)
  .attrs(p => ({ as: Documents }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseEdit2 = styled(Box)
  .attrs(p => ({ as: Edit2 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseFix = styled(Box)
  .attrs(p => ({ as: Fix }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseFixed = styled(Box)
  .attrs(p => ({ as: Fixed }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseInfo = styled(Box)
  .attrs(p => ({ as: Info }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseList2 = styled(Box)
  .attrs(p => ({ as: List2 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLock = styled(Box)
  .attrs(p => ({ as: Lock }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseMailbox = styled(Box)
  .attrs(p => ({ as: Mailbox }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseMapPoint = styled(Box)
  .attrs(p => ({ as: MapPoint }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseMicrophone = styled(Box)
  .attrs(p => ({ as: Microphone }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseNotAdd = styled(Box)
  .attrs(p => ({ as: NotAdd }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOk = styled(Box)
  .attrs(p => ({ as: Ok }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePhone = styled(Box)
  .attrs(p => ({ as: Phone }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseProgramFavorite = styled(Box)
  .attrs(p => ({ as: ProgramFavorite }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseRadioCheck2 = styled(Box)
  .attrs(p => ({ as: RadioCheck2 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseRemove = styled(Box)
  .attrs(p => ({ as: Remove }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSearch = styled(Box)
  .attrs(p => ({ as: Search }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSearch2 = styled(Box)
  .attrs(p => ({ as: Search2 }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseShape = styled(Box)
  .attrs(p => ({ as: Shape }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseShare = styled(Box)
  .attrs(p => ({ as: Share }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseShield = styled(Box)
  .attrs(p => ({ as: Shield }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseStudent = styled(Box)
  .attrs(p => ({ as: Student }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseTime = styled(Box)
  .attrs(p => ({ as: Time }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseTrash = styled(Box)
  .attrs(p => ({ as: Trash }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseUser = styled(Box)
  .attrs(p => ({ as: User }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseView = styled(Box)
  .attrs(p => ({ as: View }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseBackArrowSlim = styled(Box)
  .attrs(p => ({ as: BackArrowSlim }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseBackArrow = styled(Box)
  .attrs(p => ({ as: BackArrow }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseCloseMenu = styled(Box)
  .attrs(p => ({ as: CloseMenu }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseDownArrow = styled(Box)
  .attrs(p => ({ as: DownArrow }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseEmail = styled(Box)
  .attrs(p => ({ as: Email }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOpenMenuFit = styled(Box)
  .attrs(p => ({ as: OpenMenuFit }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseOpenMenu = styled(Box)
  .attrs(p => ({ as: OpenMenu }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePauseSimple = styled(Box)
  .attrs(p => ({ as: PauseSimple }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePause = styled(Box)
  .attrs(p => ({ as: Pause }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlaySimple = styled(Box)
  .attrs(p => ({ as: PlaySimple }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePlay = styled(Box)
  .attrs(p => ({ as: Play }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseArchitectureFooter = styled(Box)
  .attrs(p => ({ as: ArchitectureFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseArchitecture = styled(Box)
  .attrs(p => ({ as: Architecture }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseBusinessFooter = styled(Box)
  .attrs(p => ({ as: BusinessFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseBusiness = styled(Box)
  .attrs(p => ({ as: Business }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseHumanitiesFooter = styled(Box)
  .attrs(p => ({ as: HumanitiesFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseHumanities = styled(Box)
  .attrs(p => ({ as: Humanities }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLawFooter = styled(Box)
  .attrs(p => ({ as: LawFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLaw = styled(Box)
  .attrs(p => ({ as: Law }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLifelonglearningFooter = styled(Box)
  .attrs(p => ({ as: LifelonglearningFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLifelonglearningMobile = styled(Box)
  .attrs(p => ({ as: LifelonglearningMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLifelonglearning = styled(Box)
  .attrs(p => ({ as: Lifelonglearning }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoAlumniFooter = styled(Box)
  .attrs(p => ({ as: LogoAlumniFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoAlumniLight = styled(Box)
  .attrs(p => ({ as: LogoAlumniLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoAlumni = styled(Box)
  .attrs(p => ({ as: LogoAlumni }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoBsFooter = styled(Box)
  .attrs(p => ({ as: LogoBsFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoBsLight = styled(Box)
  .attrs(p => ({ as: LogoBsLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoBsMobile = styled(Box)
  .attrs(p => ({ as: LogoBsMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoBs = styled(Box)
  .attrs(p => ({ as: LogoBs }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCcltmFooter = styled(Box)
  .attrs(p => ({ as: LogoCcltmFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCcltmLight = styled(Box)
  .attrs(p => ({ as: LogoCcltmLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCcltm = styled(Box)
  .attrs(p => ({ as: LogoCcltm }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCgcFooter = styled(Box)
  .attrs(p => ({ as: LogoCgcFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCgcLight = styled(Box)
  .attrs(p => ({ as: LogoCgcLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCgc = styled(Box)
  .attrs(p => ({ as: LogoCgc }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCorpFooter = styled(Box)
  .attrs(p => ({ as: LogoCorpFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCorpLight = styled(Box)
  .attrs(p => ({ as: LogoCorpLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCorpMobile = styled(Box)
  .attrs(p => ({ as: LogoCorpMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCorp = styled(Box)
  .attrs(p => ({ as: LogoCorp }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCrFooter = styled(Box)
  .attrs(p => ({ as: LogoCrFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCrLight = styled(Box)
  .attrs(p => ({ as: LogoCrLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoCr = styled(Box)
  .attrs(p => ({ as: LogoCr }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoEntrepreneurshipFooter = styled(Box)
  .attrs(p => ({ as: LogoEntrepreneurshipFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoEntrepreneurshipLight = styled(Box)
  .attrs(p => ({ as: LogoEntrepreneurshipLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoEntrepreneurship = styled(Box)
  .attrs(p => ({ as: LogoEntrepreneurship }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoFaidFooter = styled(Box)
  .attrs(p => ({ as: LogoFaidFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoFaidLight = styled(Box)
  .attrs(p => ({ as: LogoFaidLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoFaid = styled(Box)
  .attrs(p => ({ as: LogoFaid }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoFoundFooter = styled(Box)
  .attrs(p => ({ as: LogoFoundFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoFoundLight = styled(Box)
  .attrs(p => ({ as: LogoFoundLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoFound = styled(Box)
  .attrs(p => ({ as: LogoFound }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoHumanitiesFooter = styled(Box)
  .attrs(p => ({ as: LogoHumanitiesFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoHumanitiesLight = styled(Box)
  .attrs(p => ({ as: LogoHumanitiesLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoHumanitiesMobile = styled(Box)
  .attrs(p => ({ as: LogoHumanitiesMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoHumanities = styled(Box)
  .attrs(p => ({ as: LogoHumanities }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLeadershipFooter = styled(Box)
  .attrs(p => ({ as: LogoLeadershipFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLeadershipLight = styled(Box)
  .attrs(p => ({ as: LogoLeadershipLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLeadership = styled(Box)
  .attrs(p => ({ as: LogoLeadership }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLlFooter = styled(Box)
  .attrs(p => ({ as: LogoLlFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLlLight = styled(Box)
  .attrs(p => ({ as: LogoLlLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLl = styled(Box)
  .attrs(p => ({ as: LogoLl }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLsFooter = styled(Box)
  .attrs(p => ({ as: LogoLsFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLsLight = styled(Box)
  .attrs(p => ({ as: LogoLsLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLsMobile = styled(Box)
  .attrs(p => ({ as: LogoLsMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoLs = styled(Box)
  .attrs(p => ({ as: LogoLs }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoNycLight = styled(Box)
  .attrs(p => ({ as: LogoNycLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoNyc = styled(Box)
  .attrs(p => ({ as: LogoNyc }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSoadFooter = styled(Box)
  .attrs(p => ({ as: LogoSoadFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSoadLight = styled(Box)
  .attrs(p => ({ as: LogoSoadLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSoadMobile = styled(Box)
  .attrs(p => ({ as: LogoSoadMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSoad = styled(Box)
  .attrs(p => ({ as: LogoSoad }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSogpFooter = styled(Box)
  .attrs(p => ({ as: LogoSogpFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSogpLight = styled(Box)
  .attrs(p => ({ as: LogoSogpLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSogpMobile = styled(Box)
  .attrs(p => ({ as: LogoSogpMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSogp = styled(Box)
  .attrs(p => ({ as: LogoSogp }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSohstFooter = styled(Box)
  .attrs(p => ({ as: LogoSohstFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSohstLight = styled(Box)
  .attrs(p => ({ as: LogoSohstLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSohstMobile = styled(Box)
  .attrs(p => ({ as: LogoSohstMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSohst = styled(Box)
  .attrs(p => ({ as: LogoSohst }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSpegaFooter = styled(Box)
  .attrs(p => ({ as: LogoSpegaFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSpegaLight = styled(Box)
  .attrs(p => ({ as: LogoSpegaLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSpegaMobile = styled(Box)
  .attrs(p => ({ as: LogoSpegaMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoSpega = styled(Box)
  .attrs(p => ({ as: LogoSpega }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoTcFooter = styled(Box)
  .attrs(p => ({ as: LogoTcFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoTcLight = styled(Box)
  .attrs(p => ({ as: LogoTcLight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoTc = styled(Box)
  .attrs(p => ({ as: LogoTc }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoUFooter = styled(Box)
  .attrs(p => ({ as: LogoUFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoULight = styled(Box)
  .attrs(p => ({ as: LogoULight }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoUMobile = styled(Box)
  .attrs(p => ({ as: LogoUMobile }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLogoU = styled(Box)
  .attrs(p => ({ as: LogoU }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseScienceFooter = styled(Box)
  .attrs(p => ({ as: ScienceFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseScience = styled(Box)
  .attrs(p => ({ as: Science }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSpegaFooter = styled(Box)
  .attrs(p => ({ as: SpegaFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSpega = styled(Box)
  .attrs(p => ({ as: Spega }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseUniversityFooter = styled(Box)
  .attrs(p => ({ as: UniversityFooter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseUniversity = styled(Box)
  .attrs(p => ({ as: University }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseFacebook = styled(Box)
  .attrs(p => ({ as: Facebook }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseFlickr = styled(Box)
  .attrs(p => ({ as: Flickr }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseInstagram = styled(Box)
  .attrs(p => ({ as: Instagram }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseLinkedin = styled(Box)
  .attrs(p => ({ as: Linkedin }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BasePodcast = styled(Box)
  .attrs(p => ({ as: Podcast }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseSnapchat = styled(Box)
  .attrs(p => ({ as: Snapchat }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseTiktok = styled(Box)
  .attrs(p => ({ as: Tiktok }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseTwitter = styled(Box)
  .attrs(p => ({ as: Twitter }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseWhatsapp = styled(Box)
  .attrs(p => ({ as: Whatsapp }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`
const BaseYoutube = styled(Box)
  .attrs(p => ({ as: Youtube }))
  .withConfig({shouldForwardProp: (prop) => ![...filterProps].includes(prop)})
`${Base}`

const Icons = ({ name, props}) => {
  const list = {
    aDownload : <BaseADownload {...props} />,
cloudDownload : <BaseCloudDownload {...props} />,
downloadBox : <BaseDownloadBox {...props} />,
dropDown : <BaseDropDown {...props} />,
dropUp : <BaseDropUp {...props} />,
externalLink : <BaseExternalLink {...props} />,
higher : <BaseHigher {...props} />,
left : <BaseLeft {...props} />,
less : <BaseLess {...props} />,
right : <BaseRight {...props} />,
attach : <BaseAttach {...props} />,
botLight : <BaseBotLight {...props} />,
bot : <BaseBot {...props} />,
question : <BaseQuestion {...props} />,
quote : <BaseQuote {...props} />,
add1 : <BaseAdd1 {...props} />,
add : <BaseAdd {...props} />,
advancedSearch : <BaseAdvancedSearch {...props} />,
award : <BaseAward {...props} />,
calendar : <BaseCalendar {...props} />,
calendar2 : <BaseCalendar2 {...props} />,
case2 : <BaseCase2 {...props} />,
close : <BaseClose {...props} />,
comment : <BaseComment {...props} />,
comments : <BaseComments {...props} />,
device : <BaseDevice {...props} />,
document : <BaseDocument {...props} />,
documents : <BaseDocuments {...props} />,
edit2 : <BaseEdit2 {...props} />,
fix : <BaseFix {...props} />,
fixed : <BaseFixed {...props} />,
info : <BaseInfo {...props} />,
list2 : <BaseList2 {...props} />,
lock : <BaseLock {...props} />,
mailbox : <BaseMailbox {...props} />,
mapPoint : <BaseMapPoint {...props} />,
microphone : <BaseMicrophone {...props} />,
notAdd : <BaseNotAdd {...props} />,
ok : <BaseOk {...props} />,
phone : <BasePhone {...props} />,
programFavorite : <BaseProgramFavorite {...props} />,
radioCheck2 : <BaseRadioCheck2 {...props} />,
remove : <BaseRemove {...props} />,
search : <BaseSearch {...props} />,
search2 : <BaseSearch2 {...props} />,
shape : <BaseShape {...props} />,
share : <BaseShare {...props} />,
shield : <BaseShield {...props} />,
student : <BaseStudent {...props} />,
time : <BaseTime {...props} />,
trash : <BaseTrash {...props} />,
user : <BaseUser {...props} />,
view : <BaseView {...props} />,
backArrowSlim : <BaseBackArrowSlim {...props} />,
backArrow : <BaseBackArrow {...props} />,
closeMenu : <BaseCloseMenu {...props} />,
downArrow : <BaseDownArrow {...props} />,
email : <BaseEmail {...props} />,
openMenuFit : <BaseOpenMenuFit {...props} />,
openMenu : <BaseOpenMenu {...props} />,
pauseSimple : <BasePauseSimple {...props} />,
pause : <BasePause {...props} />,
playSimple : <BasePlaySimple {...props} />,
play : <BasePlay {...props} />,
architectureFooter : <BaseArchitectureFooter {...props} />,
architecture : <BaseArchitecture {...props} />,
businessFooter : <BaseBusinessFooter {...props} />,
business : <BaseBusiness {...props} />,
humanitiesFooter : <BaseHumanitiesFooter {...props} />,
humanities : <BaseHumanities {...props} />,
lawFooter : <BaseLawFooter {...props} />,
law : <BaseLaw {...props} />,
lifelonglearningFooter : <BaseLifelonglearningFooter {...props} />,
lifelonglearningMobile : <BaseLifelonglearningMobile {...props} />,
lifelonglearning : <BaseLifelonglearning {...props} />,
logoAlumniFooter : <BaseLogoAlumniFooter {...props} />,
logoAlumniLight : <BaseLogoAlumniLight {...props} />,
logoAlumni : <BaseLogoAlumni {...props} />,
logoBsFooter : <BaseLogoBsFooter {...props} />,
logoBsLight : <BaseLogoBsLight {...props} />,
logoBsMobile : <BaseLogoBsMobile {...props} />,
logoBs : <BaseLogoBs {...props} />,
logoCcltmFooter : <BaseLogoCcltmFooter {...props} />,
logoCcltmLight : <BaseLogoCcltmLight {...props} />,
logoCcltm : <BaseLogoCcltm {...props} />,
logoCgcFooter : <BaseLogoCgcFooter {...props} />,
logoCgcLight : <BaseLogoCgcLight {...props} />,
logoCgc : <BaseLogoCgc {...props} />,
logoCorpFooter : <BaseLogoCorpFooter {...props} />,
logoCorpLight : <BaseLogoCorpLight {...props} />,
logoCorpMobile : <BaseLogoCorpMobile {...props} />,
logoCorp : <BaseLogoCorp {...props} />,
logoCrFooter : <BaseLogoCrFooter {...props} />,
logoCrLight : <BaseLogoCrLight {...props} />,
logoCr : <BaseLogoCr {...props} />,
logoEntrepreneurshipFooter : <BaseLogoEntrepreneurshipFooter {...props} />,
logoEntrepreneurshipLight : <BaseLogoEntrepreneurshipLight {...props} />,
logoEntrepreneurship : <BaseLogoEntrepreneurship {...props} />,
logoFaidFooter : <BaseLogoFaidFooter {...props} />,
logoFaidLight : <BaseLogoFaidLight {...props} />,
logoFaid : <BaseLogoFaid {...props} />,
logoFoundFooter : <BaseLogoFoundFooter {...props} />,
logoFoundLight : <BaseLogoFoundLight {...props} />,
logoFound : <BaseLogoFound {...props} />,
logoHumanitiesFooter : <BaseLogoHumanitiesFooter {...props} />,
logoHumanitiesLight : <BaseLogoHumanitiesLight {...props} />,
logoHumanitiesMobile : <BaseLogoHumanitiesMobile {...props} />,
logoHumanities : <BaseLogoHumanities {...props} />,
logoLeadershipFooter : <BaseLogoLeadershipFooter {...props} />,
logoLeadershipLight : <BaseLogoLeadershipLight {...props} />,
logoLeadership : <BaseLogoLeadership {...props} />,
logoLlFooter : <BaseLogoLlFooter {...props} />,
logoLlLight : <BaseLogoLlLight {...props} />,
logoLl : <BaseLogoLl {...props} />,
logoLsFooter : <BaseLogoLsFooter {...props} />,
logoLsLight : <BaseLogoLsLight {...props} />,
logoLsMobile : <BaseLogoLsMobile {...props} />,
logoLs : <BaseLogoLs {...props} />,
logoNycLight : <BaseLogoNycLight {...props} />,
logoNyc : <BaseLogoNyc {...props} />,
logoSoadFooter : <BaseLogoSoadFooter {...props} />,
logoSoadLight : <BaseLogoSoadLight {...props} />,
logoSoadMobile : <BaseLogoSoadMobile {...props} />,
logoSoad : <BaseLogoSoad {...props} />,
logoSogpFooter : <BaseLogoSogpFooter {...props} />,
logoSogpLight : <BaseLogoSogpLight {...props} />,
logoSogpMobile : <BaseLogoSogpMobile {...props} />,
logoSogp : <BaseLogoSogp {...props} />,
logoSohstFooter : <BaseLogoSohstFooter {...props} />,
logoSohstLight : <BaseLogoSohstLight {...props} />,
logoSohstMobile : <BaseLogoSohstMobile {...props} />,
logoSohst : <BaseLogoSohst {...props} />,
logoSpegaFooter : <BaseLogoSpegaFooter {...props} />,
logoSpegaLight : <BaseLogoSpegaLight {...props} />,
logoSpegaMobile : <BaseLogoSpegaMobile {...props} />,
logoSpega : <BaseLogoSpega {...props} />,
logoTcFooter : <BaseLogoTcFooter {...props} />,
logoTcLight : <BaseLogoTcLight {...props} />,
logoTc : <BaseLogoTc {...props} />,
logoUFooter : <BaseLogoUFooter {...props} />,
logoULight : <BaseLogoULight {...props} />,
logoUMobile : <BaseLogoUMobile {...props} />,
logoU : <BaseLogoU {...props} />,
scienceFooter : <BaseScienceFooter {...props} />,
science : <BaseScience {...props} />,
spegaFooter : <BaseSpegaFooter {...props} />,
spega : <BaseSpega {...props} />,
universityFooter : <BaseUniversityFooter {...props} />,
university : <BaseUniversity {...props} />,
facebook : <BaseFacebook {...props} />,
flickr : <BaseFlickr {...props} />,
instagram : <BaseInstagram {...props} />,
linkedin : <BaseLinkedin {...props} />,
podcast : <BasePodcast {...props} />,
snapchat : <BaseSnapchat {...props} />,
tiktok : <BaseTiktok {...props} />,
twitter : <BaseTwitter {...props} />,
whatsapp : <BaseWhatsapp {...props} />,
youtube : <BaseYoutube {...props} />,
    }
    return <React.Suspense fallback={null}>{list[name]}</React.Suspense> || null
}

const IcnSystem = ({ name, ...props }) => {
  const icon = Icons({ name, props })
  if (!icon) console.warn(`Griddo: The Icon ${name} doesn't exist in the icon library`)
  return icon
}

export default IcnSystem
 