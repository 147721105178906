import { getThemeOptions } from "@helpers/schemas"
import { AddressCollection, AddressCollection2x } from "@thumbnails/Modules"
import { CardCollectionLayout1, CardCollectionLayout2 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Address Collection",
  component: "AddressCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          title: "Address Cards",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["AddressCard"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CardCollectionLayout1,
            },
            {
              value: "L002",
              img: CardCollectionLayout2,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
        {
          title: "Show map",
          type: "RadioGroup",
          key: "showMap",
          mandatory: true,
          options: [
            {
              value: "yes",
              title: "Yes",
              name: "yes",
            },
            {
              value: "no",
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "AddressCollection",
    title: { content: "Lorem ipsum", tag: "h1" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    detail: null,
    theme: "defaultAlt",
    layout: "L001",
    showMap: "yes",
    elements: [
      {
        component: "AddressCard",
        title: {
          content: "Title",
          tag: "h1",
        },
        address: "Lorem ipsum dolor sit amet, 00",
        email: "email@email.com",
        phone: "+44 (0) 00 00 00 00 00",
        elementStyle: "MAP",
        link: {
          component: "Link",
        },
        map: {
          component: "Map",
        },
      },
      {
        component: "AddressCard",
        title: {
          content: "Title",
          tag: "h1",
        },
        address: "Lorem ipsum dolor sit amet, 00",
        email: "email@email.com",
        phone: "+44 (0) 00 00 00 00 00",
        link: {
          component: "Link",
        },
        map: {
          component: "Map",
        },
      },
    ],
  },
  thumbnails: {
    "1x": AddressCollection,
    "2x": AddressCollection2x,
  },
}
