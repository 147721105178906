import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Map Card Box",
  component: "MapCardBox",
  dataPacks: null,
  configTabs: [
    { title: "content", fields: [] },
    { title: "config", fields: [] },
  ],
  default: {
    component: "MapCardBox",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
