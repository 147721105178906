const isBrowser = typeof window !== "undefined"

const getToken = () => {
  const appInfo = isBrowser && JSON.parse(localStorage.getItem("persist:app"))

  if (!appInfo) return null

  let { token } = appInfo
  token = JSON.parse(token)
  const authorization = { Authorization: `bearer ${token}` }

  return authorization
}

const getSiteID = () => {
  const appInfo =
    typeof isBrowser && JSON.parse(localStorage.getItem("persist:root"))

  if (!appInfo) return 1

  let { sites } = appInfo
  sites = JSON.parse(sites)
  const {
    currentSiteInfo: { id },
  } = sites
  return id
}

const getLang = () => isBrowser && parseInt(localStorage.getItem("langID"))

export { getToken, getSiteID, getLang }
