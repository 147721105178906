import { allModulesWhitelist } from "@helpers/schemas"
import { ProgramsList, ProgramsList2x } from "@thumbnails/Templates"
import {
  ProgramsListLayout1,
  ProgramsListLayout2,
  ProgramsListLayout3,
  ProgramsListLayout4,
} from "@layouts"

export default {
  schemaType: "template",
  displayName: "Programs List",
  component: "ProgramsList",
  dataPacks: ["STUDIES"],
  type: { label: "Programs", value: "programs" },
  config: [
    {
      title: "Layout",
      key: "layout",
      type: "VisualUniqueSelection",
      mandatory: true,
      options: [
        {
          value: "L001",
          img: ProgramsListLayout1,
        },
        {
          value: "L002",
          img: ProgramsListLayout2,
        },
        {
          value: "L003",
          img: ProgramsListLayout3,
        },
        {
          value: "L004",
          img: ProgramsListLayout4,
        },
      ],
      columns: 2,
    },
    {
      title: "Show school logo",
      type: "ConditionalField",
      key: "showSchoolLogo",
      mandatory: true,
      required: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
  ],

  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
      mandatory: true,
    },

    {
      key: "templateTitle",
      title: "Title",
      type: "TextField",
      mandatory: true,
      humanReadable: true,
    },

    {
      key: "subtitle",
      title: "Subtitle",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },

    {
      key: "detail",
      title: "Detail",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },

    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showPathwaysExecutiveFilter",
              title: "Show Pathways executive education filter",
              name: "pathwaysExecutiveFilter",
            },
            {
              value: "showFormatFilter",
              title: "Show Format filter",
              name: "formatFilter",
            },
            {
              value: "showYearsExperienceFilter",
              title: "Show Years of Experience filter",
              name: "yearsExperienceFilter",
            },

            {
              value: "showProgramTypeFilter",
              title: "Show Program type filter",
              name: "programTypeFilter",
            },

            {
              value: "showLocationFilter",
              title: "Show Location filter",
              name: "locationFilter",
            },

            {
              value: "showLanguageFilter",
              title: "Show Language filter",
              name: "languageFilter",
            },

            {
              value: "showDurationFilter",
              title: "Show Duration filter",
              name: "durationFilter",
            },

            {
              value: "showIntakeFilter",
              title: "Show Intake filter",
              name: "intakeFilter",
            },

            {
              value: "showModeStudyFilter",
              title: "Show Mode of Study filter",
              name: "modeStudyFilter",
            },

            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "pathwaysExecutiveLabel",
          title: "Pathways Executive label",
          type: "TextField",
        },
        {
          key: "formatLabel",
          title: "Format label",
          type: "TextField",
        },
        {
          key: "yearsExperienceLabel",
          title: "Years of Experience label",
          type: "TextField",
        },
        {
          key: "programTypeLabel",
          title: "Program type label",
          type: "TextField",
        },
        {
          key: "locationLabel",
          title: "Location label",
          type: "TextField",
        },
        {
          key: "languageLabel",
          title: "Language label",
          type: "TextField",
        },
        {
          key: "durationLabel",
          title: "Duration label",
          type: "TextField",
        },
        {
          key: "intakeLabel",
          title: "Intake label",
          type: "TextField",
        },
        {
          key: "modeStudyLabel",
          title: "Mode of Study Label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "PROGRAMS" }],
      key: "data",
    },
    {
      title: "Content hierarchy for SEO",
      type: "Select",
      key: "cardTitleTag",
      options: [
        {
          value: "h2",
          label: "H2",
        },
        {
          value: "h3",
          label: "H3",
        },
        {
          value: "h4",
          label: "H4",
        },
        {
          value: "span",
          label: "span",
        },
      ],
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      mandatory: true,
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 20,
      minValue: 4,
      maxValue: 40,
      mandatory: true,
    },
    {
      title: "Cards titles link",
      type: "RadioGroup",
      key: "titlesLink",
      mandatory: false,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
    },
    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Where should the link go?",
      type: "RadioGroup",
      key: "linkDirection",
      mandatory: false,
      helptext: "This only affects to automatic mode.",
      options: [
        {
          value: "pageOfCanonical",
          title: "Page of the canonical site",
          name: "pageOfCanonical",
        },
        {
          value: "pageOfSite",
          title: "Page of this site",
          name: "pageOfSite",
        },
      ],
    },
    {
      title: "Cards 'Apply' button",
      type: "RadioGroup",
      key: "applyButton",
      mandatory: true,
      options: [
        {
          name: "no",
          value: false,
          title: "No",
        },
        {
          name: "yes",
          value: true,
          title: "Yes",
        },
      ],
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],

  default: {
    type: "template",
    templateType: "ProgramsList",
    layout: "L001",
    showSchoolLogo: true,
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    cardTitleTag: "h3",
    templateOptions: [],
    filtersType: "simple",
    templateTitle: "Lorem ipsum",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "PROGRAMS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "alpha",
      quantity: 15,
      preferenceLanguage: false,
      relations: true,
    },
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 6,
    titlesLink: false,
    linkText: null,
    linkDirection: "pageOfSite",
    applyButton: false,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": ProgramsList,
    "2x": ProgramsList2x,
  },
}
