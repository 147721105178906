import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Tag Element",
  component: "TagElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Label",
          type: "TextField",
          key: "label",
          humanReadable: true,
        },
        {
          title: "URL",
          type: "UrlField",
          advanced: true,
          key: "url",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "TagElement",
    label: "tag1",
    url: {
      external: true,
      href: "",
      icon: "",
      label: "",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
