import { getThemeOptions } from "@helpers/schemas"
import { LocationMap, LocationMap2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Location map",
  component: "LocationMap",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "",
          type: "ReferenceField",
          sources: [
            { structuredData: "INTERNATIONAL_OFFICES" },
            { structuredData: "CLUBS" },
            { structuredData: "PARTNERS" },
          ],
          key: "data",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    verticalSpacing: "medium",
    component: "LocationMap",
    theme: "default",
    title: { content: "Title", tag: "h2" },
    subtitle: null,
    detail: null,
    hasDistributorData: true,
    data: {
      mode: "auto",
      order: "alpha",
      sources: [
        {
          structuredData: "INTERNATIONAL_OFFICES",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "CLUBS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "PARTNERS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
    },
  },
  thumbnails: {
    "1x": LocationMap,
    "2x": LocationMap2x,
  },
}
