const EVENTS = {
  title: "Events",
  dataPacks: ["EVENTS"],
  local: false,
  taxonomy: false,
  fromPage: true,
  translate: true,
  schema: {
    templates: ["EventDetail"],
    fields: [
      {
        key: "title",
        title: "Title",
        type: "TextField",
        from: "title",
        humanReadable: true,
      },
      {
        key: "eventFormat",
        title: "Format",
        type: "RadioGroup",
        from: "eventFormat",
        options: [
          {
            value: "VIRTUAL",
            title: "Virtual",
            name: "VIRTUAL",
          },
          {
            value: "FACE_TO_FACE",
            title: "Face to face",
            name: "FACE_TO_FACE",
          },
          {
            value: "HYBRID",
            title: "Hybrid",
            name: "HYBRID",
          },
        ],
      },
      {
        key: "image",
        title: "Image",
        type: "ImageField",
        from: "image",
      },
      {
        key: "tag",
        title: "Tag",
        type: "TextField",
        from: "tag",
        humanReadable: true,
      },
      {
        key: "dateTime",
        title: "Event date and time",
        type: "DateField",
        from: "dateTime",
        indexable: true,
      },
      {
        key: "eventHour",
        title: "Event Hour",
        type: "TextField",
        from: "eventHour",
      },
      {
        key: "language",
        title: "Language",
        type: "TextField",
        from: "language",
        humanReadable: true,
      },
      {
        key: "address",
        title: "Address",
        type: "TextField",
        from: "address",
      },
      {
        key: "country",
        title: "Country",
        type: "TextField",
        from: "country",
        humanReadable: true,
      },
      {
        key: "distributorDescription",
        title: "Distributor description",
        type: "TextField",
        from: "distributorDescription",
        humanReadable: true,
      },
      {
        key: "addToCalendar",
        title: "Add to calendar button",
        type: "ComponentContainer",
        from: "addToCalendar",
      },
      {
        key: "units",
        title: "Unit",
        type: "AsyncCheckGroup",
        from: "categories.units",
        source: "UNIT",
      },
      {
        key: "schools",
        title: "Schools",
        type: "AsyncCheckGroup",
        from: "categories.schools",
        source: "SCHOOLS",
      },
      {
        key: "pathways",
        title: "Pathways",
        type: "AsyncCheckGroup",
        from: "categories.pathways",
        source: "PATHWAYS",
      },
      {
        key: "programs",
        title: "Programs",
        type: "AsyncCheckGroup",
        from: "categories.programs",
        source: "PROGRAMS",
      },
      {
        key: "subjectAreas",
        title: "Subject Area & Values",
        type: "AsyncCheckGroup",
        from: "categories.subjectAreas",
        source: "SUBJECT_AREA",
      },
      {
        key: "stageTargets",
        title: "Stage Target",
        type: "AsyncCheckGroup",
        from: "categories.stageTargets",
        source: "STAGE_TARGET",
      },
      {
        key: "regions",
        title: "Region",
        type: "AsyncCheckGroup",
        from: "categories.regions",
        source: "REGION",
        showList: true,
        indexable: true,
      },
      {
        key: "formats",
        title: "Format",
        type: "AsyncCheckGroup",
        from: "categories.formats",
        source: "FORMAT",
      },
      {
        key: "centers",
        title: "Centers & Departments",
        type: "AsyncCheckGroup",
        from: "categories.centers",
        source: "CENTERS",
      },
      {
        key: "topics",
        title: "Topic",
        type: "AsyncCheckGroup",
        from: "categories.topics",
        source: "TOPIC",
      },
      {
        key: "eventTypes",
        title: "Event Type",
        type: "AsyncCheckGroup",
        from: "categories.eventTypes",
        source: "EVENT_TYPE",
      },
    ],
    searchFrom: ["tag"],
  },
  clone: null,
  defaultValues: null,
}

export { EVENTS }
