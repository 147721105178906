import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Quick Fact",
  component: "QuickFact",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          key: "title",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Detail",
          key: "detail",
          type: "TextField",
          humanReadable: true,
        },
      ],
    },
    { title: "config", fields: [] },
  ],
  default: {
    component: "QuickFact",
    title: "Title",
    detail: "Lorem Ipsum",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
