import { getThemeOptions } from "@helpers/schemas"
import { LoadScript, LoadScript2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "LoadScript",
  component: "LoadScript",
  category: "iframeAndScript",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          key: "code",
          title: "Script Code",
          type: "TextArea",
          mandatory: true,
          helptext: "The code of the script you need to introduce.",
        },
        {
          key: "elementId",
          title: "HTML Element ID",
          type: "TextField",
          hideable: true,
          helptext:
            "If your script needs a HTML block with a specific ID, enter it here. Do not use uppercase letter or spaces and cannot start with numbers.",
        },
        {
          title: "Grouping link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "noSpace", value: "noSpace", title: "No space" },
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "LoadScript",
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: null,
    subtitle: null,
    detail: null,
    code: "",
    elementId: "",
    link: {
      component: "Link",
    },
    moduleOrder: null,
  },
  thumbnails: {
    "1x": LoadScript,
    "2x": LoadScript2x,
  },
}
