import { NoThumbnail, NoThumbnail2x } from "@thumbnails"
import MultiPageElement from "../components/MultiPageElement"

export default {
  schemaType: "module",
  displayName: "Multi Page Module",
  component: "MultiPage",
  category: "dev",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "TextField",
          humanReadable: true,
        },
        {
          title: "Multi Page Element",
          type: "ComponentArray",
          maxItems: 5,
          whiteList: ["MultiPageElement"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "MultiPage",
    title: "Lorem Ipsum",
    hasGriddoMultiPage: true,
    elements: [
      { ...MultiPageElement.default, sectionSlug: "tab-01" },
      { ...MultiPageElement.default, sectionSlug: "tab-02" },
      { ...MultiPageElement.default, sectionSlug: "tab-03" },
    ],
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
