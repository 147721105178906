import { getThemeOptions } from "@helpers/schemas"
import { VideoCollection, VideoCollection2x } from "@thumbnails/Modules"
import {
  CardCollectionLayout1,
  CardCollectionLayout2,
  CardCollectionLayout3,
  CardCollectionLayout4,
  CardCollectionLayout5,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Video Collection",
  component: "VideoCollection",
  category: "collections",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          hideable: true,
          key: "detail",
          humanReadable: true,
        },
        {
          title: "Cards",
          type: "ComponentArray",
          mandatory: true,
          maxItems: null,
          whiteList: ["ValueCard"],
          key: "elements",
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CardCollectionLayout1,
            },
            {
              value: "L002",
              img: CardCollectionLayout2,
            },
            {
              value: "L003",
              img: CardCollectionLayout3,
            },
            {
              value: "L004",
              img: CardCollectionLayout4,
            },
            {
              value: "L005",
              img: CardCollectionLayout5,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "VideoCollection",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    layout: "L003",
    title: { content: "Title", tag: "h2" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    detail: null,
    elements: [
      {
        component: "ValueCard",
        title: { content: "Title", tag: "h3" },
        video: {
          component: "Video",
          thumbnail: { component: "Image" },
          url: "",
        },
      },
      {
        component: "ValueCard",
        title: { content: "Title", tag: "h3" },
        video: {
          component: "Video",
          thumbnail: { component: "Image" },
          url: "",
        },
      },
      {
        component: "ValueCard",
        title: { content: "Title", tag: "h3" },
        video: {
          component: "Video",
          thumbnail: { component: "Image" },
          url: "",
        },
      },
      {
        component: "ValueCard",
        title: { content: "Title", tag: "h3" },
        video: {
          component: "Video",
          thumbnail: { component: "Image" },
          url: "",
        },
      },
    ],
    groupingLink: {
      component: "Link",
    },
  },
  thumbnails: {
    "1x": VideoCollection,
    "2x": VideoCollection2x,
  },
}
