import { getThemeOptions } from "@helpers/schemas"
import { Spacer, Spacer2x } from "@thumbnails/Modules"
import { spacerDecorationNone, spacerDecorationLine } from "@theme-img"

export default {
  schemaType: "module",
  displayName: "Spacer",
  component: "Spacer",
  dataPacks: null,
  category: "spacers",
  configTabs: [
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Decoration",
          key: "decorations",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "none",
              img: spacerDecorationNone,
            },
            {
              value: "line",
              img: spacerDecorationLine,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
        {
          title: "Size",
          type: "RadioGroup",
          key: "size",
          mandatory: true,
          helptext: "Spacer size",
          options: [
            {
              name: "s",
              value: "s",
              title: "Small",
            },
            {
              name: "m",
              value: "m",
              title: "Medium",
            },
            {
              name: "l",
              value: "l",
              title: "Large",
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: "Spacer",
    size: "s",
    theme: "default",
    decorations: "none",
  },
  thumbnails: {
    "1x": Spacer,
    "2x": Spacer2x,
  },
}
