import { allModulesWhitelist } from "@helpers/schemas"
import { GeneralInfoList, GeneralInfoList2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "General Info List",
  component: "GeneralInfoList",
  dataPacks: ["GENERALINFO"],
  type: { label: "GeneralInfo", value: "generalinfo" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      key: "heroSection",
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
      ],
      mandatory: true,
    },
    {
      key: "leadText",
      title: "Lead Text",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showRegionFilter",
              title: "Show Region filter",
              name: "regionFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "regionLabel",
          title: "Region label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "GENERALINFO" }],
      key: "data",
    },
    {
      key: "noResultsText",
      title: "No Results Text",
      type: "TextField",
      humanReadable: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 20,
      maxValue: 40,
      mandatory: true,
    },
    {
      title: "Link text",
      type: "TextField",
      key: "linkText",
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],
  default: {
    type: "template",
    templateType: "GeneralInfoList",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    leadText: "Lorem ipsum",
    templateOptions: [],
    filtersType: "simple",
    data: {
      mode: "auto",
      sources: [
        {
          structuredData: "GENERALINFO",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      order: "recent",
      quantity: 20,
      preferenceLanguage: false,
    },
    hasDistributorData: false,
    noResultsText:
      "Sorry, no results were found. Please try a different search.",
    itemsPerPage: 20,
    linkText: null,
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [{ component: "SocialMedia" }],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": GeneralInfoList,
    "2x": GeneralInfoList2x,
  },
}
