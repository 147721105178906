import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  component: "HeroCard",
  displayName: "Hero Card",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "span", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "TextArea",
          key: "subtitle",
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Background Image",
          type: "ComponentContainer",
          whiteList: ["Image"],
          key: "backgroundImage",
          helptext: "Recommended minimum image size: 2508x1030",
        },
      ],
    },
  ],
  default: {
    component: "HeroCard",
    title: {
      content: "Title",
      tag: "span",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    link: {
      component: "Link",
      text: "Link",
      url: {
        url: "",
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
    backgroundImage: {
      component: "Image",
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
