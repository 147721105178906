import { NewsList, NewsList2x } from "@thumbnails/Templates"
import { NewsListDesign1, NewsListDesign2, NewsListDesign3 } from "@designs"
import { allModulesWhitelist } from "@helpers/schemas"

export default {
  schemaType: "template",
  displayName: "News List",
  component: "NewsList",
  dataPacks: ["NEWS"],
  type: { label: "News", value: "news" },
  config: [
    {
      title: "Design",
      key: "design",
      type: "VisualUniqueSelection",
      options: [
        {
          value: "D001",
          img: NewsListDesign1,
        },
        {
          value: "D002",
          img: NewsListDesign2,
        },
        {
          value: "D003",
          img: NewsListDesign3,
        },
      ],
      columns: 3,
    },
  ],
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["HeroText", "HeroCarousel", "HeroImage"],
      key: "heroSection",
    },
    {
      title: "Lead text",
      type: "RichText",
      key: "leadText",
      hideable: true,
    },
    {
      title: "Template filters",
      type: "FieldGroup",
      fields: [
        {
          value: {
            text: "Recommended always selecting the Search Bar if you selected more than three filters.",
          },
          type: "NoteField",
          key: "noteFilters",
        },
        {
          title: "Select template filters",
          type: "CheckGroup",
          key: "templateOptions",
          options: [
            {
              value: "showUnitsFilter",
              title: "Show Units filter",
              name: "unitsFilter",
            },
            {
              value: "showSchoolsFilter",
              title: "Show Schools filter",
              name: "schoolsFilter",
            },
            {
              value: "showPathwaysFilter",
              title: "Show Pathways filter",
              name: "pathwaysFilter",
            },
            {
              value: "showProgramFilter",
              title: "Show Programs filter",
              name: "programsFilter",
            },
            {
              value: "showSubjectFilter",
              title: "Show Subject Area & Values filter",
              name: "subjectFilter",
            },
            {
              value: "showStageTargetFilter",
              title: "Show Stage Target filter",
              name: "stageTargetFilter",
            },
            {
              value: "showCentersFilter",
              title: "Show Centers & Departments filter",
              name: "centersFilter",
            },
            {
              value: "showTopicFilter",
              title: "Show Topic filter",
              name: "topicFilter",
            },
            {
              value: "showSearchbar",
              title: "Show Search Bar",
              name: "searchbar",
            },
          ],
        },
      ],
    },
    {
      key: "filtersType",
      title: "Select filter type",
      type: "RadioGroup",
      mandatory: true,
      options: [
        {
          value: "simple",
          title: "Simple filters",
          name: "simple",
        },
        {
          value: "advanced",
          title: "Advanced secondary filters",
          name: "advanced",
        },
      ],
    },
    {
      title: "Edit filters",
      type: "FieldGroup",
      collapsed: true,
      fields: [
        {
          key: "unitsLabel",
          title: "Units label",
          type: "TextField",
        },
        {
          key: "schoolsLabel",
          title: "Schools label",
          type: "TextField",
        },
        {
          key: "pathwaysLabel",
          title: "Pathways label",
          type: "TextField",
        },
        {
          key: "programsLabel",
          title: "Programs label",
          type: "TextField",
        },
        {
          key: "subjectLabel",
          title: "Subject Area & Values label",
          type: "TextField",
        },
        {
          key: "stageTargetLabel",
          title: "Stage target label",
          type: "TextField",
        },
        {
          key: "centersLabel",
          title: "Centers & Departments label",
          type: "TextField",
        },
        {
          key: "topicLabel",
          title: "Topic label",
          type: "TextField",
        },
      ],
    },
    {
      type: "ReferenceField",
      sources: [{ structuredData: "NEWS" }],
      key: "newsData",
    },
    {
      title: "No result text",
      type: "TextField",
      key: "noResultText",
      mandatory: true,
    },
    {
      title: "Items/page",
      type: "NumberField",
      key: "itemsPerPage",
      default: 18,
      minValue: 3,
      maxValue: 18,
      mandatory: true,
    },
    {
      title: "Show Featured News",
      type: "ConditionalField",
      key: "featuredCards",
      helptext: "This only affects to design 2 and 3.",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "Yes",
        },
        {
          value: false,
          title: "No",
          name: "No",
        },
      ],
      fields: [
        {
          title: "Select Featured News",
          type: "ReferenceField",
          sources: [{ structuredData: "NEWS" }],
          key: "data",
          mandatory: true,
          condition: true,
          helptext: "Max items 3. It is recommended to use 1 or 3 elements.",
        },
      ],
    },
    {
      title: "Element style",
      type: "RadioGroup",
      key: "elementStyle",
      mandatory: true,
      options: [
        {
          value: true,
          title: "With long abstract",
          name: "abstract",
        },
        {
          value: false,
          title: "No long abstract",
          name: "noLongAbstract",
        },
      ],
    },
    {
      title: "Where should the news link go?",
      type: "RadioGroup",
      key: "linkDirection",
      mandatory: false,
      helptext: "This only affects to automatic mode.",
      options: [
        {
          value: "pageOfCanonical",
          title: "Page of the canonical site",
          name: "pageOfCanonical",
        },
        {
          value: "pageOfSite",
          title: "Page of this site",
          name: "pageOfSite",
        },
      ],
    },
    {
      title: "Content hierarchy for SEO",
      type: "Select",
      key: "cardTitleTag",
      options: [
        { value: "h2", label: "H2" },
        { value: "h3", label: "H3" },
        { value: "h4", label: "H4" },
        { value: "span", label: "Span" },
      ],
    },
    {
      title: "Related content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "relatedContent",
    },
  ],

  default: {
    type: "template",
    templateType: "NewsList",
    design: "D001",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    leadText: null,
    templateOptions: [],
    filtersType: "simple",
    newsData: {
      mode: "auto",
      sources: [
        { structuredData: "NEWS", filterOperator: "OR", globalOperator: "AND" },
      ],
      order: "alpha",
      quantity: 20,
      preferenceLanguage: false,
    },
    noResultText: "Sorry, no results found. Try another search.",
    itemsPerPage: 18,
    featuredCards: false,
    data: {
      mode: "manual",
      sources: [{ structuredData: "NEWS" }],
      order: "recent",
      quantity: 3,
      preferenceLanguage: false,
    },
    hasDistributorData: true,
    elementStyle: true,
    linkDirection: "pageOfSite",
    cardTitleTag: "h3",
    relatedContent: {
      component: "Section",
      name: "Related content",
      modules: [],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": NewsList,
    "2x": NewsList2x,
  },
}
