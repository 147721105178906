import { Flex } from "@sqymagma/elements"
import React from "react"
import { useStyledTheme } from "@hooks"
import { SPACING } from "@constants"

const MaxWidth = ({
  noMaxWidth = false,
  overflow = "visible",
  width = false,
  bleed,
  fluid,
  children,
  px,
  ...props
}) => {
  const subTheme = useStyledTheme()
  const breakpoints = subTheme?.breakpoints
  const largeBreakpoints = breakpoints
    ? breakpoints[breakpoints.length - 1]
    : []

  return (
    <Flex
      width={
        !width || (fluid && noMaxWidth)
          ? 1
          : fluid === false && noMaxWidth === false && [...breakpoints]
      }
      maxWidth={
        noMaxWidth === false
          ? largeBreakpoints
          : fluid === false && noMaxWidth === false && [...breakpoints]
      }
      px={bleed ? null : px ? px : SPACING.moduleSidePadding}
      flexDirection="column"
      mx="auto"
      css={`
        overflow: ${p => p.overflow};
      `}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default MaxWidth
