import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { DownloadForm, DownloadForm2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Download Form",
  component: "DownloadForm",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          advanced: true,
          hideable: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Background Image",
          type: "ConditionalField",
          key: "bgConditional",
          hideable: true,
          options: [
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
            {
              name: "no",
              value: false,
              title: "No",
            },
          ],
          fields: [
            {
              title: "Background Image",
              type: "ImageField",
              key: "backgroundImage",
              hideable: true,
              condition: true,
            },
          ],
        },
        {
          title: "Social share",
          type: "RadioGroup",
          key: "socialShare",
          options: [
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
            {
              name: "no",
              value: false,
              title: "No",
            },
          ],
        },
        {
          title: "Downloadable resources",
          type: "FieldGroup",
          key: "downloadableRes",
          fields: [
            {
              title: "Title (Closed)",
              type: "TextField",
              key: "titleClosed",
              mandatory: true,
              helptext:
                "This title will appear while the user does not submit the form.",
              humanReadable: true,
            },
            {
              title: "Title (Opened)",
              type: "TextField",
              key: "titleOpened",
              mandatory: true,
              helptext:
                "This title will appear when the user does submit the form.",
              humanReadable: true,
            },
            {
              title: "Image",
              type: "ComponentContainer",
              whiteList: ["Image"],
              key: "image",
            },
            {
              title: "FORM",
              type: "ComponentContainer",
              mandatory: true,
              whiteList: ["IntroFormElement"],
              key: "componentModules",
            },
            {
              title: "Cards",
              type: "ComponentArray",
              mandatory: true,
              maxItems: null,
              key: "elements",
              whiteList: ["DownloadDocument"],
            },
          ],
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "DownloadForm",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { content: "Title", tag: "h4" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    detail:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    bgConditional: false,
    backgroundImage: { ...DEFAULT_IMAGE_FIELD },
    socialShare: true,
    titleClosed: "Enter email to access the reports.",
    titleOpened: "Now you can download the resources.",
    image: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
    componentModules: { component: "DownloadFormComponent" },
    elements: [
      {
        component: "DownloadDocument",
        title: { content: "Title", tag: "h4" },
        image: null,
        fileType: true,
        documentLink: {
          external: true,
          href: "",
          icon: "",
          label: "",
          newTab: false,
          noFollow: false,
          linkTo: null,
        },
      },
    ],
  },
  thumbnails: {
    "1x": DownloadForm,
    "2x": DownloadForm2x,
  },
}
