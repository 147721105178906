import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"
import { CTACard, CTACard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "CTA Card",
  component: "CTACard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Element type",
          type: "ConditionalField",
          key: "elementType",
          mandatory: true,
          required: true,
          helptext: "Type of image",
          options: [
            {
              value: "profile",
              title: "Profile",
              name: "profile",
            },
            {
              value: "icon",
              title: "Icon",
              name: "icon",
            },
          ],
          fields: [
            {
              title: "Icon",
              type: "ImageField",
              key: "icon",
              mandatory: true,
              condition: "icon",
            },
            {
              title: "Image",
              type: "ImageField",
              key: "image",
              mandatory: true,
              condition: "profile",
            },
            {
              title: "Name",
              type: "TextArea",
              key: "name",
              hideable: true,
              condition: "profile",
            },
            {
              title: "Position",
              type: "TextArea",
              key: "position",
              hideable: true,
              condition: "profile",
              humanReadable: true,
            },
          ],
        },
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Description",
          type: "RichText",
          html: true,
          key: "description",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "CTACard",
    title: {
      content: "Complete your entire application process online",
      tag: "h4",
    },
    description:
      "Keeping everyone safe and healthy is our number one priority, but we understand that planning for your future must continue. With this in mind, we have implemented an application process that is 100% online.",
    link: {
      component: "Link",
      text: "Link",
      style: "primary",
      url: {
        url: "",
        href: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    icon: {
      ...DEFAULT_IMAGE_FIELD,
    },

    image: {
      ...DEFAULT_IMAGE_FIELD,
    },

    elementType: "profile",
    name: "Name",
    position: "Position",
  },
  thumbnails: {
    "1x": CTACard,
    "2x": CTACard2x,
  },
}
