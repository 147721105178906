import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Blog Card",
  component: "BlogCard",
  dataPacks: null,
  configTabs: [
    { title: "content", fields: [] },
    { title: "config", fields: [] },
  ],
  default: {
    component: "BlogCard",
    image: DEFAULT_IMAGE_COMPONENT.file.url,
  },
}
