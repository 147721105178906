import { getThemeOptions } from "@helpers/schemas"
import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { HorizontalTabs, HorizontalTabs2x } from "@thumbnails/Modules"
import { BasicContentLayout1 } from "@layouts"

export default {
  schemaType: "module",
  displayName: "Horizontal Tabs",
  component: "HorizontalTabs",
  category: "interactive",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          hideable: true,
          advanced: true,
          default: { tag: "h2", content: "Title" },
          advanced: true,
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Horizontal Tab Element",
          type: "ComponentArray",
          maxItems: 5,
          whiteList: ["HorizontalTabElement"],
          key: "elements",
          mandatory: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: BasicContentLayout1,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "HorizontalTabs",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    title: { content: "Lorem Ipsum", tag: "h4" },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    detail: null,
    elements: [
      {
        component: "HorizontalTabElement",
        title: { content: "Title", tag: "h3" },
        image: "",
        componentModules: [
          {
            component: "BasicContent",
            title: {
              content: "Default title",
              tag: "h1",
            },
            subtitle:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. ",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
            primaryLink: {
              component: "LinkModal",
              style: "primary",
              link: {
                text: "Link",
                linkType: "url",
                url: {
                  linkToURL: null,
                  newTab: false,
                  noFollow: false,
                },
                modal: {
                  requestInfo: {
                    component: "RequestInfo",
                  },
                  floatingCTAMail: {
                    component: "FloatingCTAMail",
                  },
                  floatingCTALightbox: {
                    component: "FloatingCTALightbox",
                  },
                  floatingCTAVideo: {
                    component: "FloatingCTAVideo",
                  },
                },
              },
            },
            secondaryLink: {
              component: "LinkModal",
            },
            additionalContent: {
              image: {
                component: "LinkableImage",
                ...DEFAULT_IMAGE_COMPONENT,
                veil: 0,
              },
              video: {
                component: "Video",
              },
            },
            theme: "defaultAlt",
            layout: "L001",
            moduleOrder: null,
          },
        ],
      },
    ],
  },
  thumbnails: {
    "1x": HorizontalTabs,
    "2x": HorizontalTabs2x,
  },
}
