import { allModulesWhitelist } from "@helpers/schemas"
import { ProjectDetail, ProjectDetail2x } from "@thumbnails/Templates"

// Defaults from modules
import CardCollection from "@schemas/modules/CardCollection"
import HeroSlider from "@schemas/modules/HeroSlider"

const twoDigits = num => {
  return `${num < 10 ? "0" : ""}${num}`
}

const today = () => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}/${twoDigits(month)}/${twoDigits(day)}`
}

export default {
  schemaType: "template",
  displayName: "Project Detail",
  component: "ProjectDetail",
  dataPacks: ["PROJECTS"],
  type: { label: "Projects", value: "projects", mode: "detail" },
  content: [
    {
      key: "categories",
      title: "Select Categories",
      type: "MultiCheckSelectGroup",
      filled: true,
      hideable: true,
      note: "Go to the Category section (Global or Site) to create as many as you need.",
      elements: [
        {
          key: "units",
          placeholder: "Unit",
          source: "UNIT",
        },
        {
          key: "schools",
          placeholder: "School",
          source: "SCHOOLS",
        },
        {
          key: "pathways",
          placeholder: "Pathway",
          source: "PATHWAYS",
        },
        {
          key: "programs",
          placeholder: "Programs",
          source: "PROGRAMS",
        },
        {
          key: "subjectAreas",
          placeholder: "Subject Area & Values",
          source: "SUBJECT_AREA",
        },
        {
          key: "sector",
          placeholder: "Sector",
          source: "SECTOR",
        },
        {
          key: "regions",
          placeholder: "Region",
          source: "REGION",
        },
        {
          key: "centers",
          placeholder: "Centers & Departments",
          source: "CENTERS",
        },
        {
          key: "topics",
          placeholder: "Topic",
          source: "TOPIC",
        },
      ],
    },
    {
      key: "heroSection",
      title: "Hero Content",
      type: "ComponentArray",
      maxItems: 1,
      mandatory: true,
      whiteList: [
        "HeroText",
        "HeroImage",
        "HeroCarousel",
        "HeroClaim",
        "HeroVideo",
        "HeroQuote",
        "HeroSlider",
      ],
    },
    {
      key: "projectData",
      title: "Project Data",
      type: "FieldGroup",
      fields: [
        {
          title: "Date",
          type: "DateField",
          mandatory: true,
          key: "dateTime",
          helptext: "This date will be used to sort in distributors and lists.",
        },
        {
          key: "image",
          title: "Image",
          type: "ImageField",
          mandatory: true,
          helptext: "Shown on the distributor card",
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          html: true,
          hideable: true,
          humanReadable: true,
        },
      ],
    },
    {
      key: "quickFacts",
      title: "Quick Facts",
      type: "FieldGroup",
      fields: [
        {
          key: "program",
          title: "Program",
          type: "AsyncSelect",
          source: "PROGRAMS",
          hideable: true,
        },
        {
          key: "author",
          title: "Author",
          type: "ConditionalField",
          hideable: true,
          options: [
            {
              value: "teacher",
              title: "Teacher",
              name: "teacher",
            },
            {
              value: "student",
              title: "Student",
              name: "student",
            },
          ],
          fields: [
            {
              key: "data",
              type: "ReferenceField",
              title: "Teacher (Automatic)",
              sources: [{ structuredData: "TEACHERS" }],
              selectionType: ["manual"],
              condition: "teacher",
              helptext: "Shown on the distributor card",
            },
            {
              key: "authorTeacherManual",
              title: "Teacher (Manual)",
              type: "TextField",
              condition: "teacher",
              helptext: "Shown on the distributor card",
            },
            {
              key: "authorStudentManual",
              title: "Student (Manual)",
              type: "TextField",
              condition: "student",
              helptext: "Shown on the distributor card",
            },
          ],
        },
        {
          key: "tutor",
          title: "Tutor",
          type: "ConditionalField",
          hideable: true,
          options: [
            {
              value: "manual",
              title: "Manual",
              name: "manual",
            },
            {
              value: "teacherTutor",
              title: "Teacher",
              name: "teacherTutor",
            },
          ],
          fields: [
            {
              key: "tutorManual",
              type: "TextField",
              condition: "manual",
            },
            {
              key: "tutorData",
              type: "ReferenceField",
              sources: [{ structuredData: "TEACHERS" }],
              selectionType: ["manual"],
              condition: "teacherTutor",
            },
          ],
        },

        {
          key: "subject",
          title: "Subject",
          type: "TextField",
          hideable: true,
          humanReadable: true,
        },
        {
          key: "date",
          title: "Date",
          type: "TextField",
          hideable: true,
        },
        {
          key: "projectLink",
          title: "Project Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          hideable: true,
        },
      ],
    },
    {
      key: "moreQuickFacts",
      title: "More Quick Facts",
      type: "ComponentArray",
      maxItems: null,
      whiteList: ["QuickFact"],
    },
    {
      key: "mainContent",
      title: "Main Content Container",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
    },
    {
      key: "socialShare",
      title: "Social Share",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["SocialShare"],
    },
    {
      key: "relatedContent",
      title: "Related Content Container",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "ProjectDetail",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ ...HeroSlider.default }],
      sectionPosition: 1,
    },
    dateTime: today(),
    image: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    author: "teacher",
    data: {
      mode: "manual",
      fixed: [],
    },
    authorTeacherManual: "",
    authorStudentManual: "",
    tutor: "manual",
    tutorManual: "",
    tutorData: {
      mode: "manual",
      fixed: [],
    },
    projectLink: {
      component: "Link",
      text: "Link",
      style: "link",
      url: {
        newTab: false,
        noFollow: true,
        internal: true,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
    subject: "Lorem ipsum",
    date: "Lorem ipsum",
    moreQuickFacts: {
      component: "Section",
      name: "More Quick Facts",
      modules: [],
      sectionPosition: 2,
    },
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [{ ...CardCollection.default }],
    },
    socialShare: {
      component: "Section",
      name: "Show Social Share",
      modules: [],
    },
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
    },
    hasDistributorData: true,
    breadCrumb: [],
  },
  thumbnails: {
    "1x": ProjectDetail,
    "2x": ProjectDetail2x,
  },
}
