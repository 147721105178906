import { Carousel } from "@components"
import { useStyledSubTheme, LdJson } from "@hooks"
import { ThemeProvider } from "@contexts"

const HeroCarousel = props => {
  const { anchorID, elements, anchorText } = props
  const accent = useStyledSubTheme({ name: "accent" })

  return (
    <ThemeProvider theme={accent}>
      <div
        id={anchorID || undefined}
        style={{
          position: "relative",
          height: "95vh",
        }}
      >
        <Carousel
          elements={elements}
          autoplay={true}
          arrows
          dots
          anchorText={anchorText}
        />
      </div>

      {elements && (
        <LdJson
          data={{
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: elements.map((slide, idx) => ({
              "@type": "ListItem",
              position: idx,
              item: {
                "@type": "NewsArticle",
                headline: slide?.title.content
                  ? slide?.title.content.replace(/"/g, '\\"')
                  : "",
                image: slide?.backgroundImage?.file?.url
                  ? [slide?.backgroundImage?.file?.url]
                  : null,
                author: [
                  {
                    "@type": "Organization",
                    name: "IE University",
                    url: "https://www.ie.edu",
                  },
                ],
              },
            })),
          }}
        />
      )}
    </ThemeProvider>
  )
}

export default HeroCarousel
