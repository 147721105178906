import { allSectionList, getThemeOptions } from "@helpers/schemas"
import { PersonDistributor, PersonDistributor2x } from "@thumbnails/Modules"
import {
  CardCollectionLayout1,
  CardCollectionLayout2,
  CardCollectionLayout3,
  CardCollectionLayout4,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Person Distributor",
  component: "PersonDistributor",
  category: "people",
  dataPacks: ["PEOPLE"],
  sectionList: allSectionList,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h4", content: "Lorem ipsum" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Show data on all cards",
          type: "RadioGroup",
          key: "showDataAllCards",
          mandatory: false,
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Card Background",
          type: "ConditionalField",
          key: "cardBackground",
          mandatory: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              title: "Use person image for background",
              type: "RadioGroup",
              key: "usePersonImageAsBg",
              mandatory: true,
              condition: true,
              options: [
                {
                  value: true,
                  title: "Yes",
                  name: "Yes",
                },
                {
                  value: false,
                  title: "No",
                  name: "No",
                },
              ],
            },
          ],
        },
        {
          title: "Featured Card",
          type: "RadioGroup",
          key: "featured",
          mandatory: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
        },
        {
          type: "ReferenceField",
          sources: [
            { structuredData: "PEOPLE" },
            { structuredData: "TEACHERS" },
          ],
          key: "data",
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
          humanReadable: true,
        },
        {
          title: "Add 'load more' button to load items 6 at a time",
          type: "RadioGroup",
          key: "showLoadMore",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            {
              value: "h2",
              label: "H2",
            },
            {
              value: "h3",
              label: "H3",
            },
            {
              value: "h4",
              label: "H4",
            },
            {
              value: "span",
              label: "span",
            },
          ],
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CardCollectionLayout1,
            },
            {
              value: "L002",
              img: CardCollectionLayout2,
            },
            {
              value: "L003",
              img: CardCollectionLayout3,
            },
            {
              value: "L004",
              img: CardCollectionLayout4,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "PersonDistributor",
    anchorID: null,
    theme: "defaultAlt",
    verticalSpacing: "medium",
    layout: "L002",
    showLoadMore: true,
    title: {
      content: "Lorem Ipsum",
      tag: "h4",
    },
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    cardBackground: false,
    showDataAllCards: false,
    usePersonImageAsBg: false,
    featured: true,
    data: {
      mode: "auto",
      order: "recent",
      sources: [
        {
          structuredData: "PEOPLE",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 1,
      preferenceLanguage: false,
    },
    noResultsText: "Sorry, no results were found.",
    cardTitleTag: "h3",
    groupingLink: {
      component: "Link",
      text: "See All",
      style: "link",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": PersonDistributor,
    "2x": PersonDistributor2x,
  },
}
