import { HeaderSection, HeaderSection2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Header Section",
  component: "HeaderSection",
  dataPacks: null,
  category: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Modules",
          type: "ComponentArray",
          maxItems: null,
          whiteList: ["Header"],
          key: "modules",
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "Header Section",
    modules: [],
  },
  thumbnails: {
    "1x": HeaderSection,
    "2x": HeaderSection2x,
  },
}
