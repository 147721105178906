import { AddressCard, AddressCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Address Card",
  component: "AddressCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Address",
          type: "TextField",
          key: "address",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Email",
          type: "TextField",
          key: "email",
          hideable: true,
        },
        {
          title: "Phone",
          type: "TextField",
          key: "phone",
          hideable: true,
        },
        {
          title: "Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "link",
          hideable: true,
        },
        {
          title: "Map",
          type: "ComponentContainer",
          whiteList: ["Map"],
          key: "map",
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: "AddressCard",
    title: {
      content: "Title",
      tag: "h1",
    },
    address: "Lorem ipsum dolor sit amet, 00",
    email: "email@email.com",
    phone: "+44 (0) 00 00 00 00 00",
    elementStyle: "MAP",
    link: {
      component: "Link",
    },
    map: {
      component: "Map",
    },
  },
  thumbnails: {
    "1x": AddressCard,
    "2x": AddressCard2x,
  },
}
