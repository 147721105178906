import { getThemeOptions } from "@helpers/schemas"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Centered Panel",
  component: "CenteredPanel",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          mandatory: true,
          hideable: true,
          advanced: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "TextArea",
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentLight",
            "accentMid",
          ]),
          columns: 8,
        },
        {
          title: "Featured Text Style",
          type: "RadioGroup",
          key: "featuredTextStyle",
          helptext: "",
          options: [
            {
              name: "OPT01",
              value: "OPT01",
              title: "Cifra grande",
            },
            {
              name: "OPT02",
              value: "OPT02",
              title: "Texto largo",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "CenteredPanel",
    title: {
      content: "Title",
      tag: "h4",
    },
    subtitle: "Lorem Ipsum",
    theme: "accent",
    featuredTextStyle: "STYLE01",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
