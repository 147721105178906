import { LocationMap, LocationMap2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Map",
  component: "Map",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Latitude",
          type: "TextField",
          key: "latitude",
          mandatory: true,
        },
        {
          title: "Longitude",
          type: "TextField",
          key: "longitude",
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: "Map",
    latitude: "40.417139",
    longitude: "-3.703537",
  },
  thumbnails: {
    "1x": LocationMap,
    "2x": LocationMap2x,
  },
}
