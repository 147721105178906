import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Anchor link",
  component: "AnchorLink",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Text",
          type: "TextField",
          key: "text",
          required: true,
          humanReadable: true,
        },
        {
          title: "URL",
          type: "UrlField",
          key: "url",
          advanced: true,
          required: true,
        },
      ],
    },
  ],
  default: {
    component: "AnchorLink",
    text: "Link",
    url: {
      external: true,
      href: "",
      icon: "",
      label: "",
      newTab: false,
      noFollow: false,
      linkTo: null,
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
