import { getThemeOptions } from "@helpers/schemas"
import { TypographicVideo, TypographicVideo2x } from "@thumbnails/Modules"

export default {
  schemaType: "module",
  displayName: "Typographic Video",
  component: "TypographicVideo",
  dataPacks: null,
  category: "content",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Video",
          type: "TextField",
          key: "video",
          mandatory: true,
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          mandatory: true,
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "default",
            "accent",
            "accentAlt",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "TypographicVideo",
    anchorID: null,
    theme: "accent",

    title: "Title",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
    groupingLink: {
      component: "Link",
      text: "Lorem ipsum",
      style: "primary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": TypographicVideo,
    "2x": TypographicVideo2x,
  },
}
