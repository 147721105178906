import { MilestoneCypher, MilestoneCypher2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Milestone Cypher",
  component: "MilestoneCypher",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Cypher",
          type: "TextField",
          key: "cypher",
          mandatory: true,
        },
        {
          title: "Text",
          type: "TextField",
          key: "text",
          mandatory: true,
          humanReadable: true,
        },
      ],
    },
  ],
  default: {
    component: "MilestoneCypher",

    cypher: "1980",
    text: "Nombre de cifra",
  },
  thumbnails: {
    "1x": MilestoneCypher,
    "2x": MilestoneCypher2x,
  },
}
