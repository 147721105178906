import { MilestoneSlide, MilestoneSlide2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Milestone Slide",
  component: "MilestoneSlide",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Year",
          type: "TextField",
          key: "year",
          hideable: true,
        },
        {
          title: "Elements",
          type: "ComponentArray",
          key: "elements",
          whiteList: ["TabSlide"],
          mandatory: true,
        },
        {
          title: "Social share",
          type: "RadioGroup",
          key: "socialShare",
          hideable: true,
          options: [
            {
              name: "yes",
              value: true,
              title: "Yes",
            },
            {
              name: "no",
              value: false,
              title: "No",
            },
          ],
        },
      ],
    },
  ],
  default: {
    component: "MilestoneSlide",
    year: "Year",
    elements: [],
    socialShare: null,
  },
  thumbnails: {
    "1x": MilestoneSlide,
    "2x": MilestoneSlide2x,
  },
}
