import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "External Event Card",
  component: "ExternalEventCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          key: "guid",
          title: "Guid",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "startDate",
          title: "Start Date",
          type: "DateField",
          mandatory: true,
        },
        {
          key: "endDate",
          title: "End Date",
          type: "DateField",
          mandatory: true,
        },
        {
          key: "title",
          title: "Title",
          type: "TextField",
          mandatory: true,
          humanReadable: true,
        },
        {
          key: "description",
          title: "Description",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "abstract",
          title: "Abstract",
          type: "RichText",
          html: true,
          humanReadable: true,
        },
        {
          key: "address",
          title: "Address",
          type: "TextField",
        },
        {
          key: "format",
          title: "Format",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "place",
          title: "Place",
          type: "TextField",
        },
        {
          key: "city",
          title: "City",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "country",
          title: "Country",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "region",
          title: "Region",
          type: "TextField",
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          mandatory: true,
          advanced: true,
        },
      ],
    },
  ],
  default: {
    component: "ExternalEventCard",
    guid: "",
    title: "",
    startDate: null,
    endDate: null,
    description: "",
    abstract: "",
    address: null,
    format: null,
    place: null,
    city: null,
    country: null,
    region: null,
    link: "",
    addToCalendar: {
      component: "AddToCalendar",
    },
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
