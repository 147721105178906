import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  schemaType: "component",
  displayName: "Image",
  component: "LinkableImage",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "File",
          type: "ImageField",
          key: "file",
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          humanReadable: true,
        },
        {
          title: "Veil percentage",
          type: "SliderField",
          key: "veil",
        },
        {
          title: "Link image to URL",
          type: "UrlField",
          advanced: true,
          key: "url",
        },
      ],
    },
  ],
  default: {
    component: "LinkableImage",
    ...DEFAULT_IMAGE_COMPONENT,
    veil: 0,
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
