import { BasicCard, BasicCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "FORM",
  component: "DownloadFormComponent",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Hubspot Portal ID",
          type: "TextField",
          key: "scriptCodePortalId",
        },
        {
          title: "Hubspot Form ID",
          type: "TextField",
          key: "scriptCodeFormId",
        },
        {
          key: "scriptForm",
          title: "Form script",
          type: "TextArea",
          helptext: "The code of the script.",
        },
      ],
    },
  ],
  default: {
    component: "DownloadFormComponent",
  },
  thumbnails: {
    "1x": BasicCard,
    "2x": BasicCard2x,
  },
}
