import { allModulesWhitelist } from "@helpers/schemas"
import { AcademicVideo, AcademicVideo2x } from "@thumbnails/Templates"

export default {
  displayName: "Academic Video",
  component: "AcademicVideo",
  dataPacks: null,
  type: { label: "Static", value: "static" },
  schemaType: "template",
  content: [
    {
      title: "Hero Section",
      key: "heroSection",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: [
        "HeroCarousel",
        "HeroClaim",
        "HeroImage",
        "HeroQuote",
        "HeroText",
        "HeroVideo",
      ],
      mandatory: true,
    },
    {
      title: "Video",
      key: "video",
      type: "TextField",
      mandatory: true,
    },
    {
      title: "Video Image",
      key: "image",
      type: "ComponentContainer",
      whiteList: ["Image"],
      hideable: true,
    },
    {
      title: "Date",
      key: "videoDate",
      type: "DateField",
      mandatory: true,
    },
    {
      title: "Author",
      key: "author",
      type: "ConditionalField",
      mandatory: true,
      options: [
        {
          value: "custom",
          title: "Custom",
          name: "custom",
        },
        {
          value: "automatic",
          title: "Automatic",
          name: "automatic",
        },
      ],
      fields: [
        {
          title: "Author name",
          key: "authorName",
          type: "TextField",
          condition: "custom",
          mandatory: true,
        },
        {
          title: "Author URL",
          key: "authorURL",
          type: "UrlField",
          advanced: true,
          condition: "custom",
          hideable: true,
        },
        {
          title: "Teachers",
          key: "data",
          type: "ReferenceField",
          sources: [{ structuredData: "TEACHERS" }],
          selectionType: ["manual"],
          maxItems: 1,
          condition: "automatic",
          mandatory: true,
        },
      ],
    },
    {
      title: "Content",
      key: "content",
      type: "RichText",
      html: true,
      hideable: true,
      humanReadable: true,
    },
    {
      title: "Show transcript",
      key: "showTranscript",
      type: "ConditionalField",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
      fields: [
        {
          title: "Transcript",
          key: "transcriptLabel",
          type: "TextField",
          mandatory: true,
          condition: true,
          humanReadable: true,
        },
        {
          title: "Title",
          key: "transcriptTitle",
          type: "HeadingField",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          condition: true,
          humanReadable: true,
        },
        {
          title: "Content of transcript",
          key: "transcriptContent",
          type: "RichText",
          html: true,
          mandatory: true,
          condition: true,
          humanReadable: true,
        },
      ],
    },
    {
      title: "Show quiz",
      key: "showQuiz",
      type: "ConditionalField",
      mandatory: true,
      options: [
        {
          value: true,
          title: "Yes",
          name: "yes",
        },
        {
          value: false,
          title: "No",
          name: "no",
        },
      ],
      fields: [
        {
          title: "Quiz",
          key: "quizLabel",
          type: "TextField",
          mandatory: true,
          condition: true,
          humanReadable: true,
        },
        {
          title: "Title",
          key: "quizTitle",
          type: "HeadingField",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          condition: true,
          humanReadable: true,
        },
        {
          title: "Quiz URL",
          key: "quizURL",
          type: "TextField",
          mandatory: true,
          condition: true,
        },
      ],
    },
    {
      title: "Social Share",
      key: "socialShare",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: ["SocialShare"],
    },
    {
      title: "Related Content",
      key: "relatedContent",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
    },
  ],

  default: {
    type: "template",
    templateType: "AcademicVideo",
    theme: "defaultAlt",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [{ component: "HeroText" }],
      sectionPosition: 1,
    },
    image: {
      component: "Image",
    },
    videoDate: null,
    author: "custom",
    data: {
      mode: "manual",
      fixed: [],
    },
    hasDistributorData: true,
    showTranscript: true,
    transcriptLabel: "Transcript",
    showQuiz: true,
    quizLabel: "Take the quiz",
    socialShare: {
      component: "Section",
      name: "Social Share",
      modules: [],
      sectionPosition: 2,
    },
    relatedContent: {
      component: "Section",
      name: "Related Content",
      modules: [],
      sectionPosition: 3,
    },
  },
  thumbnails: {
    "1x": AcademicVideo,
    "2x": AcademicVideo2x,
  },
}
