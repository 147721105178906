import { IconCard, IconCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "Step Card",
  component: "StepCard",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          mandatory: true,
          default: { tag: "h4", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          key: "link",
          title: "Link",
          type: "UrlField",
          advanced: true,
        },
      ],
    },
  ],
  default: {
    component: "StepCard",
    title: {
      content: "Lorem Ipsum",
      tag: "h4",
    },
    link: {
      url: "",
      text: "",
    },
  },
  thumbnails: {
    "1x": IconCard,
    "2x": IconCard2x,
  },
}
