import { DEFAULT_IMAGE_COMPONENT } from "@constants/griddoImage"
import { BasicCard, BasicCard2x } from "@thumbnails/Components"

export default {
  schemaType: "component",
  displayName: "FORM",
  component: "IntroFormElement",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Form Title",
          key: "formTitle",
          hideable: true,
          humanReadable: true,
          type: "HeadingField",
          advanced: true,
          default: { tag: "span", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
        },
        {
          title: "Form Image",
          type: "ConditionalField",
          key: "hasImage",
          mandatory: true,
          required: true,
          options: [
            {
              value: true,
              title: "Yes",
              name: "Yes",
            },
            {
              value: false,
              title: "No",
              name: "No",
            },
          ],
          fields: [
            {
              condition: true,
              title: "Form Image",
              type: "ComponentContainer",
              whiteList: ["Image"],
              key: "formImage",
              helptext: "Recommended minimum image size: 2508x1030",
            },
          ],
        },
        {
          title: "Hubspot Portal ID",
          type: "TextField",
          key: "scriptCodePortalId",
        },
        {
          title: "Hubspot Form ID",
          type: "TextField",
          key: "scriptCodeFormId",
        },
        {
          key: "scriptForm",
          title: "Form script",
          type: "TextArea",
          helptext: "The code of the script.",
        },
      ],
    },
  ],
  default: {
    component: "IntroFormElement",
    formTitle: { tag: "span", content: "Title" },
    hasImage: true,
    scriptCodePortalId: null,
    scriptCodeFormId: null,
    formImage: {
      component: "Image",
      ...DEFAULT_IMAGE_COMPONENT,
    },
  },
  thumbnails: {
    "1x": BasicCard,
    "2x": BasicCard2x,
  },
}
