import Anchor from "@elements/Anchor"
import MaxWidth from "@elements/MaxWidth"
import { useStyledSubTheme } from "@hooks"
import { Box, Flex, Stack, Text } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import React from "react"
import styled, { ThemeProvider } from "styled-components"
import RecursiveMenu from "./RecursiveMenu"
import { searchResultsPage } from "@helpers"
import { useRenderer, useI18n } from "@hooks"
import { LinkWrapper } from "@components"
import "./styles.css"

const StyledAnchor = styled(Anchor)`
  color: ${p => p.theme.colors.inverse01};
  svg {
    transition: transform 0.25s ease-out;
  }
  &:hover {
    svg {
      transform: translateY(-2px);
      opacity: 0.8;
    }
  }
`

const MainWrapper = styled(Box)`
  display: none;
  visibility: hidden;
  ${theme("mq.xl")} {
    display: block;
    visibility: visible;
  }
`

const TopNav = ({
  socials,
  languageId,
  pageLanguages,
  externalLinks,
  showBot,
  languageNames,
}) => {
  const botUrl = searchResultsPage

  // Language
  const { getNestedTranslation: t } = useI18n()
  const goToMain = t("initial.goToMain", "Skip to main content")
  function capitalizarPrimeraLetra(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const { isSSG } = useRenderer()
  const subtheme = useStyledSubTheme({ name: "accent" })

  const showBotIcon = showBot && (!isSSG || botUrl)

  return (
    <ThemeProvider theme={subtheme}>
      <a
        className="skip-main"
        href="#main-content"
        style={{
          "--color": subtheme?.colors?.brand01,
        }}
      >
        {goToMain}
      </a>
      <MainWrapper color="text01" bg="ui01">
        <MaxWidth fluid>
          <Flex height="56px" alignItems="center">
            <Stack hs="xl" mr="auto">
              {/* Social Icons */}
              {socials && (
                <Stack hs="s" alignItems="center">
                  <nav>
                    {socials.map((social, idx) => (
                      <StyledAnchor
                        key={idx}
                        href={social.url}
                        newTab
                        title={capitalizarPrimeraLetra(social.icon)}
                      >
                        <Icons
                          name={social.icon}
                          title={social.icon}
                          height="24px"
                          fill="text01"
                          role="img"
                        />
                      </StyledAnchor>
                    ))}
                  </nav>
                </Stack>
              )}

              {/* Languages */}
              {pageLanguages?.length > 1 && languageNames && (
                <Stack hs="xs" alignItems="center">
                  {pageLanguages.map((lang, idx) => {
                    const isOtherLanguage = languageId !== lang.languageId
                    const LinkText = () => (
                      <Text
                        display="flex"
                        textStyle="tinyxs"
                        color="text01"
                        style={isOtherLanguage ? { fontWeight: "bold" } : {}}
                        css={`
                          &::before {
                            display: block;
                            content: ${idx > 0 ? '""' : "unset"};
                            float: left;
                            margin-right: 16px;
                            width: 1px;
                            height: 10px;
                            background-color: ${theme("colors.text03")};
                            align-self: center;
                          }
                        `}
                      >
                        {languageNames[lang?.locale]}
                      </Text>
                    )
                    return isOtherLanguage ? (
                      <LinkWrapper
                        key={idx}
                        href={lang.url}
                        css={`
                          &:hover {
                            opacity: 0.75;
                          }
                        `}
                      >
                        <LinkText />
                      </LinkWrapper>
                    ) : (
                      <LinkText key={idx} />
                    )
                  })}
                </Stack>
              )}
            </Stack>

            {/* Recursive Menu */}
            {externalLinks && (
              <RecursiveMenu externalLinks={externalLinks} desktop />
            )}

            {showBotIcon && (
              <Anchor
                linkToURL={botUrl}
                newTab
                title="Chatbot"
                external={false}
                internal={false}
              >
                <Icons
                  name="bot"
                  ml="s"
                  height="24px"
                  role="img"
                  aria-label={t("bot", "Bot")}
                  css={`
                    margin-top: 5px;
                  `}
                />
              </Anchor>
            )}
          </Flex>
        </MaxWidth>
      </MainWrapper>
    </ThemeProvider>
  )
}

export default TopNav
