import { NoThumbnail, NoThumbnail2x } from "@thumbnails"

export default {
  displayName: "Slim Social Share",
  component: "SlimSocialShare",
  schemaType: "component",
  dataPacks: null,
  configTabs: [
    { title: "content", fields: [] },
    { title: "config", fields: [] },
  ],
  default: {
    component: "SlimSocialShare",
  },
  thumbnails: {
    "1x": NoThumbnail,
    "2x": NoThumbnail2x,
  },
}
