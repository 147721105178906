import { DEFAULT_IMAGE_FIELD } from "@constants/griddoImage"

export default {
  schemaType: "component",
  displayName: "Project Card",
  component: "ProjectCard",
  dataPacks: null,
  configTabs: [
    { title: "content", fields: [] },
    { title: "config", fields: [] },
  ],
  default: {
    component: "ProjectCard",
    image: DEFAULT_IMAGE_FIELD,
  },
}
