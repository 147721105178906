import React from "react"
import styled from "styled-components"
import { Box, Flex, Text } from "@sqymagma/elements"
import { themeGet as theme } from "@styled-system/theme-get"
import Icons from "@icons"
import { LinkWrapper } from "@components"
import MaxWidth from "@elements/MaxWidth"
import { useSite } from "@hooks"

const StyledLinkWrapper = styled(LinkWrapper)`
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
  opacity: ${p => (p.$active ? 0.5 : 1)};
`

const SubNavList = styled.ul`
  display: grid;
  grid-template-columns: ${p =>
    p.$siteID && p.$siteID === p.$lifelongLearning
      ? "repeat(4, 1fr)"
      : "repeat(3, 1fr)"};
  grid-column-gap: ${theme("spacing.xxl")};
`

const SubNavItem = styled.li`
  color: ${theme("colors.inverse01")};
  margin-top: ${theme("spacing.l")};
`

const SubLinkItem = styled(Text)`
  opacity: ${p => (p.$active ? 0.5 : 1)};
`

const DesktopSubMenu = ({ menuItem, isActiveLink, customSetMenuBg }) => {
  const hasSpecialLinks = menuItem?.children.find(subItem => subItem.special)
  const { siteId } = useSite()
  const IE_LIFELONGLEARNING_ID = 35

  return (
    <MaxWidth fluid>
      <SubNavList
        data-testid="desktopSubmenu"
        $siteID={siteId}
        $lifelongLearning={IE_LIFELONGLEARNING_ID}
      >
        {menuItem?.children?.map((subItem, subIdx) => {
          const isChildColumn = subItem?.config?.headerStyle === "S002"
          const LinkItem = () => (
            <>
              <SubLinkItem
                textStyle="headingxs"
                color="text01"
                mb="xxs"
                $active={subItem?.url && isActiveLink(subItem)}
                data-testid="desktopSubitem"
                role="menuitem"
                aria-haspopup="true"
              >
                {subItem.label}
              </SubLinkItem>

              {subItem?.auxText && (
                <Box
                  mb="xxs"
                  mt="xxs"
                  css={`
                    cursor: auto;
                  `}
                >
                  <Text textStyle="tinym" color="text01">
                    {subItem.auxText}
                  </Text>
                </Box>
              )}
            </>
          )
          return (
            !subItem.special && (
              <SubNavItem
                key={subIdx}
                id={`subNavItem-${subIdx}`}
                role={subItem?.children ? "menu" : "menuitem"}
                aria-haspopup="true"
                tabindex="0"
                onKeyDown={event => {
                  if (event.key === "ArrowRight") {
                    customSetMenuBg(true, subIdx)
                  }
                  if (event.key === "ArrowLeft" || event.key === "Escape") {
                    customSetMenuBg(false, subIdx)
                  }
                }}
              >
                {subItem?.url ? (
                  <Box
                    css={`
                      a {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 5px;
                        margin-right: 10px !important;
                      }
                      :focus-visible::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border: 5px solid
                          ${p => p.theme.colors.interactive01Focus};
                      }
                    `}
                  >
                    <StyledLinkWrapper
                      title={subItem?.label}
                      anchor={subItem?.url?.anchor}
                      href={subItem?.url?.href}
                      linkToURL={subItem?.url?.linkToURL}
                      newTab={subItem?.url?.newTab}
                      noFollow={subItem?.url?.noFollow}
                    >
                      <LinkItem />
                    </StyledLinkWrapper>
                  </Box>
                ) : (
                  <Box
                    css={`
                      a {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 5px;
                        margin-right: 10px !important;
                      }
                      :focus-visible::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border: 5px solid
                          ${p => p.theme.colors.interactive01Focus};
                      }
                    `}
                  >
                    <LinkItem />
                  </Box>
                )}
                <Box as="ul" mt="xxs" role="menuitem">
                  {subItem?.children?.map((subSubItem, thirdIdx) => {
                    const ChildrenLinkItem = () => (
                      <Text
                        color="#888A8C"
                        textStyle="navigationl"
                        data-testid="subitemChild"
                      >
                        {isChildColumn
                          ? subSubItem.label
                          : subSubItem.label +
                            (thirdIdx !== subItem?.children.length - 1
                              ? "\u2060, "
                              : "")}
                      </Text>
                    )
                    return (
                      <Box
                        key={thirdIdx}
                        display={isChildColumn ? "block" : "inline-block"}
                        as="li"
                        mr="7px"
                        css={`
                          cursor: pointer;
                          transition: 0.3s ease;
                          &:hover {
                            opacity: 0.5;
                          }
                          opacity: ${subSubItem?.url && isActiveLink(subSubItem)
                            ? 0.5
                            : 1};
                          a {
                            display: inline-block;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 5px;
                            margin-right: 10px !important;
                          }
                        `}
                      >
                        {subSubItem?.url ? (
                          <LinkWrapper
                            id={`childrenLinkItem-${thirdIdx}-${thirdIdx}`}
                            title={subSubItem?.label}
                            anchor={subSubItem?.url?.anchor}
                            href={subSubItem?.url?.href}
                            linkToURL={subSubItem?.url?.linkToURL}
                            newTab={subSubItem?.url?.newTab}
                            noFollow={subSubItem?.url?.noFollow}
                            data-testid="desktopSubitem"
                            onKeyDown={event => {
                              if (event.key === "ArrowRight") {
                                customSetMenuBg(true, subIdx)
                              }
                              if (
                                event.key === "ArrowLeft" ||
                                event.key === "Escape"
                              ) {
                                customSetMenuBg(false, subIdx)
                              }
                            }}
                          >
                            <ChildrenLinkItem />
                          </LinkWrapper>
                        ) : (
                          <ChildrenLinkItem />
                        )}
                      </Box>
                    )
                  })}
                </Box>
              </SubNavItem>
            )
          )
        })}
      </SubNavList>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderTop={
          (menuItem?.url?.linkToURL ||
            menuItem?.url?.href ||
            hasSpecialLinks) &&
          "1px solid #e8e8ea"
        }
        mt="m"
      >
        <Flex>
          {menuItem?.children?.map(subItem => {
            return (
              subItem?.special && (
                <StyledLinkWrapper
                  css={`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: ${theme("spacing.s")};
                    margin-left: 0;
                  `}
                  title={subItem?.label}
                  anchor={subItem?.url?.anchor}
                  href={subItem?.url?.href}
                  linkToURL={subItem?.url?.linkToURL}
                  newTab={subItem?.url?.newTab}
                  noFollow={subItem?.url?.noFollow}
                >
                  <Icons
                    name="question"
                    height="35px"
                    width="35px"
                    fill="brand01"
                    aria-hidden={true}
                  />
                  <Flex flexDirection="column" ml="xxs">
                    {subItem.auxText && (
                      <Text color="link02active" textStyle="tinyxl">
                        {subItem.auxText}
                      </Text>
                    )}
                    {subItem.label && (
                      <Text color="link01" textStyle="tinyl">
                        {subItem.label}
                      </Text>
                    )}
                  </Flex>
                </StyledLinkWrapper>
              )
            )
          })}
        </Flex>
        {(menuItem?.url?.linkToURL || menuItem?.url?.href) && (
          <StyledLinkWrapper
            css={`
              display: flex;
              justify-content: center;
              margin: ${theme("spacing.s")};
              margin-right: 0;
            `}
            data-testid="desktopSubitem"
            role="menuitem"
            aria-haspopup="true"
            title={menuItem?.label}
            anchor={menuItem?.url?.anchor}
            href={menuItem?.url?.href}
            linkToURL={menuItem?.url?.linkToURL}
            newTab={menuItem?.url?.newTab}
            noFollow={menuItem?.url?.noFollow}
          >
            <Text color="text01" textStyle="headingxs">
              {menuItem.label}
            </Text>
            <Icons
              width={{ default: "24px" }}
              height={{ default: "24px" }}
              name="higher"
              fill="text01"
              ml="xxs"
              mr="-5px"
              aria-hidden={true}
            />
          </StyledLinkWrapper>
        )}
      </Flex>
    </MaxWidth>
  )
}

export default DesktopSubMenu
