import { getThemeOptions } from "@helpers/schemas"
import { ProgramTabs, ProgramTabs2x } from "@thumbnails/Modules"
import { BasicContentLayout1 } from "@layouts"
import StudyPlan from "@schemas/modules/StudyPlan"
import BasicContent from "@schemas/modules/BasicContent"
import FeaturedBluePanel from "@schemas/modules/FeaturedBluePanel"

const TabDefault = (schema, sectionSlug, title) => ({
  component: "ProgramTabElement",
  // tabTitle: { content: "Title", tag: "h3" },
  title: title,
  elements: [],
  sectionSlug: sectionSlug,
  componentModules: [{ ...schema.default }],
})

export default {
  schemaType: "module",
  displayName: "Program Tabs",
  component: "ProgramTabs",
  category: "content",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Program Sections",
          type: "ComponentArray",
          maxItems: 7,
          whiteList: ["ProgramTabElement"],
          key: "elements",
          mandatory: true,
        },
        {
          title: "CTA 1",
          type: "ComponentContainer",
          whiteList: ["LinkModal"],
          key: "cta1",
          hideable: true,
        },
        {
          title: "CTA 2",
          type: "ComponentContainer",
          whiteList: ["LinkModal"],
          key: "cta2",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: BasicContentLayout1,
            },
          ],
          columns: 2,
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(),
          columns: 8,
        },
        {
          title: "Submenu Style",
          key: "submenuColor",
          type: "VisualUniqueSelection",
          options: getThemeOptions([
            "defaultAlt",
            "accent",
            "accentLight",
            "accentMid",
            "inverse",
          ]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    anchorID: null,
    component: "ProgramTabs",
    theme: "defaultAlt",
    submenuColor: "inverse",
    cta1: {
      component: "LinkModal",
      style: "primary",
      link: {
        text: "CTA1",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
        },
      },
    },
    cta2: {
      component: "LinkModal",
      style: "secondary",
      link: {
        text: "CTA2",
        url: {
          href: null,
          linkToURL: null,
          linkTo: null,
          newTab: false,
          noFollow: false,
        },
        modal: {
          floatingCTALightbox: {
            component: "FloatingCTALightbox",
          },
        },
      },
    },
    hasGriddoMultiPage: true,
    elements: [
      TabDefault(StudyPlan, "", "Tab 1"),
      TabDefault(BasicContent, "tab-02", "Tab 2"),
      TabDefault(FeaturedBluePanel, "tab-03", "Tab 3"),
    ],
  },
  thumbnails: {
    "1x": ProgramTabs,
    "2x": ProgramTabs2x,
  },
}
