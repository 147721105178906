import { allModulesWhitelist } from "@helpers/schemas"
import { BasicTemplate, BasicTemplate2x } from "@thumbnails/Templates"

export default {
  schemaType: "template",
  displayName: "Basic Template",
  component: "BasicTemplate",
  dataPacks: null,
  type: { label: "Static", value: "static" },
  content: [
    {
      title: "Hero Section",
      type: "ComponentArray",
      maxItems: 1,
      whiteList: [
        "HeroCarousel",
        "HeroClaim",
        "HeroImage",
        "HeroMosaic",
        "HeroQuote",
        "HeroText",
        "HeroVideo",
        "HeroSidePanel",
      ],
      key: "heroSection",
    },
    {
      title: "Main Content",
      type: "ComponentArray",
      maxItems: null,
      whiteList: allModulesWhitelist,
      key: "mainContent",
    },
  ],
  default: {
    type: "template",
    templateType: "BasicTemplate",
    heroSection: {
      component: "Section",
      name: "Hero Section",
      modules: [],
      sectionPosition: 1,
    },
    mainContent: {
      component: "Section",
      name: "Main Content",
      modules: [],
      sectionPosition: 2,
    },
  },
  thumbnails: {
    "1x": BasicTemplate,
    "2x": BasicTemplate2x,
  },
}
